<!--合约交易-左部操作面板-->
<template>
<div class="tradeMainLeft">
    <!--连接钱包和账户-->
    <div v-if="!isInitWallet" class="tradeAssets">
        <div class="tradeConnect">
            <div>{{this.$t("connectWalletIntroduce")}}</div>
            <div class="mt10"><Button type="info" @click="isconnectWalletPop=true">{{$t("connectWallet")}}</Button></div>
        </div>
    </div>
    <!--切换链-->
    <div v-else-if="shouldSwitchChain" class="tradeAssets">
        <div class="tradeConnect">
            <div>{{this.$t("toggleChainWarn")}} {{$config.chainInfo.chainName}}</div>
            <div class="mt10"><Button type="info" @click="toggleChain()">{{$t("switchingChain")}}</Button></div>
        </div>
    </div>
    <wallet-pannel v-else></wallet-pannel>
    <div class="tradeAction">
        <!--市价，限价，止损-->
        <div class="tradeActionTab">
            <ul>
                <li @click="upMarket('market'),isMarket=false" :class="market=== 'market'?'active':''">{{this.$t("market")}}</li>
                <li @click="upMarket('limit'),isMarket=false" :class="market=== 'limit'?'active':''">{{this.$t("limit")}}</li>
                <!-- <li  @click="upMarket('stop')" :class="market=== 'stopLimit'||market=== 'trailingStop'?'active':''">
                    <span class="mr5">{{this.$t("stop")}}</span>
                    <Icon v-if="isMarket" type="md-arrow-dropup" />
                    <Icon v-else type="md-arrow-dropdown" />
                </li> -->
            </ul>
            <div v-if="isMarket" class="stopChild">
                <div @click="isMarket=false,upMarket('stopLimit')">{{this.$t("stopLimit")}}</div>
                <div @click="isMarket=false,upMarket('trailingStop')">{{this.$t("trailingStop")}}</div>
            </div>
        </div>
        <div class="" style="overflow-y:auto;">
            <!--卖出，买入-->
            <div class="actionTab">
                <div class="sell" @click="toggleBuyMode('sell')" :class="deal==='sell'?'bg active':''">{{this.$t("sell")}}</div>
                <div class="buy" @click="toggleBuyMode('buy')" :class="deal==='buy'?'bg active':''">{{this.$t("buy")}}</div>
            </div>
            <div class="actionWp">
                <!--数量-->
                <!-- <Tooltip max-width="250" placement="top-start">
                    <div slot="content">
                        <p class="f16">{{this.$t("orderAmount")}}</p>
                        <p class="f12 mt5">{{this.$t("amountIntroduce")}}</p>
                    </div> -->
                    <div class="mt15 actionWpTitle">{{this.$t("amount")}}<span class="tag">{{blockchain.abbreviation}}</span></div>
                <!-- </Tooltip> -->
                <div class="mt10">
                    <ex-input v-model="form.amount" @input="e=>form.amount=prohibitNegative(e,blockchain.amountAccuracy,20)"  max-length="20" placeholder="0.00" @mousewheel.native.prevent />
                </div>
                <!--止损限价-触发价格-->
                <div v-show="market=== 'stopLimit'">
                    <Tooltip max-width="250" placement="top-start">
                        <div slot="content">
                            <p class="f16">{{this.$t("stopPriceTitle")}}</p>
                            <p class="mt5">{{this.$t("stopPriceIntroduce")}}</p>
                        </div>
                        <div class="mt15 actionWpTitle">{{this.$t("stopPrice")}}<span class="tag">USD</span></div>
                    </Tooltip>
                    <div class="mt10">
                        <ex-input v-model="form.triggerPrice" @input="e=>form.triggerPrice=prohibitNegative(e,blockchain.priceAccuracy,20)" placeholder="0.00"  @mousewheel.native.prevent />
                    </div>
                </div>
                <!--限价-->
                <div v-show="market=== 'limit'||market==='stopLimit'">
                    <Tooltip max-width="250" placement="top-start">
                        <div slot="content">
                            <p class="f16">{{this.$t("limitPrice")}}</p>
                            <p class="mt5">{{this.$t("limitPriceIntroduce")}}</p>
                        </div>
                        <div class="mt15 actionWpTitle">{{this.$t("limitPrice")}}<span class="tag">USD</span></div>
                    </Tooltip>
                    <div class="mt10">
                        <ex-input v-model="form.limitPrice" @input="e=>form.limitPrice=prohibitNegative(e,blockchain.priceAccuracy,20)" placeholder="0.00" @mousewheel.native.prevent />
                    </div>
                </div>
                <!--追踪止损-追踪百分比-->
                <div v-show="market=== 'trailingStop'">
                    <Tooltip max-width="250" placement="top-start">
                        <div slot="content">
                            <p class="f16">{{this.$t("trailingPercent")}}</p>
                            <p class="mt5">{{this.$t("trailingPercentIntroduce")}}</p>
                        </div>
                        <div class="actionWpTitle">{{this.$t("trailingPercent")}}<span class="tag">USD</span></div>
                    </Tooltip>
                    <div class="mt10" style="position:relative;">
                        <ex-input v-model="form.trailingPercent" max-length="10" placeholder="0.00" :is-negative="false" @mousewheel.native.prevent />
                        <span style="position:absolute;right:20px;top:50%;transform:translateY(-50%);">%</span>
                    </div>
                </div>
                <!--杠杆-->
                <div v-show="market=='market' || market=='limit'">
                    <div class="center pt10 pb5">{{this.$t("or")}}</div>
                    <div class="">
                        {{this.$t("leverage")}}
                        <span class="ml10 op5 right">{{this.$t("upto25x")}}</span>
                        <span class="ml10">{{form.level}}×</span>
                    </div>
                    <div class="mt15">
                        <Slider v-model="form.level" :min="1" :max="25" :step="1" show-stops></Slider>
                        <!-- <div class="flexBetween"><span>{{this.$t("long")}}</span><span>{{this.$t("short")}}</span></div> -->
                    </div>
                </div>
                <!--高级-->
                <Collapse v-if="false" v-show="market!='market'" v-model="isSuperPannel"><!--v-if="false"-->
                    <Panel name="1">
                        {{$t('advanced')}}
                        <div slot="content" class="super">
                            <!--有效截止时间-->
                            <div style="margin:0 0 8px 4px;">{{$t('timeInForce')}}</div>
                            <Select v-show="market=='limit'" v-model="form.controlType" class="mb10" size="large" style="width:100%;">
                                <Option value="1">{{$t('goodTilTime')}}</Option>
                                <Option value="2">{{$t('fillOrKill')}}</Option>
                                <Option value="3">{{$t('immediateOrCancel')}}</Option>
                            </Select>
                            <div class="alignCenter">
                                <ex-input v-model="form.dateNum" max-length="10" placeholder="0" @input="e=>form.amount=prohibitNegative(e,0,20)"  class="iblock" style="width:50%;" @mousewheel.native.prevent />
                                <Select v-model="form.dateType" class="ml10" style="flex:1;" size="large">
                                    <Option value="minute">{{$t('minute')}}</Option>
                                    <Option value="hour">{{$t('hour')}}</Option>
                                    <Option value="day">{{$t('day')}}</Option>
                                    <Option value="week">{{$t('week')}}</Option>
                                </Select>
                            </div>
                            <!--执行-->
                            <!-- <template v-if="market!='trailingStop'">
                                <div style="margin:16px 0 8px 4px;">{{$t('execution')}}</div>
                                <Checkbox v-model="form.execute" class="iblock" style="margin:0 0 10px 4px;" @on-change="onChange">
                                    <span class="ml10">{{$t('postOnly')}}</span>
                                </Checkbox>
                                <Tooltip max-width="250" placement="top-start">
                                    <div slot="content">
                                        <p class="f16">{{$t('postOnly')}}</p>
                                        <p class="mt5">{{$t('postOnlyDetail')}}</p>
                                    </div>
                                    <div class="actionWpTitle"><img :src='require("@/assets/img/help.svg")'></div>
                                </Tooltip>
                                <br/>
                                <Checkbox v-show="market!='limit'" v-model="form.execute2" class="iblock" style="margin:0 0 10px 4px;" @on-change="onChange2">
                                    <span class="ml10">{{$t('fillOrKill')}}</span>
                                </Checkbox>
                                <Tooltip v-show="market!='limit'" max-width="250" placement="top-start">
                                    <div slot="content">
                                        <p class="f16">{{$t('fillOrKill')}}</p>
                                        <p class="mt5">{{$t('fillOrKillDetail')}}</p>
                                    </div>
                                    <div class="actionWpTitle"><img :src='require("@/assets/img/help.svg")'></div>
                                </Tooltip>
                            </template> -->
                        </div>
                    </Panel>
                </Collapse>
                <!--操作提示-->
                <div class="warn" v-if="isWarnTips">
                    <div v-if="warnState==0">{{$t('warnTips')}}</div>
                    <div v-else-if="warnState==1">{{$t('warnTips2')}}</div>
                    <div v-else-if="warnState==2">{{$t('lowerPriceWarn')}}{{ lowerLimit.toFixed(2) }}</div>
                    <div v-else-if="warnState==3">{{$t('highPriceWarn')}}{{ upperLimit.toFixed(2) }}</div>
                    <div v-else-if="warnState==4">{{ $t('highLeverageWarn') }}{{ calcLeverageLimit(Number(form.amount))}}</div>
                    <div v-else-if="warnState==5">{{ $t('amounExceedWarn') }}{{ amountLimit }}</div>
                </div>
                <!--合计-->
                <div class="infobox">
                    <!--预期价格-->
                    <div class="count_list" v-if="market==='market'">
                        <div>
                            <Tooltip max-width="250" placement="top-start">
                                <div slot="content">
                                    <p class="f16">{{this.$t("expectedPrice")}}</p>
                                    <p class="f12 mt5">{{this.$t("expectedPriceIntroduce")}}</p>
                                </div>
                                <div class="actionWpTitle">{{this.$t("expectedPrice")}}</div>
                            </Tooltip>
                        </div>
                        <span>{{ expectedPrice | numFormat(2)}}</span>
                    </div>
                    <!--价格差-->
                    <div class="count_list" v-if="market==='market'">
                        <div>
                            <Tooltip max-width="250" placement="top-start">
                                <div slot="content">
                                    <p class="f16">{{this.$t("priceImpactTitle")}}</p>
                                    <p class="f12 mt5">{{this.$t("priceImpactIntroduce")}}</p>
                                </div>
                                <div class="actionWpTitle">{{this.$t("priceImpact")}}</div>
                            </Tooltip>
                        </div><span>{{ priceImpact | numFormat(2)}}</span>
                    </div>
                    <!--费用百分比-->
                    <!-- <div class="count_list">
                        <div>{{this.$t("feePercent")}}</div><span>-</span>
                    </div> -->
                    <!--交易费率-->
                    <div class="count_list">
                        <!-- <div>
                            <Tooltip max-width="250" placement="top-start">
                                <div slot="content">
                                    <p class="f16">{{this.$t("fee")}}</p>
                                    <p class="f12 mt5">{{this.$t("feeIntroduce")}}</p>
                                </div>
                                <div class="actionWpTitle">{{this.$t("fee")}}<span class="tag ml5">{{this.$t("taker")}}</span></div>
                            </Tooltip>
                        </div><span>-</span> -->
                        <div>{{this.$t("fee")}}</div><span>{{(tradeFee&&tradeFee/10000) | format(2,'%')}}</span>
                    </div>
                    
                    <!--合计-->
                    <div class="count_list">
                        <div>{{this.$t("total")}}</div><span>{{totalMoney | numFormat(2)}}</span>
                    </div>
                    <div class="count_list">
                        <div>{{this.$t("gasFee")}}</div><span>{{gasFee | numFormat(3)}}<span class="tag ml5">USDT</span></span>
                    </div>
                </div>
                <!--下单按钮-->
                <div class="center mt20 pb20 sub_btn">
                    <Button type="info" long size="large" @click="subOrder()" :disabled="isSubOrder" :class="{'red_btn':deal=='sell'&&!isSubOrder,'green_btn':deal=='buy'&&!isSubOrder}">
                        <template v-if="market==='market'">{{this.$t("placeMarketOrder")}}</template>
                        <template v-else-if="market==='limit'">{{this.$t("placeLimitOrder")}}</template>
                        <template v-else-if="market==='stop'||market==='trailingStop'||market==='stopLimit'">{{this.$t("placeStopOrder")}}</template>
                    </Button>
                </div>
            </div>
        </div>
        <!--连接钱包弹窗-->
        <ConnectWallet v-model="isconnectWalletPop" key="1"></ConnectWallet>
    </div>
    <loading-pop v-model="isLoadingPop" :text="loadingText"></loading-pop>
</div>
</template>
<script>
import { signTypedDataV4 } from "@/utils/signFn/order";//签名函数，生成订单
import loadingPop from "@/components/loadingPop.vue"
import ConnectWallet from "@/components/model/connectWallet.vue"
import exInput from "@/components/common/exInput/index.vue"
import walletPannel from "./walletPannel.vue"
import { subOrder,sendMsg,userFee } from "@/api/http"
import { accMul,accAdd,accSub,accDiv } from "@/utils/base"
import WalletUtil from "@/utils/walletUtil"
import ContractUtil from "@/utils/contractUtil"

let initTimeoutId=0
let getAllowanceTimeoutId=0

let loading=false;
export default {
    components: {
       ConnectWallet,exInput,walletPannel,loadingPop
    },
    data () {
        return {
            isLoadingPop:false,//加载中弹窗
            loadingText:'',//加载中文字
            isconnectWalletPop:false,//连接钱包弹窗
            market:'market',//市价:market,限价:limit,止损:stop。stopLimit:止损限价,trailingStop:追踪止损
            deal:'buy',//买入：buy，卖出：sell
            isMarket:false,//是否止损
            isSuperPannel: '',//高级面板
            // tradeFee:'',//展示的交易费率
            form:{
                amount:'',//数量
                level:25,//杠杆
                limitPrice:'',//限价
                triggerPrice:'',//触发价格
                trailingPercent:'',//追踪百分比
                controlType:'1',//截止时间执行类型
                dateNum:28,//截止时间数值
                dateType:'day',//截止时间类型
                execute:false,//仅挂单
                execute2:false,//全部成交否则取消指令
            },
            warnState:'',//提示类型
            allowance:0,//是否授权usdc
        };
    },
    mounted () {
        // this.init();
        //盘口点击后输入金额
        this.$eventBus.$on("inputPrice",(price)=>{
            this.market='limit';
            this.form.limitPrice=price;
        });
        //配置消息。交易费率
        // this.$eventBus.$on("config_msg",(res)=>{
        //     let tradeFee=res.data.takerFeeRate || 0;
        //     this.tradeFee=tradeFee;
        // });
        // setTimeout(() => {
        //     sendMsg({cmd:'config',data:this.blockchain.perpetual});
        // }, 0);
    },
    methods: {
        //初始化
        init(){
            clearTimeout(initTimeoutId)
            if(!this.addr || !this.isInitWallet){
                initTimeoutId=setTimeout(()=>this.init(),2000)
                return
            }
            if(this.blockchain.usdc){
                WalletUtil.usdt.at(this.blockchain.usdc)
            }
            this.getAllowance(true);
        },
        setAllowance(token,addr,spender, allowance){
            this.allowance=allowance
            localStorage.setItem(token+'_'+addr+'_'+spender, allowance)
        },
        //获取是否授权
        getAllowance(ignoreCache){
            clearTimeout(getAllowanceTimeoutId)
            if(this.allowance && !ignoreCache){
                return
            }
            const usdc=this.blockchain.usdc
            const perpetual=this.blockchain.perpetual
            const addr=this.addr
            const cachedAllowance=localStorage.getItem(usdc+'_'+addr+'_'+perpetual)
            if(cachedAllowance && !ignoreCache){
                this.allowance=cacheAllowance
                return
            }
            if(!usdc || !perpetual || !addr){
                getAllowanceTimeoutId=setTimeout(()=>this.getAllowance(ignoreCache),2000)
            }

            ContractUtil.usdt.at(usdc).then((instance) => {
                return instance.allowance(addr,perpetual).then(res=>{
                    this.setAllowance(usdc,addr,perpetual, res.toString())                    
                })
            }).catch(err=>{
                console.warn(err.message)
                getAllowanceTimeoutId=setTimeout(()=>this.getAllowance(ignoreCache),2000)
            })
        },
        //授权
        approve(){
            const usdc=this.blockchain.usdc
            const perpetual=this.blockchain.perpetual
            const addr=this.addr

            WalletUtil.usdt.at(usdc).then((instance) => {
                return instance.approve(perpetual,'9999999999999999999999999999999999999999', {from:addr})
                .then(() =>{
                    return this.getAllowance(true)
                });
            }).catch(err=>{
                console.warn(err.message)
            });
        },
        //切换链
        async toggleChain(){
            try {
                await WalletUtil.provider.request({
                    method: 'wallet_switchEthereumChain',
                    params: [{ chainId: this.$config.chainId }]
                });
                try {
                    WalletUtil.walletInit(() => { })
                } catch (err) { }
            } catch (switchError) {
                console.warn(switchError)
                try {
                    await WalletUtil.provider.request({
                        method: 'wallet_addEthereumChain',
                        params: [this.$config.chainInfo]
                    });
                    await WalletUtil.provider.request({
                        method: 'wallet_switchEthereumChain',
                        params: [{ chainId: this.$config.chainId }]
                    });
                    try {
                        WalletUtil.walletInit(() => { })
                    } catch (err) { }
                } catch (addError) {
                    console.warn(addError)
                }
            }
        },
        //执行复选变单选
        onChange(){
            this.form.execute2=false;
        },
        onChange2(){
            this.form.execute=false;
        },
        //提交订单
        async subOrder(){
            if(loading){
                this.$Message.warning(this.$t('repeatSubmission'));
                return false;
            }

            if(!this.addr){
                return;
            }

            if(this.market=='limit'){
                if(this.form.limitPrice < this.lowerLimit){
                    this.$Message.error(this.$t('lowerPriceWarn')+this.lowerLimit.toFixed(2));
                    return false;
                }
                if(this.form.limitPrice > this.upperLimit){
                    this.$Message.error(this.$t('highPriceWarn')+this.upperLimit.toFixed(2));
                    return false;
                }
            }

            let price= this.market=='market'?0:this.form.limitPrice;//市价单，价格为零
            let resUserFee=await userFee(this.addr);//获取费率相关信息
            if(resUserFee.code != 200){this.$Message.error(this.$t('operationFailed'));return;}
            loading=true;
            const unsignedData={
                trader: this.$store.state.app.wallet.address,//用户地址
                amount: this.form.amount,//数量
                price: price,//价格
                version: 2,//版本
                side: this.deal,//买：buy，卖：sell
                type: this.market,//类型。market：市价，limit：限价
                triggerPrice: this.form.triggerPrice,//触发价格
                trailingPercent: this.form.trailingPercent,//追踪百分比
                controlType: this.form.controlType,//截止时间执行类型。1：有效截止时间，2.全部成交否则取消指令，3.立即否则取消。
                execution: this.form.execute?1:this.form.execute2?2:0,//执行。0：未选择，1：仅挂单，2：全部成交否则取消指令
                makerFeeRate:resUserFee.data.makerFeeRate,
                takerFeeRate:resUserFee.data.takerFeeRate,
                
                leverage:this.form.level,
                close:0,
            }
            console.warn('unsignedData',unsignedData, this.form)

            var res=signTypedDataV4(unsignedData,this.blockchain.perpetual,this.blockchain.broker,this.addr).then(data=>{ 
                loading=false;
                data.signature= JSON.stringify(data.signature);     //签名
                data.type= unsignedData.type;     //类型。market：市价，limit：限价
                data.side= unsignedData.side;       //买和卖
                data.leverage= unsignedData.leverage;
                data.close= unsignedData.close;//平仓
                this.isLoadingPop=true;
                if(this.market=='market'){
                    this.loadingText=this.$t('placingMarketOrder');
                }else if(this.market=='limit'){
                    this.loadingText=this.$t('placingLimitOrder');
                }
                subOrder(data)
                .then(res=>{
                    this.isLoadingPop=false;
                    this.loadingText='';
                    if(res.code == 200){
                        this.$Message.success(this.$t('operationSucceeded'));
                        this.freeForm();
                        this.$eventBus.$emit("changeBalance");//余额变动
                        this.$eventBus.$emit("changeOrder");//更新订单。我的挂单
                        this.$eventBus.$emit("update_current_position");//获取当前持仓
                    }else if(res.code == 600){      //未匹配成功
                        this.$Message.error(this.$t('insufficientLiquidity'));
                    }else if(res.code == 601){      //未匹配到订单
                        this.$Message.error(this.$t('orderNotMatched'));
                    }else if(res.code == 602){      //您未有内测资格
                        this.$Message.error(this.$t('youNotTest'));
                    }else{
                        // this.$Message.error(this.$t('operationFailed'));
                        this.$Message.error(res.msg);
                    }
                })
                .catch(err=>{
                    console.warn(err);
                });
            }).catch(err=>{
                loading=false;
                console.warn(err);
            });
        },
        //清空表单
        freeForm(){
            this.form.amount='';
            this.form.level=25;
            this.form.triggerPrice='';
            this.form.limitPrice='';
            this.form.trailingPercent='';
        },
        //买入卖出切换
        toggleBuyMode(event){
            this.deal = event;
        },
        //切换下单类型。市价:market,限价:limit,止损:stop。stopLimit:止损限价,trailingStop:追踪止损
        upMarket(event){
            if(event === 'stop'){
                this.isMarket = !this.isMarket;
            }else{
                this.market = event;
            }
        },
        calcLeverageLimit(amount,maxLeverage=25){
            return Math.max(1,Math.min(maxLeverage,Math.floor(this.amountLimit/amount)))
        }
    },
    computed:{
        shouldSwitchChain(){
            return this.isInitWallet && this.wallet.chainId!==parseInt(this.$config.chainId) && this.$store.state.app.walletActiveName!='Coinbase Wallet'
        },
        blockchain(){
            return this.$store.state.blockchain;
        },
        newPrice(){
            return this.$store.state.handicap.newPrice
        },
        lowerLimit(){
            return this.newPrice*0.8
        },
        upperLimit(){
            return this.newPrice*1.2
        },
        amountLimit(){
            return 500;
        },
        //预期价格
        expectedPrice(){
            let amount=this.form.amount;//数量
            let book=this.$store.state.handicap.book;//盘口挂单数据
            let sumP=0,count=0;//sumP：总金额。
            if(this.market!='market'){
                return '';
            }
            if(!amount){
                return '';
            }
            //买入：buy，卖出：sell
            if(this.deal=='sell'){
                sumP=0;//sumP：总金额。
                for(let i=0;i<book.buy.length;i++){
                    count=accAdd(count,book.buy[i].amount);
                    if (accSub(amount,book.buy[i].amount) <= 0){
                        sumP = accAdd(sumP,accMul(amount,book.buy[i].price));
                        break;
                    }else{
                        sumP = accAdd(sumP,accMul(book.buy[i].price,book.buy[i].amount));
                        amount = accSub(amount,book.buy[i].amount);
                    }
                }
            }else if(this.deal=='buy'){
                sumP=0;//sumP：总金额。
                for(let i=book.sell.length-1;i>=0;i--){
                    count=accAdd(count,book.sell[i].amount);
                    if (accSub(amount,book.sell[i].amount) <= 0){
                        sumP = accAdd(sumP,accMul(amount,book.sell[i].price));
                        break;
                    }else{
                        sumP =  accAdd(sumP,accMul(book.sell[i].price,book.sell[i].amount));
                        amount = accSub(amount,book.sell[i].amount);
                    }
                }
            }
            return this.form.amount>count ? '' : (accDiv(sumP,this.form.amount) || '');
        },
        //价格差
        priceImpact(){
            let amount=this.form.amount;//数量
            let book=this.$store.state.handicap.book;//盘口挂单数据
            let price=0,result=0;
            if(!amount){
                return '';
            }
            //买入：buy，卖出：sell
            if(this.deal=='buy'){
                price=book.sell[book.sell.length-1].price;
                result=this.expectedPrice ? this.expectedPrice-price : '';
            }else if(this.deal=='sell'){
                price=book.buy[0].price;
                result=this.expectedPrice ? price-this.expectedPrice : '';
            }
            return result;
        },
        //合计，总金额
        totalMoney(){
            // let newPrice=this.$store.state.handicap.newPrice;//价格（最新成交价）
            // let markPrice=this.$store.state.handicap.markPrice;//标记价格
            let expectedPrice=this.expectedPrice;//预期价格
            let amount=this.form.amount;//数量
            let limitPrice=this.form.limitPrice;//限价
            let sumPrice=0;
            if(!amount){
                return '';
            }
            if(this.market=='market' && !expectedPrice){
                return;
            }
            //合计 数量*限价/25 + 数量*限价*交易手续费
            if(this.market=='market'){
                sumPrice = accMul(expectedPrice,amount);
            }else{
                sumPrice = accMul(limitPrice,amount);
            }
            return sumPrice;
        },
        //钱包地址
        addr() {
            return this.$store.state.app.wallet.address;
        },
        //是否初始化钱包
        isInitWallet() {
            return this.$store.state.app.wallet.isInit;
        },
        //钱包
        wallet() {
            return this.$store.state.app.wallet;
        },
        //下单是否可点击，为true不可点击。
        isSubOrder(){
            // return false;
            //市价:market,限价:limit,止损:stop。stopLimit:止损限价,trailingStop:追踪止损
            //!isWarnTips:是否存在警告，!this.addr：是否存在地址（是否登录)。
            switch(this.market){
                case 'market':
                    return !this.form.amount || this.form.amount==0 || this.isWarnTips || !this.addr;
                    break;
                case 'limit':
                    return !this.form.amount || this.form.amount==0 || !this.form.limitPrice || this.form.limitPrice==0 || this.isWarnTips || !this.addr;
                    break;
                case 'stopLimit':
                    return !this.form.amount || this.form.amount==0 || !this.form.triggerPrice || !this.form.limitPrice || this.form.limitPrice==0 || !this.addr;
                    break;
                case 'trailingStop':
                    return !this.form.amount || this.form.amount==0 || !this.form.trailingPercent || !this.addr;
                    break;
                default:
                    return false;
                    break;
            }
        },
        //不可下单提示。输入限制。数量*价格+手续费<=可用保证金*杠杆。价格:最新成交价和限价
        isWarnTips(){
            // return false;//表单不校验
            let newPrice=this.$store.state.handicap.newPrice;//价格（最新成交价）
            let amount=this.form.amount;//数量
            let limitPrice=this.form.limitPrice;//限价
            let availableMargin=this.$store.state.user.availableMargin;//可用保证金
            let markPrice=this.$store.state.handicap.markPrice;//标记价格
            if(!amount || amount==0){
                return false;
            }
            // 计算是否能够下单
            // 市价：可用保证金(当前组件等子组件的可用保证金修改为可用钱包余额)>markPrice*amount/25+预期价格*amount*交易费率
            // 限价：可用保证金>markPrice*amount/25+用户输入价格*amount*交易费率
            if(this.market=='market'){
                let resPrice=accMul(this.expectedPrice,amount)/this.form.level+accMul(this.expectedPrice,amount)*(this.tradeFee/1E18)
                //计算亏损不计算盈利
                //买入时，标记价格小于预期价格。卖出时，标记价格大于预期价格
                let pnl = 0;
                if (resPrice >0){
                    if (this.deal=="buy" && markPrice < this.expectedPrice ){
                        pnl = accMul((this.expectedPrice-markPrice),amount)
                    }else if (this.deal=="sell" && markPrice > this.expectedPrice ){
                        pnl = accMul((markPrice-this.expectedPrice),amount)
                    }
    //                 if (this.deal=="buy" ){
    //                     pnl = accMul((this.expectedPrice-markPrice),amount)
    //                 }else if (this.deal=="sell"){
    //                     pnl = accMul((markPrice-this.expectedPrice),amount)
    //                 }
                    resPrice += pnl;
                }
                // if(resPrice!=0 && !this.expectedPrice){     //计算价格不为零，预期价格为空
                //     this.warnState=1;
                //     return true;
                // }
                if(resPrice==0 && amount>0){    //合计为零，有数量的情况
                    this.warnState=1;
                    return true;
                }else if(resPrice==0 || (availableMargin < resPrice+this.gasFee) || availableMargin==0){      //计算价格为零，可用保证金小于计算价格，可用保证金不为零。
                    this.warnState=0;
                    return true;
                }
            }else if(this.market=='limit'){
                if(!limitPrice){
                    return false;
                }
                let resPrice=accMul(limitPrice,amount)/this.form.level+accMul(limitPrice,amount)*(this.tradeFee/1E18)+this.gasFee;
                if(resPrice==0 || (availableMargin < resPrice+this.gasFee) || availableMargin==0){
                    this.warnState=0;
                    return true;
                }

                if(limitPrice < this.lowerLimit){
                    this.warnState=2;
                    return true
                }
                if(limitPrice > this.upperLimit){
                    this.warnState=3;
                    return true
                }    
            }

            if(Number(amount)>this.amountLimit){
                this.warnState=5;
                return true;
            }

            if(Number(this.form.level) > this.calcLeverageLimit(Number(amount))){
                this.warnState=4;
                return true;
            }

            return false;
        },
        //交易费率
        tradeFee(){
            return this.$store.state.app.takerFeeRate;
        },
        //上链手续费
        gasFee(){
            return this.$store.state.app.gasFee;
        }
    },
    watch:{
        //是否登录钱包
        'isInitWallet':{
            handler:function(val,value){
                setTimeout(() => {
                    sendMsg({cmd:'config',data:this.blockchain.perpetual});
                }, 0);
                this.allowance=0;
                if(this.isInitWallet){  //登录状态才获取
                    setTimeout(()=>{
                        this.init();
                    },0);
                }
            },
            deep: false,
            immediate: false
        },
        //切换交易对
        '$store.state.blockchain.perpetual':{
            handler:function(val,value){
                this.freeForm();
                setTimeout(() => {
                    sendMsg({cmd:'config',data:this.blockchain.perpetual});
                }, 0);
                this.allowance=0;
                if(this.isInitWallet){  //登录状态才获取
                    setTimeout(()=>{
                        this.init();
                    },0);
                }
                // this.getAllowance();
            },
            deep: false,
            immediate: true
        },
    },
    beforeDestroy () {
        this.$eventBus.$off("inputPrice");
    },
};
</script>

<style lang="less" >
.tradeMainLeft{width:320px;height:100%;border-right:1px solid @borderColor;display:flex;flex-direction: column;
}
.tradeAssets{height:170px;border-bottom:1px solid @borderColor;.flexCenter;text-align:center;
    .tradeConnect{margin:0 30px;}
}
.tradeAction{flex:1;overflow:auto;display: flex;
    flex-direction: column;}
.tradeActionTab{position: relative;
    ul{display: flex;border-bottom:1px solid @borderColor;
        li{width:33.33333%;.flexCenter;height:48px;border-left:1px solid @borderColor;box-sizing:border-box; cursor:pointer;
            &:first-child{border:0;}
            &.active{background: #000;cursor:default;color:#fff;}
            &:hover{background: #000;}
        }
    }
}
.stopChild{ .column; position: absolute;right:0;z-index: 3;background:#fff;
    >div{ .pt10;.pb10;.pl20;.pr20; background:rgba(0, 0, 0, 0.87);border-bottom:1px solid @borderColor;
        &.active{background: #000;cursor:default;}
        &:hover{background: #000;}
    }
}
.actionTab{ position:relative;
    width:280px;border-radius:@btn-border-radius ;background:#111; display: flex;margin:20px auto 0;overflow:hidden;
    >div{height:40px;width:140px;.flexCenter; position: relative;z-index:2;cursor:pointer;}
    .sell{border-radius: @btn-border-radius 0 0 @btn-border-radius;
        &.active{.red;border: 2px solid @error-color;}
    }
    .buy{border-radius: 0 @btn-border-radius @btn-border-radius 0;
        &.active{.green;border: 2px solid @success-color;}
    }
    .bg{
        border-radius:@btn-border-radius ;height:40px;width:140px;transition:ease all 0.3s;background: @graybg;display: flex;
    }
}
.actionWp{width:280px;margin:0 auto;font-size:13px;
    //填充颜色
    .ivu-slider-wrap{
        background-color: #a6a5b3;
        .mb5;
        .ivu-slider-bar{background-color: @error-color;}
        .ivu-slider-stop{background: #000;}
        /**按钮颜色 */
        .ivu-slider-button{
            background-color: @error-color;
            border-color: @error-color;
        }
    }

}
.infobox{background: #000;border-radius:@btn-border-radius ;padding:13px 15px;.mt20;
    .count_list{.flexBetween;.mt10;
        &:first-child{margin:0;}
    }
}
.actionWpTitle{
    display:inline-block;
    vertical-align: middle;
    &:hover{.op5;}
}
.ivu-collapse-header{
    padding-left:0 !important;
}
.super{color:@grey;}
/**杠杆 */
.actionWp .ivu-slider-wrap{
    background: linear-gradient(45deg, #ed40141a, #ed4014);
}
.actionWp .ivu-slider-wrap .ivu-slider-stop{
    top: 50%;
    transform: translateY(-50%);
}
.ivu-slider-stop{
    width: 2px !important;
    height: 2px !important;
}
.ivu-slider-wrap{
    height:5px !important;
}
.ivu-slider-bar{
    height:5px !important;
}
.ivu-slider-button-wrap{
    top:-4px !important;
}
/*表单效验不通过*/
.warn {
    background: rgba(255,83,83,.1);
    border-left: 4px solid #ff5353;
    color: #f7f7f7;
    font-size: 13px;
    line-height: 16px;
    border-radius: 4px;
    padding: 10px 12px;
    margin: 16px 0;
    max-height: 200px;
    overflow: auto;
}
.sub_btn .red_btn{
    background-color:@error-color !important;
    color: white !important;
    border-color: @error-color !important;
}
.sub_btn .green_btn{
    background-color:@success-color !important;
    color: white !important;
    border-color: @success-color !important;
}
</style>
