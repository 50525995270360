<!--通用组件：输入框。限制输入小数和负数-->
<template>
 <div>
     <!--能输入负号-->
    <input :value="value" :type="type" :placeholder="placeholder" @keypress="keypress" @keydown="keydown" @input="input" @change="change" @blur="blur" ref="input" class="ex-input ex-input-large ex-input-max" step="0.01" />
    <!--onkeypress="return (/[\d]/.test(String.fromCharCode(event.keyCode || event.which))) || event.which === 8 || event.which === 46"  -->
 </div>
</template>

<script>
//禁用滚轮事件@mousewheel.native.prevent

/**
 * 限制不能输入负数其他方法1：
 *
 * <input type="number" @keydown="handleInput" placeholder="请输入" v-model="SaleQty">
 * handleInput(e) {
 * e.target.value=e.target.value.replace(/[^\d]/g,'');
 * },
 */
/**限制不能输入负数其他方法2： */
//onkeypress="return (/[\d]/.test(String.fromCharCode(event.keyCode || event.which))) || event.which === 8 || event.which === 46"
// function noNumbers(e){
//     var keycode = window.event ? e.keyCode : e.which;
//     //Internet Explorer/Chrome 浏览器使用 event.keyCode 取回被按下的字符，而 Netscape/Firefox/Opera 等浏览器使用 event.which。
//     var keychar = String.fromCharCode(keycode);//fromCharCode:将 Unicode 编码转为一个字符

//     if(keycode >= 48 && keycode <= 57){     //数字键盘代码：0-9
//         e.returnValue = true
//     }else if(keycode >=96 && keycode <= 105){       //onkeydown事件：小键盘数字代码：0-9
//         e.returnValue = true;
//     }else if(keycode == 8 || keycode == 46){        //删除键和delete
//         e.returnValue = true;
//     }else if(keycode == 45){        //负号不允许输入。onkeydown事件:keycode == 109 || keycode == 189
//         e.returnValue = false;
//     }else{            //其他字符不允许输入
//         e.returnValue = false;
//     }
// }
export default {
    name: '',
    model:{
        prop:'value',
        event:'input'
    },
    props: {
        //输入框的值
        value:{
            type:[String,Number],
            default:''
        },
        //输入框的类型
        type:{
            type:String,
            default:'text'
        },
        //最大长度
        maxLength:{
            type:[String,Number],
            default:0
        },
        //默认字符
        placeholder:{
            type:String,
            default:''
        },
        //是否能输入负号，负数
        isNegative:{
            type:Boolean,
            default:true
        },
        //限制小数位,-1值不生效，0以上的生效
        decimal:{
            type:[Number],
            default:0
        }
    },
    components: {

    },
    data () {
        return {

        }
    },
    mounted() {
        this.setNativeInputValue();
    },
    methods: {
        //获取input实例
        getInput() {
            return this.$refs.input;
        },
        //选择输入框内容
        select() {
            this.getInput().select();
        },
        //原生操作dom修改值
        setNativeInputValue() {
            const input = this.getInput();
            input.value = this.value;
        },
        //按键
        keypress(e){
            //负号不允许输入
            // if(!this.isNegative && e.key == '-'){
            //     e.preventDefault();
            // }
        },
        //按上
        keydown(e){
            //负号不允许输入
            // if(!this.isNegative && e.which ==229 ){
            //     console.log(e,'keyup')
            //     e.preventDefault();
            // }
        },
        //输入事件
        input(e){
            // if(!this.isNegative){
            //     e.target.value=e.target.value.replace('-','');
            // }
            if(e.target.value){
            //     //限制小数位
            //     if(this.decimal>0){
            //         let regex=new RegExp(`^\\d+(?:\\.\\d{0,${this.decimal}})?`,"g");
            //         // let regex=new RegExp(`^\\d*(\\.?\\d{0,${this.decimal}})`,"g");     //regExp使用的时候，反斜杠需要写两个
            //         e.target.value = (e.target.value.match(regex)[0]) || null;
            //     }
                //限制长度
                if(this.maxLength > 0 && e.target.value.length >= this.maxLength){
                    e.target.value = e.target.value.slice(0,this.maxLength);
                }
            }
            this.$emit("input",e.target.value);
            this.$nextTick(this.setNativeInputValue);
            //限制小数原本写法
            // if(this.decimal>0){
            //     e.target.value = (e.target.value.match(/^\d*(\.?\d{0,2})/g)[0]) || null;
            // }
            // this.$emit("input",e.target.value);
        },
        //输入改变，并失去焦点
        change(e){
            this.$emit("change",e.target.value);
        },
        //失去焦点
        blur(e){
            this.$emit("blur",e.target.value);
        }
    },
    watch:{
        value() {
            this.setNativeInputValue();
        },
    }
}
</script>

<style scoped lang='less'>
.ex-input {
    display: inline-block;
    width: 100%;
    height: 32px;
    line-height: 1.5;
    padding: 4px 7px;
    font-size: 14px;
    border: 1px solid #dcdee2;
    border-radius: 4px;
    color: #666;
    background-color: #fff;
    background-image: none;
    position: relative;
    cursor: text;
    transition: border 0.2s ease-in-out, background 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}
.ex-input-large {
    font-size: 16px;
    padding: 6px 7px;
    height: 40px;
}
.ex-input-max {
    background-color: #111;
    border-color: transparent;
    color: #c8c7d8;
    caret-color: #c8c7d8;
}
.ex-input::-webkit-input-placeholder {
    color: #c8c7d8;
}
/*去掉上下箭头*/
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
-webkit-appearance: none;
}

input[type="number"] {
-moz-appearance: textfield;
}
</style>
