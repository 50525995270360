<!--合约交易-右边数据面板-->
<template>
<div class="tradeMainRight">
    <div class="trl" style="position:relative"><!--style="display:none;"-->
        <!--盘口，最新成交-->
        <div class="trTab">
            <div @click="upHandiCap('spanbook')" :class="{'active':handcapActive==='spanbook'}">{{$t("book")}}</div>
            <div @click="upHandiCap('trHistory')" :class="{'active':handcapActive==='trHistory'}">{{$t("trades")}}</div>
        </div>
        <div v-show="handcapActive==='spanbook'" class="orderBooks">
            <!--盘口-数量eth，价格usd-->
            <div class="orderBooksHead">
                <div class="spanL spanbook">{{$t("size")}}<span class="tag ml5">{{blockchain.abbreviation}}</span></div>
                <div class="spanC spanbook">{{$t("price")}}<span class="tag ml5">USD</span></div>
                <div class="spanR spanbook">{{$t("mine")}}</div>
            </div>
            <div id="container" class="orderBooksList">
                <ul class="sellorders orderlist">
                    <li v-for="(item,index) in book.sell" :key="index" @click="inputPrice(item.price)" :class="{'changeRed':item.changeType=='add','changeGrey':item.changeType=='remove','changeBGBlack':item.changeBg=='add','book_hover':item.price!=0,'printer':item.price!=0}">
                        <template v-if="item.price!=0">
                        <div class="spanL spanbook">{{item.amount | toFixed(blockchain.amountAccuracy)}}</div>
                        <div class="spanC spanbook">{{item.price | toFixed(blockchain.priceAccuracy)}}</div>
                        <div class="spanR spanbook">{{item.order && item.order.amount | toFixed(blockchain.amountAccuracy)}}</div>
                        <!--填充-->
                        <div class="orderVolBg">
                            <i :style="{'width':item.volumePercent+'px'}"></i>
                            <span :style="{'width':item.pricePercent+'px'}"></span>
                        </div>
                        </template>
                    </li>
                </ul>
                <!--价差-->
                <div class="priceNow">
                    <div class="spanL spanbook">{{$t("spread")}}</div>
                    <div class="spanC spanbook">{{priceRegion | toFixed(blockchain.priceAccuracy)}}</div>
                    <div class="spanR spanbook">{{priceRegionPercent>0?(priceRegionPercent.toFixed(2))+'%':'-'}}</div>
                </div>
                <ul class="buyorders orderlist">
                    <li v-for="(item,index) in book.buy" :key="index" @click="inputPrice(item.price)" :class="{'changeGreen':item.changeType=='add','changeGrey':item.changeType=='remove','changeBGBlack':item.changeBg=='add','book_hover':item.price!=0,'printer':item.price!=0}">
                        <template v-if="item.price!=0">
                        <div class="spanL spanbook">{{item.amount | toFixed(blockchain.amountAccuracy)}}</div>
                        <div class="spanC spanbook">{{item.price | toFixed(blockchain.priceAccuracy)}}</div>
                        <div class="spanR spanbook">{{item.order && item.order.amount | toFixed(blockchain.amountAccuracy)}}</div>
                        <div class="orderVolBg">
                            <i :style="{'width':item.volumePercent+'px'}"></i>
                            <span :style="{'width':item.pricePercent+'px'}"></span>
                        </div>
                        </template>
                    </li>
                </ul>
                <div id="container_bottom" style="visibility:hidden;"></div>
            </div>
        </div>
        <div v-if="handcapActive==='trHistory'" class="trHistory">
            <!--最新成交-->
            <div class="trHistoryHead">
                <div class="spanL spanbook">{{$t("size")}}<span class="tag ml5">{{blockchain.abbreviation}}</span></div>
                <div class="spanC spanbook">{{$t("price")}}<span class="tag ml5">USD</span></div>
                <div class="spanR spanbook">{{$t("time")}}</div>
            </div>
            <ul class="trHistoryList orderlist">
                <li class="book_hover" v-for="(item,index) in newTradesList" :key="index" :class="item.side==='buy'?'buyorders':'sellorders'">
                    <div class="spanL spanbook" :class="item.side=='buy'?'green':'red'">{{item.amount | toFixed(blockchain.amountAccuracy)}}</div>
                    <div class="spanC spanbook">{{item.price | toFixed(blockchain.priceAccuracy)}}</div>
                    <div class="spanR spanbook clo6">{{item.timeStr.substr(11,8)}}</div><!--:title="item.time"-->
                    <!--填充图形-->
                    <div class="orderVolBg"><span :style="{'width':item.amountPercent+'px'}"></span></div>
                </li>
                <li v-if="newTradesList.length===0" class="center clo6" style="justify-content:center;">{{$t('none')}}</li>
            </ul>
        </div>
        <loading-layer v-model="isHandicapLoading"></loading-layer>
    </div>
    <div class="trr" style="overflow-y:auto;">
        <div class="trrTab">
            <div @click="trrTabActive='price'" :class="{'active':trrTabActive==='price'}">{{$t("price")}}</div>
            <div @click="trrTabActive='depth'" :class="{'active':trrTabActive==='depth'}" >{{$t("depth")}}</div>
            <div @click="trrTabActive='funding'" :class="{'active':trrTabActive==='funding'}">{{$t("funding")}}</div>
            <div @click="trrTabActive='details'" :class="{'active':trrTabActive==='details'}">{{$t("detail")}}</div>
        </div>
        <!--价格-深度-资金-详情-->
        <div class="tradingView" :style="{'height':60+'%'}"
        :class="{'flexCenter':trrTabActive==='details'}" style="position:relative;overflow-y:auto;" ><!--:style="{'height':(isOpen?40:60)+'%'}"-->
            <!--价格-->
            <div v-show="trrTabActive==='price'">
                <!-- 这里嵌入Trading View 图表插件 -->
                <tradingview :isShow="trrTabActive==='price'"></tradingview>
            </div>
            <!--深度-->
            <div v-show="trrTabActive==='depth'" >
                <!--深度图-->
                <depth-chart :isShow="trrTabActive==='depth'"></depth-chart>
            </div>
            <!--资金-->
            <div v-if="trrTabActive==='funding'">
                <!--折线图-->
                <linechart :isShow="trrTabActive==='funding'"></linechart>
            </div>
            <!--详情-->
            <div v-show="trrTabActive==='details'" class="tradingViewDetail">
                <trade-detail style="overflow-y:auto;"></trade-detail>
            </div>
        </div>
        <!--当前持仓，订单，全部成交-->
        <div class="trrDown flexColumn" style="overflow-y:auto;">
            <div class="alignCenter">
                <div class="trrDownTab" style="flex:1;">
                    <div :class="userInfoActive==='position'?'active':''" @click="upUserInfoActive('position')">
                        {{$t("position")}}
                    </div>
                    <div :class="userInfoActive==='traderOrder'?'active':''" @click="upUserInfoActive('traderOrder')">
                        {{$t("orders")}}<span class="tag ml5">{{ordersDataList.length}}</span>
                    </div>
                    <div :class="userInfoActive==='fills'?'active':''" @click="upUserInfoActive('fills')">
                        {{$t("fills")}}<span class="tag ml5">{{fills.total}}</span><!--fills.total>=999?'999+':fills.total-->
                    </div>
                </div>
                <!--展开-->
                <!-- <div class="PMLmainTopRight" v-if="!isOpen && trrTabActive==='price'" @click="toggle('fold')">
                    <span class="mr5">{{this.$t("open")}}</span>
                    <img :src="require('@/assets/img/arrow.svg')" style="width:12px;height:12px;transform: rotate(-90deg);"/>
                </div>
                <div class="PMLmainTopRight" v-if="isOpen && trrTabActive==='price'" @click="toggle('open')">
                    <span  class="mr5">{{this.$t("fold")}}</span>
                    <img :src="require('@/assets/img/arrow.svg')" style="width:12px;height:12px;transform: rotate(90deg);"/>
                </div> -->
            </div>
            <div class="trrDownCon" style="flex:1;overflow-y:auto;position:relative;">
                <!--当前持仓-->
                <!--原版-->
                <!-- <now-position :data="{}"></now-position> -->
                <div class="pwh100" :class="{'shadow':isPositionShadow}" style="overflow:auto;" v-show="userInfoActive==='position'">
                    <div class="pwh100 positionPannel" ref="positionPannel" style="overflow:auto;">
                    <trade-table :columns="positionTitleList" :tableData="positionDataList" :noData="$t('position2')+$t('fullStop')" :key="`position`" @scrollBottom="scrollBottom('position')" :tableStyle="{'min-width':'1300px'}">
                        <template v-slot:default="props">
                            <td>{{props.scope.contract}}-USD</td>
                            <td>
                                <span v-if="props.scope.side==2" class="red">{{$t('_sell')}}</span>
                                <span v-if="props.scope.side==3" class="green">{{$t('_buy')}}</span>
                            </td>
                            <td>{{props.scope.size | toFixed(blockchain.amountAccuracy)}}</td>
                            <td>
                                {{props.scope.marginBalance | numFormat(2)}}
                                <!-- <span class="active_t ml5 alignCenter" @click="isDepositPop=true">
                                    <svg t="1644202833512" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2474" width="24" height="24"><path d="M512 832C688.73112 832 832 688.73112 832 512 832 335.26888 688.73112 192 512 192 335.26888 192 192 335.26888 192 512 192 688.73112 335.26888 832 512 832ZM485.333334 492 485.333334 332 538.666666 332 538.666666 492 698.666666 492 698.666666 545.333334 538.666666 545.333334 538.666666 705.333334 485.333334 705.333334 485.333334 545.333334 325.333334 545.333334 325.333334 492 485.333334 492Z" p-id="2475" fill="#6179fa"></path></svg>
                                </span> -->
                            </td>
                            <td>{{props.scope.bondRate*100 | toFixed(2)}}%</td>
                            <td>{{props.scope.positionAvgPrice | numFormat(2)}}</td>
                            <td>{{props.scope.signPrice | numFormat(2)}}</td>
                            <td>
                                <span v-if="props.scope.strongPrice>=0">{{props.scope.strongPrice | numFormat(1)}}</span>
                                <span v-else>0</span><!--{{$t('neverClose')}}-->
                            </td>
                            <!-- <td>{{props.scope.fundRate | toFixed(2)}}%</td> -->
                            <td>{{props.scope.profitLoss | numFormat(2)}}</td>
                            <td style="position:relative;">
                                <Dropdown>
                                    <a href="javascript:void(0)" style="display:block;text-align:center;"><Icon type="ios-more" size="32"/></a>
                                    <template #list>
                                        <DropdownMenu>
                                            <DropdownItem><div class="blue printer active_t" @click="e => closePosition(props.scope)">{{ $t('closePosition') }}</div></DropdownItem>
                                            <DropdownItem><div class="blue printer active_t" @click="e => showStopProfit(props.scope)">{{ $t('stopProfitSetting') }}</div></DropdownItem>
                                            <DropdownItem><div class="blue printer active_t" @click="e => showStopLoss(props.scope)">{{ $t('stopLossSetting') }}</div></DropdownItem>
                                        </DropdownMenu>
                                    </template>
                                </Dropdown>
                            </td>
                        </template>
                    </trade-table>
                    </div>
                    <loading-layer v-model="isTableLoading"></loading-layer>
                </div>
                <!--订单-->
                <div class="pwh100" :class="{'shadow':isOrderShadow}" style="overflow:auto;" v-show="userInfoActive==='traderOrder'">
                    <div class="pwh100 traderOrderPannel" ref="traderOrderPannel" style="overflow:auto;">
                    <trade-table :columns="ordersTitleList" :tableData="ordersDataList" :noData="$t('orders')+$t('fullStop')" :key="`traderOrder`" @scrollBottom="scrollBottom('traderOrder')"  :tableStyle="{'min-width':'1000px'}">
                        <template v-slot:default="props">
                            <td>
                                <!-- {{props.scope.perpetual}} -->
                                <!--合约需处理-->
                                <img :src="blockchain.img">
                                <div class="POMCSCitem">
                                    <div><span>{{blockchain.fullName}}</span></div>
                                    <div class="POMCSCitemContent">{{blockchain.abbreviation}}-USD</div>
                                </div>
                            </td>
                            <td>
                                <span v-if="props.scope.side=='LONG'" class="green">{{$t('buy')}}</span>
                                <span v-else-if="props.scope.side=='SHORT'" class="red">{{$t('sell')}}</span>
                            </td>
                            <td>
                                {{props.scope.matchedAmountPPB/1E9 | toFixed(blockchain.amountAccuracy)}}/{{props.scope.amountPPB/1E9 | toFixed(blockchain.amountAccuracy)}}
                            </td>
                            <td>
                                {{props.scope.pricePPB/1E9 | numFormat(blockchain.amountAccuracy)}}
                            </td>
                            <!-- <td>{{props.scope.triggerPrice | toFixed(blockchain.amountAccuracy)}}</td> -->
                            <td style="flex-direction:column;align-items:flex-start;">
                                <div>{{props.scope.expireStr.substr(0,10)}}</div>
                                <div style="opacity: 0.5;">{{props.scope.expireStr.substr(11,8)}}</div>
                            </td>
                            <td>
                                <span class="blue printer active_t" @click="cancelOrder(props.scope.hash)">{{$t('cannel')}}</span>
                            </td>
                        </template>
                    </trade-table>
                    </div>
                    <loading-layer v-model="isOrderLoading"></loading-layer>
                </div>
                <!--全部成交-->
                <div class="pwh100" :class="{'shadow':isFillShadow}" style="overflow:auto;" v-show="userInfoActive==='fills'">
                    <div class="pwh100 fillsPannel" ref="fillsPannel" style="overflow:auto;">
                    <trade-table :columns="fillsTitleList" :tableData="fillsDataList" :noData="$t('fills')+$t('fullStop')" key="fills" @scrollBottom="scrollBottom('fills')" :tableStyle="{'min-width':'1200px'}">
                        <template v-slot:default="props">
                            <td style="flex-direction:column;align-items:flex-start;">
                                <div>{{props.scope.timeStr.substr(0,10)}}</div>
                                <div style="opacity: 0.5;">{{props.scope.timeStr.substr(11,8)}}</div>
                            </td>
                            <td>
                                <span v-if="props.scope.type=='market'">{{$t('market')}}</span>
                                <span v-else>{{$t('limit')}}</span>
                                <!-- <span v-else-if="props.scope.type=='stopLimit'">{{$t('stopLimit')}}</span>
                                <span v-else-if="props.scope.type=='trailingStop'">{{$t('trailingStop')}}</span> -->
                            </td>
                            <td>
                                <span v-if="props.scope.side=='LONG'" class="green">{{$t('buy')}}</span>
                                <span v-else class="red">{{$t('sell')}}</span>
                            </td>
                            <td :class="props.scope.side=='LONG'?'green':'red'">
                                <Icon v-if="props.scope.side=='LONG'" type="md-arrow-dropup"></Icon>
                                <Icon v-else type="md-arrow-dropdown" />
                                {{props.scope.volume | toFixed(blockchain.amountAccuracy)}}
                            </td>
                            <td>
                                {{props.scope.price | numFormat(blockchain.amountAccuracy)}}
                            </td>
                            <td>{{props.scope.amount | numFormat(blockchain.amountAccuracy)}}/{{props.scope.fee*100/props.scope.amount | format(2,'%')}}</td>
                            <td>
                                <template v-if="props.scope.type=='limit'">{{$t('maker')}}</template>
                                <template v-if="props.scope.type=='market'">{{$t('taker')}}</template>
                            </td>
                            <td>
                                <span v-if="props.scope.status=='0'">{{$t('processing')}}</span>
                                <span v-if="props.scope.status=='1'">{{$t('successfulChaining')}}</span>
                                <span v-if="props.scope.status=='2'">{{$t('uplinkFailure')}}</span>
                            </td>
                        </template>
                    </trade-table>
                    </div>
                    <loading-layer v-model="isFillLoading"></loading-layer>
                </div>
            </div>
        </div>
    </div>
    <loading-pop v-model="isLoadingPop" :text="loadingText"></loading-pop>
    <!--存取款-->
    <marginControlPop v-if="isDepositPop" v-model="isDepositPop" :marginBalance="marginBalance" :availableMargin="availableMargin"></marginControlPop>

    <Modal v-model="sp.show" width="400px" :mask-closable="false">
        <template slot="header">
        <Icon type="information-circled"></Icon>
        <span>{{ $t('stopProfitSetting') }}</span>
        </template>
        <div class="form-v">
            <div class="position-info">
                <div><span class="field-label">{{ $t('Size') }}</span><span class="field-value">{{ numberFormat(sp.p.size) }} ETH</span></div>
                <div><span class="field-label">{{ $t('Side') }}</span><span class="field-value">{{ formatSide(sp.p.side) }}</span></div>
                <div><span class="field-label">{{ $t('CostPrice') }}</span><span class="field-value">{{ usdFormat(sp.p.positionAvgPrice) }}</span></div>
                <div><span class="field-label">{{ $t('profitLoss') }}</span><span class="field-value">{{ usdFormat(sp.p.profitLoss) }}</span></div>
                <div><span class="field-label">{{ $t('signPrice') }}</span><span class="field-value">{{ usdFormat(sp.p.signPrice) }}</span></div>
                <div><span class="field-label">{{ $t('Change') }}</span><span class="field-value">{{ numberFormat(calcProfitLossChange(sp.p, sp.p.signPrice)) }}%</span></div>
            </div>

            <div class="form-item-v">
                <label class="form-item-label">{{ $t('StopProfitPrice') }}</label>

                <InputNumber
                style="display:block;width:100%"
                :min="sp.min"
                :max="sp.max"
                :step="sp.step"
                :precision="blockchain.priceAccuracy"
                v-model="sp.price"
                :formatter="v => '$'+v"
                :parser="v => v.replaceAll('$', '').replaceAll(',','').replaceAll(' ','')"/>

                <div class="flex-row">
                    <div><span class="field-label">{{ $t('Change') }}</span><span class="field-value">{{ numberFormat(calcProfitLossChange(sp.p, sp.price)) }}%</span></div>
                    <div><span class="field-label">{{ $t('profitLoss') }}</span><span class="field-value">≈ {{ usdFormat(calcProfitLoss(sp.p, sp.price)) }}</span></div>
                </div>
            </div>

            <div class="form-item-v">
                <label class="form-item-label">{{ $t('CloseOrderType') }}:</label>
                <Select v-model="sp.type">
                    <Option value="limit">{{ $t('LimitOrder') }}</Option>
                        <Option value="market">{{ $t('MarketOrder') }}</Option>
                </Select>
            </div>
    
            <div class="form-item-v">
                <label class="form-item-label">{{ $t('Amount') }}(ETH):</label>
                <InputNumber
                    style="display:block;width:100%"
                    :step="0.1"
                    :precision="blockchain.amountAccuracy"
                    :min="Math.pow(10,-blockchain.amountAccuracy)"
                    v-model="sp.amount"
                    />
                <div class="flex-row">
                    <span class="percent-btn" @click="e => setAmount(sp, sp.p.size * 0.25)">25%</span>
                    <span class="percent-btn" @click="e => setAmount(sp, sp.p.size * 0.5)">50%</span>
                    <span class="percent-btn" @click="e => setAmount(sp, sp.p.size * 0.75)">75%</span>
                    <span class="percent-btn" @click="e => setAmount(sp, sp.p.size)">100%</span>
                </div>
            </div>
        </div>

        <template slot="footer">
        <Button type="info" size="large" long @click="setStopProfit">
            <span>{{ $t('stopProfitSetting') }}</span>
        </Button>
        </template>
    </Modal>
     <Modal v-model="sl.show" width="400px" :mask-closable="false">
        <template slot="header">
        <Icon type="information-circled"></Icon>
        <span>{{ $t('stopLossSetting') }}</span>
        </template>
        <div class="form-v">
            <div class="position-info">
                <div><span class="field-label">{{ $t('Size') }}</span><span class="field-value">{{ numberFormat(sl.p.size) }} ETH</span></div>
                <div><span class="field-label">{{ $t('Side') }}</span><span class="field-value">{{ formatSide(sl.p.side) }}</span></div>
                <div><span class="field-label">{{ $t('CostPrice') }}</span><span class="field-value">{{ usdFormat(sl.p.positionAvgPrice) }}</span></div>
                <div><span class="field-label">{{ $t('profitLoss') }}</span><span class="field-value">{{ usdFormat(sl.p.profitLoss) }}</span></div>
                <div><span class="field-label">{{ $t('signPrice') }}</span><span class="field-value">{{ usdFormat(sl.p.signPrice) }}</span></div>
                <div><span class="field-label">{{ $t('Change') }}</span><span class="field-value">{{ numberFormat(calcProfitLossChange(sl.p, sl.p.signPrice)) }}%</span></div>
            </div>

            <div class="form-item-v">
                <label class="form-item-label">{{ $t('StopLossPrice') }}</label>

                <InputNumber
                style="display:block;width:100%"
                :min="sl.min"
                :max="sl.max"
                :step="sl.step"
                :precision="blockchain.priceAccuracy"
                v-model="sl.price"
                :formatter="v => '$' + v"
                :parser="v => v.replaceAll('$', '').replaceAll(',', '').replaceAll(' ', '')"/>

                <div class="flex-row">
                    <div><span class="field-label">{{ $t('Change') }}</span><span class="field-value">{{ numberFormat(calcProfitLossChange(sl.p, sl.price)) }}%</span></div>
                    <div><span class="field-label">{{ $t('profitLoss') }}</span><span class="field-value">≈ {{ usdFormat(calcProfitLoss(sl.p, sl.price)) }}</span></div>
                </div>
            </div>

            <div class="form-item-v">
                <label class="form-item-label">{{ $t('CloseOrderType') }}:</label>
                <Select v-model="sl.type">
                    <Option value="limit">{{ $t('LimitOrder') }}</Option>
                    <Option value="market">{{ $t('MarketOrder') }}</Option>
                </Select>
            </div>

            <div class="form-item-v">
                <label class="form-item-label">{{ $t('Amount') }}(ETH):</label>
                <InputNumber
                    style="display:block;width:100%"
                    :step="0.1"
                    :precision="blockchain.amountAccuracy"
                    :min="Math.pow(10, -blockchain.amountAccuracy)"
                    v-model="sl.amount"
                    />
                <div class="flex-row">
                    <span class="percent-btn" @click="e => setAmount(sl, sl.p.size * 0.25)">25%</span>
                    <span class="percent-btn" @click="e => setAmount(sl, sl.p.size * 0.5)">50%</span>
                    <span class="percent-btn" @click="e => setAmount(sl, sl.p.size * 0.75)">75%</span>
                    <span class="percent-btn" @click="e => setAmount(sl, sl.p.size)">100%</span>
                </div>
            </div>
        </div>

        <template slot="footer">
        <Button type="info" size="large" long @click="setStopLoss">
            <span>{{ $t('stopLossSetting') }}</span>
        </Button>
        </template>
    </Modal>
</div>
</template>

<script>
import {getPositionTitleList,getFillsTitleList,getOrdersTitleList} from '../const/const.js'
import { accSub,maxArrObj,minArrObj,calcPercent,mergeArr,createAutoData,compare,dragMoveX } from "@/utils/base"
import { buildOrder,signTypedDataV4 } from "@/utils/signFn/order";//签名函数，生成订单
import WalletUtil from "@/utils/walletUtil"
import config from "@/config";
import { subOrder,getOrder,getAllOrder,cancelOrder,sendMsg,userFee, getOrderBook, getLatestDeals } from "@/api/http"
import tradingview from '@/components/tradingview/index.vue'
import linechart from '@/components/linechart/index.vue'
import depthChart from '@/components/depthChart/index.vue'
import loadingPop from "@/components/loadingPop.vue"
import tradeTable from "@/components/tradeTable.vue"
import NowPosition from "./nowPosition.vue"
import TradeDetail from "./tradeDetail.vue"
import ContractUtil from "@/utils/contractUtil"
//生成空对象数组
//len：长度，volumeRange：数量范围，priceRange：价格。
function getEmptyLen(len=10,volumeRange,priceRange){
    let arr=[];
    for(let i=0;i<len;i++){
        if(volumeRange!=0 || priceRange!=0){
            arr.push({
                amount:createAutoData(1,volumeRange,1)[0],
                price:createAutoData(1,priceRange+100,priceRange-100)[0]
            });
        }else{
            arr.push({
                amount:0,
                price:0
            });
        }
    }
    arr.sort(compare('price')).reverse();
    return arr;
}
//判断数组的每一项：同一价格的数量，是否增加或者减少
//oldArr：旧数组,newArr：新数组
function convertArr(oldArr,newArr){
    newArr.forEach(item=>{
        // 这里需要判断价格不为零
        if(item.price!=0){
            let isKey=oldArr.some((v)=>item.price == v.price);
            if(!isKey){     //新增
                item.changeBg='add';
            }else{
                oldArr.forEach(v=>{
                    //价格相等，数量增加或减少
                    if(v.price == item.price){
                        if(item.amount > v.amount){
                            item.changeType='add';
                        }else if(item.amount < v.amount){
                            item.changeType='remove';
                        }
                    }
                });
            }
        }
    });
    return newArr;
}
import marginControlPop from '@/components/marginControlPop/marginControlPop.vue'

let loading=false;
let getNowId=null;
let getNewPositionTimeoutId=0

let loadDataTimeoutId=0

const formatter = new Intl.NumberFormat(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
})

const usdFormatter = new Intl.NumberFormat("en-US", {
    style: 'currency',
    currency: 'USD'
})

export default {
    components: {
        tradeTable,tradingview,NowPosition,TradeDetail,linechart,depthChart,loadingPop,marginControlPop
    },
    data () {
        return {
            sp:{ //止盈
                p: {},
                step: 0.1,
                show: false,
                type:'limit',
                price:0,
                amount:0,
            },
            sl: { //止损
                p: {},
                step: 0.1,
                show: false,
                type: 'limit',
                price: 0,
                amount: 0,
            },

            isLoadingPop:false,//加载中弹窗
            loadingText:'',//加载中文字
            isHandicapLoading:true,//盘口加载中
            isTableLoading:true,//持仓加载中
            isOrderLoading:true,//订单加载中
            isFillLoading:true,//全部成交加载中
            isPositionShadow:false,//持仓阴影
            isOrderShadow:false,//订单阴影
            isFillShadow:false,//全部成交阴影
            /**标签栏 */
            handcapActive: 'spanbook',//盘口:spanbook,成交:trHistory
            trrTabActive: 'price',//价格：price，深度：depth，资金：funding，详情：details
            userInfoActive:'position',//持仓:position,订单:traderOrder,成交:fills
            /**盘口最新成交 */
            book:{
                sell:getEmptyLen(30,0,0),//盘口卖出
                buy:getEmptyLen(30,0,0),//盘口买入
            },
            newTradesList:[],//最新成交订单列表
            barsList:[],//k线图数据
            /**用户信息（右下角表格） */
            positionTitleList:getPositionTitleList(),//当前持仓,表格标题
            ordersTitleList:getOrdersTitleList(),//订单,表格标题
            //全部成交表单
            fills:{
                loading:false,//加载中
                current:1,//当前页
                finished:false,//是否完成
                total:0,//总页
            },
            fillsTitleList:getFillsTitleList(),//全部成交,表格标题
            positionDataList:[],//当前持仓数据
            ordersDataList:[],//订单数据
            fillsDataList:[],//全部成交数据
            // isOpen:JSON.parse(sessionStorage.getItem('tradeMainRight_isOpen')) || false,//是否展开
            /**存款弹窗内容 */
            isDepositPop:false,//存款弹窗
            marginBalance:'',
            availableMargin:'',
        };
    },
    mounted () {
        // this.randomBook();//随机盘口
        $('#container').animate({scrollTop:$('#container_bottom').offset().top/2-$('#container').height()/2-60},0);
        
        this.getNowData();
        //监听更新订单
        this.$eventBus.$on("changeOrder",()=>{
            this.getOrder();
        });
        //监听更新当前持仓
        this.$eventBus.$on("update_current_position", () => {
            this.getNowPosition();
        });
        //监听标记价格变动
        this.$eventBus.$on("changeMarkPrice",(e)=>{
            if(this.positionDataList.length>0){
                var obj=this.positionDataList[0];
                obj.markPrice=e;
                this.$set(this.positionDataList,0,obj);
            }
        });
        //横向拖动表格
        dragMoveX('.positionPannel');
        dragMoveX('.traderOrderPannel');
        dragMoveX('.fillsPannel');
        //阴影
        this.setShadow('positionPannel','isPositionShadow');
        this.setShadow('traderOrderPannel','isOrderShadow');
        this.setShadow('fillsPannel','isFillShadow');

        this.loadData();
        
    },
    methods: {
        async loadData(){
            if(this.blockchain.perpetual){
                await Promise.all([
                    getOrderBook(this.blockchain.perpetual).catch(err=>console.warn(err.message)),
                    getLatestDeals(this.blockchain.perpetual).catch(err=>console.warn(err.message)),
                    this.loadUserData(),
                ]);
            }
            loadDataTimeoutId = setTimeout(()=>this.loadData(), 3500);
        },
        async loadUserData(){
            if(this.addr){

            }
        },

        setAmount(cp,amount){
            cp.amount=Number(amount.toFixed(this.blockchain.amountAccuracy));
        },
        setPrice(cp,price){
            cp.price= Number(price.toFixed(this.blockchain.priceAccuracy));
        },

        formatSide(side){
            switch(parseInt(side)){
                case 3:return this.$t('LONG')
                case 2:return this.$t('SHORT')
                case 1:return this.$t('EMPTY')
                case 0:return this.$t('FLAT')
            }
            return  '--'
        },
        numberFormat(v){
            if(v==null){
                return '--'
            }
            return formatter.format(v);
        },
        usdFormat(v){
            if (v == null) {
                return '--'
            }
            return usdFormatter.format(v);
        },
        calcProfitLoss(p,price){
            if(p.side==0 || p.side==1){
                return 0;
            }
            
            if(p.side==3){
                return (price - p.positionAvgPrice) * p.size + p.otherProfitLoss
            }else if(p.side==2){
                return (p.positionAvgPrice - price) * p.size + p.otherProfitLoss
            }
            return 0;
        },
        calcProfitLossChange(p,price){
            if (p.side == 0 || p.side == 1) {
                return 0;
            }

            if (p.side == 3) {
                return (price - p.positionAvgPrice)*100/p.positionAvgPrice
            } else if (p.side == 2) {
                return (p.positionAvgPrice - price)*100/p.positionAvgPrice
            }
            return 0;
        },

        //设置表格阴影
        setShadow(element,value){
            //监听滚动，控制阴影显隐
            this.$refs[element].addEventListener('scroll',(event)=>{
                let scrollLeft=event.target.scrollLeft;
                if(scrollLeft==0){
                    this[value]=false;
                }else{
                    this[value]=true;
                }
            });
        },
        //点击盘口输入价格
        inputPrice(price){
            if(price){
                this.$eventBus.$emit("inputPrice",price);
            }
        },
        /**获取当前持仓 */
        getNowData(){
            // const equipment = new ContractUtil.web3.eth.Contract(ContractUtil.perpetual.abi,this.blockchain.perpetual);
            // const equipment2 =  new ContractUtil.web3.eth.Contract(ContractUtil.amm.abi,this.blockchain.fundmork);
            // const exchange =  new ContractUtil.web3.eth.Contract(ContractUtil.exchange.abi,this.blockchain.exchange);
            // let addr=this.$store.state.app.wallet.address;
            // //取款
            // equipment.events.Withdraw({filter:{trader:addr}},(error,events)=>{
            //     this.getNowPosition();
            // })
            // //存款
            // equipment.events.Deposit({filter:{trader:addr}},(error,events)=>{
            //     this.getNowPosition();
            // })
            // //清算
            // equipment.events.Liquidate({filter:{trader:addr}},(error,events)=>{
            //     this.getNowPosition();
            // })
            // //
            // exchange.events.MatchWithOrders({filter:{perpetual:this.blockchain.perpetual}},(error,events)=>{
            //     this.getNowPosition();
            // })
        },
        //账单
        bill(){

        },

        showStopProfit(p){
            //enum Side {FLAT, EMPTY, SHORT, LONG}
            if (p.side == 0 || p.side == 1) {
                return;
            }

            const step= Math.round(p.positionAvgPrice * 2) / 1000;
            const d={
                p,
                step: Number(step.toFixed(this.blockchain.priceAccuracy)),
                amount: p.size,
                price: p.signPrice,
                type: 'limit',
                show :true
            }

            if (p.side == 3) {
                d.min = p.signPrice;
            } else if( p.side == 2 ) {
                d.max = p.signPrice;
            }
            this.sp=d;
        },
        showStopLoss(p){
            //enum Side {FLAT, EMPTY, SHORT, LONG}
            if (p.side == 0 || p.side == 1) {
                return;
            }
            const step = Math.round(p.positionAvgPrice * 2) / 1000;
            const d = {
                p,
                step: Number(step.toFixed(this.blockchain.priceAccuracy)),
                amount: p.size,
                price: p.signPrice,
                type: 'limit',
                show: true
            }

            if (p.side == 3) {
                d.max = p.signPrice;
            } else if( p.side == 2 ) {
                d.min = p.signPrice;
            }
            this.sl=d;
        },

        //平仓
        async closePosition(p){
            //enum Side {FLAT, EMPTY, SHORT, LONG}
            if (p.side == 0 || p.side == 1) {
                return;
            }

            const order = {
                type: 'market',
                price: 0,
                amount: p.size,
                side: p.side == 3 ? 'sell' : 'buy',
                close:1,
                perpetual: p.perpetual,
                broker: p.broker,
            }
            return this.createOrder(order)
        },

        //设置止盈
        async setStopProfit() {
            this.setPrice(this.sp, this.sp.price);
            this.setAmount(this.sp, this.sp.amount);
             const { type, price, amount, p } = this.sp;
            //enum Side {FLAT, EMPTY, SHORT, LONG}
            if (p.side == 0 || p.side == 1) {
                return;
            }

            const order = {
                type,
                price: type == 'market' ? 0 : price,
                amount,
                side: p.side == 3 ? 'sell' : 'buy',
                keepMinPrice: p.side == 3 ? 0 : price,
                keepMaxPrice: p.side == 3 ? price : 0,
                close:0,
                perpetual: p.perpetual,
                broker: p.broker,
            }
            await this.createOrder(order)
            this.sp.show=false;
        },

        //设置止损
        async setStopLoss() {
            this.setPrice(this.sl, this.sl.price);
            this.setAmount(this.sl, this.sl.amount);
            const { type, price, amount, p} = this.sl;
            //enum Side {FLAT, EMPTY, SHORT, LONG}
            if(p.side==0 || p.side==1){
                return;
            }
            const order={
                type,
                price: type == 'market' ? 0 : price,
                amount,         
                side: p.side == 3 ? 'sell' : 'buy',
                keepMinPrice: p.side==3 ? price : 0,
                keepMaxPrice: p.side==3 ? 0 : price,
                close:0,
                perpetual: p.perpetual,
                broker: p.broker,
            }
            await this.createOrder(order)
            this.sl.show = false;
        },

        async createOrder(order) {
            if (loading) {
                this.$Message.success(this.$t('loading'));
                return false;
            }
            if(!this.addr){
                return;
            }

            const addr=this.addr;
            let resUserFee = await userFee(this.addr);
            if (resUserFee.code != 200) { this.$Message.error(this.$t('operationFailed')); return; }
            loading = true;
            const unsignedData = {
                trader: addr,//用户地址
                version: 2,//版本
                makerFeeRate: resUserFee.data.makerFeeRate,
                takerFeeRate: resUserFee.data.takerFeeRate,
                leverage: 25,
                close: 1,
                ...order,
            }
            var res = await signTypedDataV4(unsignedData, order.perpetual, order.broker, addr).then(async data => {
                loading = false;
                data.signature = JSON.stringify(data.signature); 
                data.type = unsignedData.type;     //类型。market：市价，limit：限价
                data.side = unsignedData.side;     //买和卖
                data.leverage = unsignedData.leverage;
                data.close = unsignedData.close;//平仓
                if(order.keepMinPrice > 0){
                    data.keepMinPrice = order.keepMinPrice;
                }
                
                if(order.keepMaxPrice >  0){
                    data.keepMaxPrice = order.keepMaxPrice;
                }

                // data.makerFee= tradeFee;//资金费率
                // data.takerFee= tradeFee;//资金费率
                this.isLoadingPop = true;
                this.loadingText = this.$t('closingPosition');
                await subOrder(data)
                    .then(res => {
                        this.isLoadingPop = false;
                        if (res.code == 200) {
                            this.$Message.success(this.$t('operationSucceeded'));
                            this.$eventBus.$emit("update_current_position");//更新当前持仓
                            this.$eventBus.$emit("changeBalance");//余额变动
                        } else if (res.code == 600) {      //未匹配成功
                            this.$Message.error(this.$t('insufficientLiquidity'));
                        } else if (res.code == 601) {      //未匹配到订单
                            this.$Message.error(this.$t('orderNotMatched'));
                        } else if (res.code == 602) {      //您未有内测资格
                            this.$Message.error(this.$t('youNotTest'));
                        } else {
                            // this.$Message.error(this.$t('operationFailed'));
                            this.$Message.error(res.msg);
                        }
                    })
                    .catch(err => {
                        console.warn(err);
                    });
            }).catch(err => {
                loading = false;
                console.warn(err);
            });
        },

        //切换展开折叠
        // toggle(val){
        //     this.isOpen=val!='open'?true:false;
        //     sessionStorage.setItem('tradeMainRight_isOpen',JSON.stringify(this.isOpen));
        // },
        //获取当前持仓
        async getNowPosition(){
            try{
                clearTimeout(getNewPositionTimeoutId)
                //实例
                let res=await ContractUtil.getTraderPosition(this.blockchain.contractReader,this.blockchain.perpetual,this.addr);
                this.isTableLoading=false;
                if(res[4].side!=2 && res[4].side!=3){    //没有持仓
                    this.positionDataList=[];
                    return;
                }
                let p=Number(1+('0').repeat(18));//18次方
                let size=Number(res.marginAccount.size)/p,
                    cashBalance=Number(res.marginAccount.cashBalance)/p,
                    entryValue=Number(res.marginAccount.entryValue)/p;
                let marginBalance=Number(res.marginBalance)/p,
                    markPrice=Number(res.markPrice)/p,
                    accumulatedFundingPerContract=Number(res[3][10].accumulatedFundingPerContract)/p,
                    maintenanceMargin=Number(res.maintenanceMargin)/p,
                    entryFundingLoss=Number(res.marginAccount.entryFundingLoss)/p,
                    entrySocialLoss=Number(res.marginAccount.entrySocialLoss)/p,
                    longFundingLoss=Number(accumulatedFundingPerContract*size-entryFundingLoss),
                    shortSocialLossPerContract=Number(res[3].shortSocialLossPerContract)/p,
                    longSocialLossPerContract=Number(res[3].longSocialLossPerContract)/p,
                    shortSocialLoss = Number(shortSocialLossPerContract*size-entrySocialLoss),
                    longSocialLoss = Number(longSocialLossPerContract*size-entrySocialLoss);
                let strongPrice=0,
                    profitLoss=0,
                    otherProfitLoss =0;
                if(res[4].side==2){    //做空
                    strongPrice=((cashBalance+entryValue+longFundingLoss-shortSocialLoss)/(size*0.03+size)).toFixed(2);
                    profitLoss=entryValue-markPrice*size-shortSocialLoss+longFundingLoss;
                    otherProfitLoss= -shortSocialLoss + longFundingLoss;
                }else if(res[4].side==3){      //做多
                    strongPrice=((cashBalance-entryValue-longFundingLoss-longSocialLoss)/(size*0.03-size)).toFixed(2);
                    profitLoss=markPrice*size-entryValue-longSocialLoss-longFundingLoss;
                    otherProfitLoss = -longSocialLoss - longFundingLoss;
                }
                let arr=[{
                    contract:this.blockchain.abbreviation,//合约
                    side:res[4].side,//方向
                    size:size,//大小
                    marginBalance:marginBalance,//保证金
                    bondRate:maintenanceMargin/marginBalance,
                    // bondRate:marginBalance != 0 ? (cashBalance*0.03)/marginBalance : 0,//保证金率
                    positionAvgPrice:size != 0 ? entryValue/size : 0,//持仓均价
                    signPrice:markPrice,//标记价格
                    strongPrice:strongPrice,//强平价格
                    profitLoss:profitLoss,//盈亏
                    otherProfitLoss,
                    ...this.blockchain,
                    rawRes:res,
                }]
                this.$eventBus.$emit("changeMarkPrice",markPrice);//标记价格变动
                
                //考虑到定时器取消后，当前持仓已经开始获取了
                if(!this.isInitWallet){
                    this.positionDataList=[];
                }else{
                    this.positionDataList=arr;
                }
                //存取款弹窗数据
                this.marginBalance=res.marginBalance.toString()/p;
                this.availableMargin= res?.walletMargin?.freeMargin ?? (res.availableMargin.toString()/p);
            }catch(err){
                getNewPositionTimeoutId=setTimeout(()=>this.getNowPosition(),2000)
                console.warn(err.message)
            }
        },
        //取消订单 side：买、卖
        cancelOrder(orderHash){
            this.$Modal.confirm({
                title: `${this.$t('cancelOrderConfirm')}`,
                content: '',
                okText:`${this.$t('confirm')}`,
                cancelText:`${this.$t('cancel')}`,
                onOk: () => {
                    if(!this.addr){
                        return;
                    }
                    cancelOrder(this.addr,{
                        orderHash,
                    }).then(res=>{
                        if(res.code == 200){
                            this.$Message.success(this.$t('operationSucceeded'));
                            this.getOrder();
                        }else{
                            this.$Message.error(this.$t('operationFailed'));
                        }
                    });
                },
                onCancel: () => {
                }
            });
            
        },
        //获取订单（表格）
        getOrder(){
            if(!this.addr || !this.blockchain.perpetual){
                return;
            }
            getOrder(this.addr, this.blockchain.perpetual).then(res=>{
                this.isOrderLoading=false;
                if(res.code == 200){
                    this.ordersDataList=res.data || [];
                    this.mergeMyOrder();
                }else{
                    this.$Message.error(this.$t('operationFailed'));
                }
            })
            .catch(err=>{
                console.warn(err);
            });
        },
        //表格触底
        scrollBottom(val){
            //持仓:position,订单:traderOrder,成交:fills
            if(val=='position'){

            }else if(val=='traderOrder'){

            }else if(val=='fills' && !this.fills.finished &&  !this.fills.loading){
                this.fills.current++;
                this.getAllOrder();
            }
        },
        //获取全部订单
        getAllOrder(){
            if(!this.blockchain.perpetual || !this.addr){
                return;
            }

            let d={
                page:this.fills.current,
                size:15,
                perpetual:this.blockchain.perpetual
            };
            this.fills.loading=true;
            getAllOrder(this.addr, d).then(res=>{
                this.isFillLoading=false;
                this.fills.loading=false;
                if(res.code == 200){
                    this.fills.total=res.data.total || 0;
                    let list=res.data.records || [];
                    // list.forEach(item=>{
                    //     item.serviceCharge=config.serviceCharge;
                    // });
                    //列表渲染三件套
                    // if(d.page == 1 && data.data.list.length <= 0){//暂无数据
                    //     no_log.show();
                    // }
                    if (list.length >= d.size) {
                        this.fills.finished = false;
                    } else {
                        this.fills.finished = true;
                    }
                    if(d.current==1){
                        this.fillsDataList=list;
                    }else{
                        this.fillsDataList=this.fillsDataList.concat(list);
                    }
                }else{
                    this.$Message.error(this.$t('operationFailed'));
                }
            })
            .catch(err=>{
                console.warn(err);
            });
        },
        //连接socket
        initSocket(){
            //盘口消息
            this.$eventBus.$on("handicap_msg",(res)=>{
                this.isHandicapLoading=false;
                let _buy=res.data.buy || [];
                let _sell=res.data.sell || [];
                _buy.forEach(item=>{
                    item.amount=item.a;
                    item.price=item.p;
                });
                _sell.forEach(item=>{
                    item.amount=item.a;
                    item.price=item.p;
                });
                let buy=convertArr(this.book.buy,_buy) || [];
                let sell=convertArr(this.book.sell,_sell) || [];
                let maxSellVolume=maxArrObj(sell,'amount');//最大卖出数量
                let maxBuyVolume=maxArrObj(buy,'amount');//最大买入数量
                let maxSellPrice=maxArrObj(sell,'price');//最大卖出价格
                let minSellPrice=minArrObj(sell,'price');//最小卖出价格
                let maxBuyPrice=maxArrObj(buy,'price');//最大买入价格
                let minBuyPrice=minArrObj(buy,'price');//最小买入价格
                sell.forEach(item=>{
                    //高亮颜色公式：当前数量/最大数量，填充范围（1px-10px）
                    item.volumePercent=item.amount!=0 ? calcPercent(item.amount,maxSellVolume)/10 : 1;
                    //底纹颜色公式：最大卖出价格-最小卖出价格/最大卖出价格-每一项价格，填充范围（1px-25px）
                    item.pricePercent=item.price!=0 ? calcPercent((maxSellPrice-minSellPrice)-(maxSellPrice-item.price),maxSellPrice-minSellPrice)/5 : 1;
                });
                buy.forEach(item=>{
                    item.volumePercent=item.amount!=0 ? calcPercent(item.amount,maxBuyVolume)/10 : 1;
                    item.pricePercent=item.price!=0 ? calcPercent(maxBuyPrice-item.price,maxBuyPrice-minBuyPrice)/5 : 1;
                });
                //我的订单判断
                
                this.book.buy=buy;
                this.book.sell=sell;
                this.mergeMyOrder();
                this.$store.commit('setBook',{buy,sell});
            });
            //最新成交
            this.$eventBus.$on("deals_msg",(res)=>{
                let maxAmount=(res.data && res.data.maxAmount) || 0;
                let list=(res.data && res.data.list) || [];
                //设置标题，根据不同交易对精度截取长度
                if(list.length>0){
                    window.document.title = `$${list[0].price&&list[0].price.toFixed(1)} ${this.blockchain.abbreviation}-USD`+' · '+config.website.title;
                }else{
                    window.document.title = `$${0} ${this.blockchain.abbreviation}-USD`+' · '+config.website.title;
                }
                //处理显示数据
                if(list.length>0){
                    if(maxAmount==0){
                        maxAmount=maxArrObj(list,'amount');//最大卖出数量
                    }
                    list.forEach(item=>{
                        try{
                        item.amountPercent=calcPercent(item.amount,maxAmount)/2;//1-50px
                        item.timeStr = new Date(item.time).toISOString();
                        }catch(err){
                            console.warn("---err--",err);
                        }
                    });
                    this.newTradesList=list;
                    let newPrice=list.length>=0 ? list[0].price : 0;
                    this.$store.commit('setNewPrice',newPrice);
                }
                this.$eventBus.$emit("deals");
            });
            setTimeout(()=>{
                //sendMsg({cmd:'handicap',data:this.addr});
                //sendMsg({cmd:'deals',data:this.addr});
            },0);
        },
        mergeMyOrder(){
            let myOrder=(this.ordersDataList||[]).map(o=>({price: o.pricePPB/1E9, amount: o.unmatchedAmountPPB/1E9, type: o.side=='LONG'?'buy':'sell'}));
            myOrder=mergeArr(myOrder,'price','amount','type');
            myOrder.forEach(item=>{
                if(item.type=='buy'){
                    this.book.buy.forEach((m,n)=>{
                        if(m.price==item.price){
                            m['order']=item;
                        }
                    });
                }else if(item.type=='sell'){
                    this.book.sell.forEach((m,n)=>{
                        if(m.price==item.price){
                            m['order']=item;
                        }
                    });
                }
            });
        },
        //盘口 spanbook 成交 trHistory
        upHandiCap(event){
            this.handcapActive = event;
        },
        //持仓订单成交 active
        upUserInfoActive(event){
            if(this.userInfoActive != event){
                //持仓:position,订单:traderOrder,成交:fills
                if(event=='traderOrder'){
                    this.isInitWallet && this.getOrder();
                }else if(event=='fills'){
                    this.fills.current=1;
                    this.fills.finished=false;
                    this.isInitWallet && this.getAllOrder();
                }
            }
            this.userInfoActive = event;
            this.initTable();
        },
        //刷新表格表头
        initTable(){
            this.positionTitleList=getPositionTitleList();
            this.ordersTitleList=getOrdersTitleList();
            this.fillsTitleList=getFillsTitleList();
        },
        //随机盘口
        randomBook(){
            setInterval(()=>{
                this.book.sell=getEmptyLen(30,200,3000);
                this.book.buy=getEmptyLen(30,200,2800);
                let maxSellVolume=maxArrObj(this.book.sell,'amount');//最大卖出数量
                let maxBuyVolume=maxArrObj(this.book.buy,'amount');//最大买入数量
                let maxSellPrice=maxArrObj(this.book.sell,'price');//最大卖出价格
                let minSellPrice=minArrObj(this.book.sell,'price');//最小卖出价格
                let maxBuyPrice=maxArrObj(this.book.buy,'price');//最大买入价格
                let minBuyPrice=minArrObj(this.book.buy,'price');//最小买入价格
                this.book.sell.forEach(item=>{
                    //高亮颜色公式：当前数量/最大数量，填充范围（1px-10px）
                    item.volumePercent=calcPercent(item.amount,maxSellVolume)/10;
                    //底纹颜色公式：最大值-当前值，最大值-最小值，填充范围（1px-25px）
                    item.pricePercent=calcPercent(((maxSellPrice-minSellPrice)-(maxSellPrice-item.price)),maxSellPrice-minSellPrice)/5;
                    //颜色
                    let r=Math.random()*10;
                    item.changeType= r>=7? 'add' : r>=4 ? 'remove' : '';
                    item.changeBg=Math.random()*10>=7 ? 'add': '';
                });
                this.book.buy.forEach(item=>{
                    item.volumePercent=calcPercent(item.amount,maxBuyVolume)/10;
                    item.pricePercent=calcPercent(((maxBuyPrice-item.price)),maxBuyPrice-minBuyPrice)/5;
                    let r=Math.random()*10;
                    item.changeType= r>=7? 'add' : r>=4 ? 'remove' : '';
                    item.changeBg=Math.random()*10>=7 ? 'add': '';
                });
            },1000);
        }
    },
    computed:{
        //保证金使用率
        marginBalanceRate(){
            return Math.max(0,this.availableMargin >=0 ? (this.marginBalance-this.availableMargin)/this.marginBalance*100 : 100);
        },
        //是否完成表单
        isForm(){
            return !this.form.amount || this.form.amount==0;
        },
        //多语言切换
        lang(){
            return this.$i18n.locale;
        },
        //地址
        addr(){
            return this.$store.state.app.wallet.address;
        },
        //价差
        priceRegion(){
            if(!this.book?.sell?.length ||!this.book?.buy?.length){
                return '';
            }

            if(this.book.sell[this.book.sell.length-1].price ==0 || this.book.buy[0].price==0){
                return '';
            }
            let region=accSub(this.book.sell[this.book.sell.length-1].price || 0,this.book.buy[0].price || 0);
            return region>0 ? region : '';
        },
        //价差比
        priceRegionPercent(){
            if(!this.book?.sell?.length ||!this.book?.buy?.length){
                return 0;
            }
            let p=this.book.buy[0]?.price!=0 ? this.priceRegion/this.book.buy[0]?.price : 0;
            return p*100 || 0;
        },
        blockchain(){
            return this.$store.state.blockchain;
        },
        //是否初始化钱包
        isInitWallet() {
            return this.$store.state.app.wallet.isInit;
        },
    },
    watch:{
        //切换交易对
        '$store.state.blockchain.perpetual':{
            handler:function(val,value){
                if(val){
                    this.book={
                        sell:getEmptyLen(30,0,0),//盘口卖出
                        buy:getEmptyLen(30,0,0),//盘口买入
                    };
                    this.newTradesList=[];//最新成交订单列表
                    if(this.isInitWallet){
                        clearInterval(getNowId);
                        this.isTableLoading=true;//表格加载中
                        this.isOrderLoading=true;//订单加载中
                        this.isFillLoading=true;//全部成交加载中
                        this.getNowPosition();
                        getNowId=setInterval(()=>{
                            this.getNowPosition();
                        },5000);
                        this.fills.current=1;
                        this.getAllOrder();
                        this.getOrder();
                        //监听当前持仓
                        this.getNowData();
                    }
                }
            },
            deep: false,
            immediate: false
        },
        //监听多语言切换
        lang:function(){
            this.initTable();
        },
        //是否登录钱包
        'isInitWallet':{
            handler:function(val,value){
                if(val){
                    clearInterval(getNowId);
                    this.isTableLoading=true;//表格加载中
                    this.isOrderLoading=true;//订单加载中
                    this.isFillLoading=true;//全部成交加载中
                    this.getNowPosition();
                    getNowId=setInterval(()=>{
                        this.getNowPosition();
                    },5000);
                    this.fills.current=1;
                    userFee(this.addr);
                    this.getAllOrder();
                    this.getOrder();
                }else{
                    this.isTableLoading=false;
                    this.isOrderLoading=false;
                    this.isFillLoading=false;
                    this.fills.total=0;
                    this.positionDataList=[];
                    this.ordersDataList=[];//订单数据
                    this.fillsDataList=[];//全部成交数据
                    clearInterval(getNowId);
                }
                setTimeout(()=>{
                    this.initSocket();
                },0);
            },
            deep: false,
            immediate: true
        },
    },
    beforeDestroy () {
        this.$eventBus.$off("update_current_position");
        this.$eventBus.$off("changeOrder");
        this.$eventBus.$off("handicap_msg");
        this.$eventBus.$off("deals_msg");
        this.$eventBus.$off("changeMarkPrice");
        clearInterval(getNowId);
        clearTimeout(loadDataTimeoutId);
    },
};
</script>

<style lang="less" scoped>
.tradeMainRight{flex:1;display:flex;
    .trl{width:300px;border-right:1px solid @borderColor;.column;}
    .trr{flex:1;.column;}
}
.trTab{display:flex;border-bottom:1px solid @borderColor;
    >div{height:40px;width:50%;box-sizing:border-box;.flexCenter;cursor:pointer;
        &:first-child{border-right:1px solid @borderColor;}
        &.active{background: #111;color:#fff;cursor:default;}
        &:hover{background: #000;}
    }
}
.spanbook{text-align:right;font-size:12px;position:relative;z-index:2;padding:3px 4px 3px 0;}
.spanL{flex:0 0 32%; }
.spanC{flex:0 0 24%; }
.spanR{flex:0 0 28%; padding-right:12px;}
.orderBooks{padding:0 0 0 0px;flex:1;overflow:hidden;.column;
    .orderBooksHead{.flexBetween;padding: 5px 0;}
    .orderBooksList{flex:1;overflow:auto;}
}
.trHistory{padding:0 0 0 0px;flex:1;overflow:hidden;.column;
    .trHistoryHead{.flexBetween;padding: 5px 0;}
    .trHistoryList{flex:1;overflow:auto;}
}
.orderlist>li{
    height:18px;
    .flexBetween;position:relative;margin-top:2px;
    // &:hover{background: @graybg;}
    .spanbook{padding:0 4px 0 0;
        &.spanR{padding-right:12px;}
    }
    .orderVolBg{
        height:100%;width:100%;position: absolute;
        i{display: block;position: absolute;left:0;top:0;height:100%;z-index:2;height:100%; .op4;}
        span{display: block;position: absolute;left:0;top:0;height:100%;z-index:1;height:100%; .op3; }
    }
}
.orderlist>.book_hover:hover{
    background: @graybg;
}
.priceNow{position:relative;.flexBetween;padding:2px 0;margin:5px 0;border-top:1px solid @borderColor;border-bottom:1px solid @borderColor;
    .spanL,.spanR{.op5;}
}
.sellorders{
    ul>li>.orderVolBg>i,ul>li>.orderVolBg>span{background: @error-color; }
    .orderVolBg>span{background: @error-color; }
    .orderVolBg>i{background: @error-color; }
}
.buyorders{
    ul>li>.orderVolBg>i,ul>li>.orderVolBg>span{background: @success-color; }
    .orderVolBg>span{background: @success-color; }
    .orderVolBg>i{background: @success-color; }
}
.trrTab{display: flex;border-bottom:1px solid @borderColor;
    >div{cursor: pointer;.flexCenter;height:40px;padding:0 30px;border-right:1px solid @borderColor;
        &.active{background: #111;color:#fff;cursor: default;}
        &:hover{background: #000;}
    }
}
.tradingViewDetail{width:80%;height:100%;.pt20;.pb20;
}

.trrDown{flex:1;border-top:1px solid @borderColor;
    
}
.trrDownTab{
    display: flex;border-bottom:1px solid @borderColor;
    >div{
        cursor: pointer;.flexCenter;height:40px;padding:0 30px;border-right:1px solid @borderColor;
        &.active{background: #111;color:#fff;cursor: default;}
        &:hover{background: #000;}
    }
}
.POMCSCitem{
    .POMCSCitemContent{.op5;}
}
.trrDownCon{
    .flexColumn;
}
/**盘口颜色渐变 */
.changeRed{
    animation: changeRed 1s;
}
@keyframes changeRed {
    0%{color:@error-color;}
    100%{color:@grey;}
}
.changeGreen{
    animation: changeGreen 1s;
}
@keyframes changeGreen {
    0%{color:@success-color;}
    100%{color:@grey;}
}
.changeGrey{
    animation: changeGrey 1s;
}
@keyframes changeGrey {
    0%{color:#75757e;}
    100%{color:@grey;}
}
.changeBGBlack{
    animation: changeBGBlack 1s;
}
@keyframes changeBGBlack {
    0%{background-color:#171717;}
    100%{background-color:@theme-color;}
}

.PMLmainTopRight {
    .alignCenter; cursor: pointer; padding: 5px 10px; margin-right:10px; border-radius: 15px; background:#333;
    &:active {
    background: #000;
    }
    &:hover{opacity:0.8;}
}
/**阴影 */
.shadow:after,
.shadow:before {
    content: ""; position: absolute; top: 0; height: 100%; opacity: 1; pointer-events: none; background: -webkit-linear-gradient( right, #000000a8 -62.95%,rgba(28, 28, 40, 0.04)); background: linear-gradient(270deg,#000000a8 -62.95%,rgba(28, 28, 40, 0.04)); -webkit-transition: opacity 0.2s ease; transition: opacity 0.2s ease;
}
.shadow:before {
    left: 0; width: 64px; -webkit-transform: rotate(180deg); transform: rotate(180deg); z-index: 1;
}
.shadow:after {
    right: 0; width: 144px;
}


.position-info {
    display:grid;
    grid-template-columns: 1fr 1fr;
    gap:4px;
    padding:8px;
    background:#181818;
    & > .flex-row > * {
        width: 50%;
    }
}

.form-item-v {
    display:flex;
    flex-direction: column;
    gap:4px;
}
.form-v {
    display:flex;
    flex-direction: column;
    gap:16px;
}

.field-label {
    font-size:0.9em;
    margin-right:8px;
    &:after {
        content:':';
    }
}
.field-value {
    font-size:0.9em;
    color:#888;
}

.percent-btn {
    display:inline-block;
    padding:4px 16px;
    border-radius: 4px;
    background:#1f1f1f;
    cursor:pointer;
}

.flex-grid {
    display:grid;
    gap:4px;
}

.flex-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

</style>
