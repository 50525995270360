<!--详情-->
<template>
<div class="flexBox">
    <div class="TVDcontnetLeft">
        <div class="TVDCLtitle">
            <img :src="blockchain.img">
            <span>{{blockchain.fullName}}</span>
        </div>
        <div class="mt20">{{simpleDscr}}</div>
        <div class="mt10 op5">{{detail}}</div>
    </div>
    <div class="TVDcontnetRight">
        <!--市场名称-->
        <div class="TVDCRitem">
            <div class="TVDCRitemName">
                {{this.$t("marketName")}}
            </div>
            <div class="TVDCRitemData">{{blockchain.abbreviation}}-USD</div>
        </div>
        <div class="TVDCRitem">
            <div class="TVDCRitemName">
                <Tooltip max-width="250" placement="bottom">
                    <div slot="content">
                        <p class="f16">{{this.$t("tickSize")}}</p>
                        <p class="mt5">{{this.$t("tickIntroduce")}}</p>
                    </div>
                    <div>{{this.$t("tickSize")}}</div>
                </Tooltip>
            </div>
            <div class="TVDCRitemData">
                {{info.tickSize | numFormat(blockchain.priceAccuracy)}}
            </div>
        </div>
        <!--步骤数量-->
        <div class="TVDCRitem">
            <div class="TVDCRitemName">
                <Tooltip max-width="250" placement="bottom">
                    <div slot="content">
                        <p class="f16">{{this.$t("stepSize")}}</p>
                        <p class="mt5">{{this.$t("stepSizeIntroduce")}}</p>
                    </div>
                    <div>{{this.$t("stepSize")}}</div>
                </Tooltip>
            </div>
            <div class="TVDCRitemData">
                {{info.stepSize | toFixed(blockchain.amountAccuracy)}}<span class="tag">{{blockchain.abbreviation}}</span>
            </div>
        </div>
        <div class="TVDCRitem">
            <div class="TVDCRitemName">
                {{this.$t("minimumOrderSize")}}
            </div>
            <div class="TVDCRitemData">
                {{info.minOrderSize | toFixed(blockchain.amountAccuracy)}}<span class="tag">{{blockchain.abbreviation}}</span>
            </div>
        </div>
        <div class="TVDCRitem">
            <div class="TVDCRitemName">
                <Tooltip max-width="250" placement="bottom">
                    <div slot="content">
                        <p class="f16">{{this.$t("maximumLeverage")}}</p>
                        <p class="mt5">{{this.$t("maximumLeverageIntroduce")}}</p>
                    </div>
                    <div>{{this.$t("maximumLeverage")}}</div>
                </Tooltip>
            </div>
            <div class="TVDCRitemData">{{info.maxLeverage | toFixed(2)}}×</div>
        </div>
        <div class="TVDCRitem">
            <div class="TVDCRitemName">
                <Tooltip max-width="250" placement="bottom">
                    <div slot="content">
                        <p class="f16">{{this.$t("MMfraction")}}</p>
                        <p class="mt5">{{this.$t("MMfractionIntroduce")}}</p>
                    </div>
                    <div>{{this.$t("MMfraction")}}</div>
                </Tooltip>
            </div>
            <div class="TVDCRitemData">
                {{info.maintenanceMarginFraction | toFixed(4)}}
            </div>
        </div>
        <div class="TVDCRitem">
            <div class="TVDCRitemName">
                <Tooltip max-width="250" placement="bottom">
                    <div slot="content">
                        <p class="f16">{{this.$t("IMfraction")}}</p>
                        <p class="mt5">{{this.$t("IMfractionIntroduce")}}</p>
                    </div>
                    <div>{{this.$t("IMfraction")}}</div>
                </Tooltip>
            </div>
            <div class="TVDCRitemData">
                {{info.initialMarginFraction | toFixed(4)}}
            </div>
        </div>
        <div class="TVDCRitem">
            <div class="TVDCRitemName">
                {{this.$t("IIMfraction")}}
            </div>
            <div class="TVDCRitemData">
                {{info.incrementalInitialMarginFraction | toFixed(4)}}
            </div>
        </div>
        <!--增加仓位-->
        <div class="TVDCRitem">
            <div class="TVDCRitemName">
                {{this.$t("IPsize")}}
            </div>
            <div class="TVDCRitemData">
                {{info.incrementalPositionSize | toFixed(blockchain.amountAccuracy)}}<span class="tag">{{blockchain.abbreviation}}</span>
            </div>
        </div>
        <div class="TVDCRitem">
            <div class="TVDCRitemName">
                {{this.$t("BPsize")}}
            </div>
            <div class="TVDCRitemData">
                {{info.baselinePositionSize | toFixed(blockchain.amountAccuracy)}}<span class="tag">{{blockchain.abbreviation}}</span>
            </div>
        </div>
        <div class="TVDCRitem borderNone">
            <div class="TVDCRitemName">
                {{this.$t("MPsize")}}
            </div>
            <div class="TVDCRitemData">
                {{info.maxPositionSize | toFixed(blockchain.amountAccuracy)}}<span class="tag">{{blockchain.abbreviation}}</span>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import { getPairConfig } from "@/api/http"

export default {
    name: '',
    props: {
    },
    components: {

    },
    data () {
        return {
            info:{},//信息
            simpleDscr:'',//简介
            detail:'',//详情
        }
    },
    mounted() {
        
    },
    methods: {
        //获取详情
        pairPerpetual(){
            getPairConfig(this.blockchain.perpetual).then(pairConfig=>{
                this.info=pairConfig || {};
                this.setLang();
            });
        },
        //中英文详情
        setLang(){
            this.simpleDscr=this.$i18n.locale=='zh-CN'?this.info['zhSimpleDscr']:this.info['enSimpleDscr'];
            this.detail=this.$i18n.locale=='zh-CN'?this.info['zhDetail']:this.info['enDetail'];
        }
    },
    computed:{
        blockchain(){
            return this.$store.state.blockchain;
        },
    },
    watch:{
        //监听多语言切换
        '$i18n.locale':function(){
            this.setLang();
        },
        //切换交易对
        '$store.state.blockchain.perpetual':{
            handler:function(val,value){
                if(val){
                    this.info={};
                    this.simpleDscr='';
                    this.detail='';
                    this.pairPerpetual();
                }
            },
            deep: false,
            immediate: true
        }
    }
}
</script>

<style scoped lang='less'>
.TVDcontnetLeft{flex:1;.pr15;}
.TVDCLtitle{display:flex; align-items: center;
    >img{width:36px;height: 36px;}
    >span{.f20;.ml10; font-weight: 700;}
}
.TVDcontnetRight{flex:1;.ml15;.mb20;
    .TVDCRitem{.p5;.pl10;.pr10;width:100%;display: flex;justify-content: space-between;border-bottom:1px solid @borderColor;
    }
}
.TVDCRitemName{.op5;cursor: pointer;
    &:hover{opacity: 1;}
}
.tag{.ml5;.f12;}
.borderNone{border:none;}
</style>