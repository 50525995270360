<!--合约交易-货币类型，24小时变动一栏-->
<template>
<div class="tradeBar">
    <div class="tradeBarLeft">
        <div class="symbolPairChoose" @click="SPCselect">
            <div class="symbolPair">
                <img :src="blockchain.img">
                <span class="ml5">{{blockchain.abbreviation}}-USD</span>
            </div>
            <div class="symbolPairIcon"><span class="mr5">{{this.$t("markets")}}</span><Icon v-if="isSelect" type="md-arrow-dropup" /><Icon v-else type="md-arrow-dropdown" /></div>
        </div>
    </div>
    <!--导航栏右边-盘口数据-->
    <div class="tradeBarRight">
        <div class="tradePrice" :class="info.changePrice==0?'red':'green'">{{info.price | numFormat(blockchain.priceAccuracy)}}</div>
        <ul class="pairInfo">
            <li>
                <div>
                    <span>{{this.$t("signPrice")}}</span>
                    <p>{{markPrice | numFormat(2)}}</p>
                </div>
            </li>
            <li>
                <div>
                    <span>{{this.$t("oraclePrice")}}</span>
                    <p>{{chainlink | numFormat(2)}}</p>
                </div>
            </li>
            <li>
                <div>
                    <span>{{this.$t("change24h")}}</span>
                    <p v-if="info.spread===0">0</p>
                    <p v-else :class="{'red':info.change==0,'green':info.change==1}">
                        <Icon type="md-arrow-dropup" v-if="info.change==1" />
                        <Icon type="md-arrow-dropdown" v-else-if="info.change==0" />
                        {{info.spread | numFormat(2)}}({{info.percentage | toFixed(2)}}%)
                    </p>
                </div>
            </li>
            <li>
                <div>
                    <span>{{this.$t("openInterest")}}</span>
                    <p v-if="openPositionContract || openPositionContract===0">{{openPositionContract | format(2)}} <span class="tag">{{blockchain.abbreviation}}</span></p>
                    <p v-else>-</p>
                </div>
            </li>
            <li>
                <div>
                    <span>{{this.$t("funding8h")}}</span>
                    <p class="green" v-if="info.fundingRate==0 && info.fundingRate!=''">0%</p>
                    <p class="green" v-else>{{info.fundingRate | format(6,'%')}}</p>
                </div>
            </li>
            <li>
                <div>
                    <span>{{this.$t("volume24h")}}</span>
                    <p>{{info.totalVolume | numFormat(2,'')}} ETH</p>
                </div>
            </li>
            <li>
                <div>
                    <span>{{this.$t("amount24h")}}</span>
                    <p>{{info.totalAmount | numFormat(2)}}</p>
                </div>
            </li>
            <li>
                <div>
                    <span>{{this.$t("nextFunding")}}</span>
                    <p>{{countDown}}</p>
                </div>
            </li>
        </ul>
    </div>
</div>
</template>

<script>
import { timeDiff,dragMoveX } from "@/utils/base"
import axios from 'axios';
import dayjs from 'dayjs';
import config from "@/config";
import { sendMsg } from "@/api/http"
import ContractUtil from "@/utils/contractUtil"
let timeId=null;

let getDataTimeoutId=0
let getOpenPositionContractTimeoutId=0
let lastMarkPriceTimeoutId=0
let chainlinkInstanceTimeoutId=0

export default {
    model:{
      prop:'isSelect',
      event:'change'
    },
    props:{
        //是否显示货币类型弹窗
        isSelect:{
            type:Boolean,
            default:false
        },
    },
    data () {
        return {
            info:{},//实时数据，24小时交易数，24小时交易总额等等
            markPrice:'',//标记价格
            chainlink:'',//预言机，指数价格
            openPositionContract:'',//未平仓合约
            countDown:'00:00',//倒计时
        };
    },
    components: {
       
    },
    mounted () {
        setTimeout(() => {
            this.initSocket();
        }, 0);
        this.setCountDown();
        
        this.$eventBus.$on("deals",()=>{
            this.getOpenPositionContract();
        });
        //监听标记价格变动
        this.$eventBus.$on("changeMarkPrice",(e)=>{
            this.markPrice=e;
            this.$store.commit('setMarkPrice',this.markPrice);
        });
        dragMoveX('.tradeBarRight');
    },
    methods: {
        //连接socket
        initSocket(){
            //盘口信息
            this.$eventBus.$on("handicapInformation_msg",(res)=>{
                let info=res.data.filter(item=>{
                    return item.perpetual == this.blockchain.perpetual;
                })[0];
                this.info=info;
                if(info && info.price>0){
                    this.$store.commit('setNewPrice', info.price);
                }
            });
            sendMsg({cmd:'handicapInformation',data:this.blockchain.perpetual});
        },
        //获取标记价格，预言机，未平仓合约。
        async getData(perpetual){
            try{
                clearTimeout(getDataTimeoutId)
                //this.chainlinkInstance(perpetual);
                //this.lastMarkPrice(perpetual);
                //this.getOpenPositionContract();
                let contractReader = await ContractUtil.contractReader.at(this.blockchain.contractReader);
                let res=await contractReader.getMarket.call(this.blockchain.perpetual);
                if(this.blockchain.perpetual == perpetual){
                    let p=Number(1+('0').repeat(18));
                    //标记价格
                    let markPrice=Number(res.markPrice)/p; 
                    this.markPrice=markPrice;
                    this.$store.commit('setMarkPrice',this.markPrice);
                    this.$eventBus.$emit("changeMarkPrice",markPrice);//标记价格变动
                    //预言机
                    this.chainlink=Number(res.oraclePrice)/p;
                    //未平仓合约
                    this.openPositionContract=Number(res.totalSize)/p;
                }
            }catch(err){
                console.warn(err.message)
                getDataTimeoutId=setTimeout(()=>this.getData(perpetual||this.blockchain.perpetual),2000)
            }
        },
        // 未平仓合约
        async getOpenPositionContract(){
            try{
                clearTimeout(getOpenPositionContractTimeoutId)
                let perpetualInstance=await ContractUtil.perpetual.at(this.blockchain.perpetual);
                let openPositionContract =(await perpetualInstance.totalSize(3)).toString();
                let p=Number(1+('0').repeat(18));
                this.openPositionContract=Number(openPositionContract)/p;
            }catch(err){
                console.warn(err.message)
                getOpenPositionContractTimeoutId=setTimeout(()=>this.getOpenPositionContract(),2000)
            }
        },
        //获取标记价格
        async lastMarkPrice(perpetual){
            try{
                clearTimeout(lastMarkPriceTimeoutId)
                let instance = await ContractUtil.perpetual.at(this.blockchain.perpetual);
                await instance.markPrice.call().then((res) => {
                    if(this.blockchain.perpetual == perpetual){
                        let p=Number(1+('0').repeat(18));
                        let markPrice=Number(res.toString())/p;
                        this.markPrice=markPrice;
                        this.$store.commit('setMarkPrice',this.markPrice);
                        this.$eventBus.$emit("changeMarkPrice",markPrice);//标记价格变动
                    }
                });
            }catch(err){
                console.warn(err.message)
                lastMarkPriceTimeoutId=setTimeout(()=>this.lastMarkPrice(perpetual||this.blockchain.perpetual),2000)
            }
        },
        //获取预言机价格
        async chainlinkInstance(perpetual){
            try{
                setTimeout(chainlinkInstanceTimeoutId)
                let chainlinkInstance = await ContractUtil.amm.at(this.blockchain.fundmork);
                await chainlinkInstance.indexPrice().then((res) => {
                    if(this.blockchain.perpetual == perpetual){
                        let p=Number(1+('0').repeat(18));
                        let chainlink=Number(res[0].toString())/p;
                        this.chainlink=chainlink;
                    }
                });
            }catch(err){
                console.warn(err.message)
                chainlinkInstanceTimeoutId=setTimeout(()=>this.chainlinkInstance(perpetual||this.blockchain.perpetual),2000)
            }
        },
        //选择交易对
        SPCselect(){
            this.$emit("change",!this.isSelect);
        },
        //下一笔时间倒计时
        setCountDown(){
            setInterval(()=>{
                //8小时倒计时
                var date1=new Date();
                if (new Date().getHours()>= 0&&new Date().getHours()< 8){
                    var newyear=new Date(9999,31,8,0,0,0); 
                    newyear=new Date(9999,31,8,8,0,0)
                }
                if (new Date().getHours()>= 8&&new Date().getHours()< 16){
                    var newyear=new Date(9999,31,8,16,0,0); 
                    newyear=new Date(9999,31,8,16,0,0)
                }
                if (new Date().getHours()>= 16&&new Date().getHours()< 24){
                    var newyear=new Date(9999,31,8,8,0,0); 
                    newyear=new Date(9999,31,8,24,0,0)
                }
                var ms=newyear-date1;
                var day=Math.floor(ms/1000/3600/24);
                var h=Math.floor(ms%(3600*24*1000)/1000/3600);
                var m=Math.floor(ms%(3600*24*1000)/1000%3600/60);
                var s=Math.floor(ms%(3600*24*1000)/1000%3600%60);
                let countDown=(h>=10?h:'0'+h)+':'+(m>=10?m:'0'+m)+':'+(s>=10?s:'0'+s);
                this.countDown=countDown;
            },1000);
        },
        
    },
    computed:{
        blockchain(){
            return this.$store.state.blockchain;
        },
    },
    watch:{
        //切换交易对
        '$store.state.blockchain.perpetual':{
            handler:function(val,value){
                if(val){
                    this.info={};
                    this.openPositionContract='';
                    this.chainlink='';
                    this.markPrice='';
                    this.$store.commit('setMarkPrice',this.markPrice);
                    //未平仓合约
                    clearTimeout(timeId);
                    timeId=setTimeout(()=>{
                        this.getData(val);
                    },100);
                }
            },
            deep: false,
            immediate: true
        },
    },
    beforeDestroy() {
        clearTimeout(timeId);
        this.$eventBus.$off("deals");
        this.$eventBus.$off("handicapInformation_msg");
        this.$eventBus.$off("changeMarkPrice");
    },
};
</script>

<style lang="less" scoped>
.boderB{border: 1px solid @borderColor;}
.tradeBar{ display: flex;height:48px;height:48px;border-bottom:1px solid @borderColor;}
.tradeBarLeft{width:320px; position: relative;
    .ivu-select-selection{
        >input {background: #000;}
    }
    &:after{.afterLine;}
}
.symbolPairChoose{
    .flexBetween;height:48px;padding:0 15px; cursor:pointer;z-index:auto;// background: rgba(0, 0, 0, 0.87);
    &:hover{background:#000;}
}
.symbolPair{.flexCenter;
    img{height:24px;width:24px;border-radius: 1000px;}
}
.symbolPairIcon{
    span{opacity: 0.5;}
}
.tradeBarRight{flex:1;display:flex; overflow:hidden; overflow-x:auto;}
.tradePrice{height:48px;padding:0 20px;font-size:18px;.flexCenter;font-weight:bold;position: relative;
    &:after{.afterLine;}
}
.pairInfo{display: flex;
    li{padding:0 20px; position: relative;height:48px;.flexCenter;font-size:12px;
        >div{white-space: nowrap;
            >span{opacity: 0.5;}
        }
        &:after{.afterLine;}
    }
}
</style>