<!--合约交易-货币类型列表-->
<template>
 <div class="moneyTypePop" v-if="isSelect">
     <!--货币类型列表-->
    <div class="SPCcomplex">
        <div class="symbolPairChoose boderB" v-for="(item,index) in dataList" :key="index" @click="select(item)" :class="{'active':blockchain.perpetual==item.perpetual}">
            <div class="symbolPair">
                <img :src="item.img">
                <span class="ml5">{{item.fullName}}</span>
                <span class="tag f12 ml5">{{item.abbreviation}}</span>
            </div>
            <div class="symbolPairIcon">
                <span class="mr5">{{item.price | numFormat(2)}}</span>
                <!--change。0为下降，1为上升，2为未改变。-->
                <span v-if="item.change==1" class="green"><Icon type="md-arrow-dropup" />{{item.percentage*100 | toFixed(2)}}%</span>
                <span v-else-if="item.change===0" class="red"><Icon type="md-arrow-dropdown" />{{item.percentage*100 | toFixed(2)}}%</span>
            </div>
        </div>
    </div>
    <div style="flex:1;backdrop-filter:blur(6px);" @click="close();"></div>
 </div>
</template>

<script>
import config from "@/config";
import { sendMsg } from "@/api/http"
export default {
    name: '',
    model:{
        prop:'isSelect',
        event:'change'
        },
    props: {
        //是否显示弹窗，选择弹窗列表的内容
        isSelect:{
            type:Boolean,
            default:false
        }
    },
    components: {

    },
    data () {
        return {
            dataList:[],//交易对列表
        }
    },
    mounted() {
        setTimeout(() => {
            this.initSocket();
        }, 0);
    },
    methods: {
        //选择交易对
        select(item){
            if(item.perpetual==this.blockchain.perpetual){
                return;
            }
            sessionStorage.setItem("currencyActive",item.perpetual);
            let c=item;
            this.$store.commit("setCurrencyConfig",{
                perpetual:c.perpetual,
                broker:c.broker,
                fundmork:c.fundmork,
                img:c.img,
                fullName:c.fullName,
                abbreviation:c.abbreviation,
                amountAccuracy:c.amountAccuracy,
                priceAccuracy:c.priceAccuracy,
            });
            sendMsg({cmd:'changePerpetual',data:item.perpetual});
            this.close();
        },
        //连接socket
        initSocket(){
            //交易对消息
            this.$eventBus.$on("tradePair_msg",(res)=>{
                let list=res.data || [];
                this.dataList=list;
            });
            sendMsg({cmd:'tradePair'});
        },
        //选择交易对
        close(){
            this.$emit("change",false);
        },
    },
    computed:{
        blockchain(){
            return this.$store.state.blockchain;
        },
    },
    beforeDestroy() {
        this.$eventBus.$off("tradePair_msg");
    },
}
</script>

<style scoped lang='less'>
.boderB{border: 1px solid @borderColor;}
.SPCcomplex{ 
    // background: #fff;
    background: @theme-color;
    border-right: 1px solid @border-color;
    overflow: auto;
    // position: relative;
    z-index:3;width:320px;min-width:320px;
}
.symbolPairChoose{
    .flexBetween;height:48px;padding:0 15px; cursor:pointer;z-index:auto;background:@theme-color;
    &:hover{background:@active-color;}
}
.symbolPair{.flexCenter;
    img{height:24px;width:24px;border-radius: 1000px;}
}
.symbolPairIcon{
    display:flex;
    flex-direction:column;
    align-items: flex-end;
    // span{opacity: 0.5;}
}
.active{
    cursor:default !important;
    background:@active-color !important;
}
.moneyTypePop{
    position: absolute;
    width:100%;
    height:100%;
    display:flex;
    z-index: 99;
}
</style>