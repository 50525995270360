<!--当前持仓-->
<template>
 <div class="holdInfo">
     <div class="holdInfoLeft">
        <div class="holdBox">
            {{this.$t("none")}}
        </div>
        <div class="holdInfoDown">
            <div class="flexBetween">
                <dl>
                    <dt>
                        <Tooltip max-width="250" placement="right">
                            <div slot="content">
                                <p class="f16">{{this.$t("leverage")}}</p>
                                <p class="mt5">{{this.$t("leverageIntroduce")}}</p>
                            </div>
                            <div>{{this.$t("leverage")}}</div>
                        </Tooltip>
                    </dt>
                    <dd>4x</dd>
                </dl>
                <dl>
                    <dt>
                        <Tooltip max-width="250" placement="right">
                            <div slot="content">
                                <p class="f16">{{this.$t("strongFlatPrice")}}</p>
                                <p class="mt5">{{this.$t("SFPintroduce")}}</p>
                            </div>
                            <div>{{this.$t("strongFlatPrice")}}</div>
                        </Tooltip>
                    </dt>
                    <dd>64723.2</dd>
                </dl>
            </div>
            <div class="flexBetween">
                <dl>
                    <dt>
                        <Tooltip max-width="250" placement="right">
                            <div slot="content">
                                <p class="f16">{{this.$t("UPAlossTitle")}}</p>
                                <p class="mt5">{{this.$t("UPAlossIntroduce")}}</p>
                            </div>
                            <div>{{this.$t("UPAloss")}}</div>
                        </Tooltip>
                    </dt>
                    <dd class="red">-87.1827(-2.8%)</dd>
                </dl>
                <dl>
                    <dt>
                        <Tooltip max-width="250" placement="right">
                            <div slot="content">
                                <p class="f16">{{this.$t("margin")}}</p>
                                <p class="mt5">{{this.$t("RPAlossIntroduce")}}</p>
                            </div>
                            <div>{{this.$t("margin")}}</div>
                        </Tooltip>
                    </dt>
                    <dd>64723.2</dd>
                </dl>
            </div>
        </div>
    </div>
    <div class="holdInfoRight">
        <ul>
            <li><span>{{this.$t("openPrice")}}</span><span>-</span></li>    
            <li><span>{{this.$t("markPrice")}}</span><span>-</span></li>    
            <li><span>{{this.$t("available")}}</span><span>-</span></li>    
        </ul>
    </div>
 </div>
</template>

<script>
 export default {
   name: '',
   props: {
       //用户持仓数据
       data:{
           type:Object,
           required:true
       }
   },
   components: {

   },
   data () {
     return {

     }
   },
   methods: {
       
   },
   mounted() {
        
   },
 }
</script>

<style lang='less'>

.holdInfo{
    margin:20px;.flexBetween;align-items:flex-start;
    >div{width:50%;flex:1;padding:20px;box-sizing:border-box;}
    .holdBox{background:@graybg;border-radius:@border-radius-base ;min-height:60px;.flexCenter;}
}
.holdInfoDown{
    dl{.mt15;
        dt{.op5;
            &:hover{opacity: 1;cursor: pointer;}
        }
        dd{}
    }
}
.holdInfoRight{
    li{.flexBetween;.mb10;}
}
</style>