<!--连接钱包和账户-->
<template>
<div>
    <!-- 账户信息-->
    <ul class="accountInfo" style="position:relative;">
       <li>
           <span>{{$t('account')}}</span>
           <span>
               <Button type="primary" shape="circle" style="background:#333;border-color:#333;" @click="showDialog(1)">{{$t('withdraw')}}</Button>
               <Button class="ml10" type="info" shape="circle" @click="showDialog(2)">{{$t('deposit')}}</Button>
           </span>
        </li>
        <li>
           <span>{{$t('walletBalance')}}</span>
           <span>{{walletBalance | numFormat(2)}}</span>
        </li>
        <li>
           <span>{{$t('marginBalance')}}</span>
           <span>{{marginBalance | numFormat(2)}}</span>
        </li>
        <li>
           <span>{{$t('freeMargin')}}</span>
           <span>{{availableMargin | numFormat(2)}}</span>
        </li>
        <li>
           <span>{{$t('marginUtilization')}}</span>
           <span>{{marginBalanceRate | format(2,'%')}}</span>
        </li>
        <!-- <li>
           <span>维持保证金率</span>
           <span>0.00%</span>
        </li> -->
        <loading-layer v-model="isLoadingLayer"></loading-layer>
    </ul>
    <!--存款-->
    <Modal v-model="isDepositPop" width="400px" :mask-closable="false">
        <template slot="header">
            <Icon type="information-circled"></Icon>
            <span v-if='controlType==1'>{{$t('withdraw')}}</span>
            <span v-else-if='controlType==2'>{{$t('deposit')}}</span>
        </template>
        <!--数量 ETH-->
        <div class="actionWpTitle">{{$t("assets")}}</div>
        <div class="mt10">
            <Select v-model="form.currency" class="mb10" size="large" style="width:100%;">
                <Option v-for="(item,index) in currency" :key="index" :value="index">{{item.type}}</Option>
            </Select>
        </div>
        <div class="mt15 actionWpTitle">{{$t("amount")}}</div>
        <div class="mt10">
            <ex-input v-model="form.amount" @input="e=>form.amount=prohibitNegative(e,3,20)" max-length="20" placeholder="0.00" @mousewheel.native.prevent />
        </div>
        <div class="amountBox">
            <div class="flexBetween">
                <div class="actionWpTitle">{{$t('freeMargin')}}<span class="tag ml5">USDT</span></div>
                <em>{{(availableMargin) | numFormat(2)}}</em>
            </div>
        </div>
        <!-- <div class="infobox">
            <div class="flexBetween">
                <span>Equity</span>
                <em>$0.00</em>
            </div>
            <div class="flexBetween">
                <span>购买力</span>
                <em>$0.00</em>
            </div>
        </div> -->
        <div v-if="controlType==2 && !checkAllowance(form.amount)" class="infobox">
            <!-- <div class="fontColor center" style="margin-bottom:10px;">{{$t('approveUSDC')}}</div> -->
            <Button type="info" size="large" long @click="approve()">{{$t('enable')}} USDT</Button>
        </div>
        <template slot="footer">
            <Button type="info" v-if="controlType==2 && checkAllowance(form.amount) || controlType==1" size="large" long @click="confirm()" :disabled="isForm">
                <span v-show='controlType==1'>{{$t('confirmWithdraw')}}</span>
                <span v-show='controlType==2'>{{$t('confirmDeposit')}}</span>
            </Button>
            <span v-else></span>
        </template>
    </Modal>
    
</div>
</template>

<script>
import exInput from "@/components/common/exInput/index.vue"

import WalletUtil from "@/utils/walletUtil"
import {getCurrencyList} from "@/api/http"
import ContractUtil from "@/utils/contractUtil"
const { toWad2  } = require('@/utils/signFn/constants.js');//常量模块
let timeId=null;
let loading=false;
let initTimeoutId=0
let getDataTimeoutId=0
let getWalletTimeoutId=0
let getAllowanceTimeoutId=0

export default {
    name: '',
    props: {
    },
    components: {
        exInput
    },
    data () {
        return {
            isLoadingLayer:true,
            marginBalance:'',//保证金余额
            availableMargin:'',//可用保证金
            isDepositPop:false,//存款弹窗
            currency:[],//所有币种
            allowance:0,//是否授权usdc
            controlType:0,//1：取款，2：存款
            form:{
                currency:0,//币种
                amount:'',//数量
            },
            frozen:0,//冻结金额
            walletBalance:0,//钱包余额
        }
    },
    mounted() {
        getCurrencyList().then(currencyList=>{
            this.currency=currencyList;
        });
        this.getFrozen();
        this.init();
        this.getNowData();
        this.$eventBus.$on("changeBalance",()=>{
            this.getData(this.blockchain.perpetual);
        });
    },
    methods: {
        //获取冻结金额
        getFrozen(){
            this.$eventBus.$on("frozen_msg",(res)=>{
                console.log('冻结金额：',res);
                this.frozen=res.data;
            });
        },
        //当前账户
        getNowData(){
            // const equipment = new ContractUtil.web3.eth.Contract(ContractUtil.perpetual.abi,this.blockchain.perpetual);
            // //取款
            // equipment.events.Withdraw({filter:{trader:this.addr}},(error,events)=>{
            //     // console.log(events,'events')
            //     this.getData(this.blockchain.perpetual);
            // })
            // //存款
            // equipment.events.Deposit({filter:{trader:this.addr}},(error,events)=>{
            //     // console.log(events,'events')
            //     this.getData(this.blockchain.perpetual);
            // })
            // //清算
            // equipment.events.Liquidate({filter:{trader:this.addr}},(error,events)=>{
            //     // console.log(events,'events')
            //     this.getData(this.blockchain.perpetual);
            // })
            
        },
        setAllowance(token,addr,spender, allowance){
            this.allowance=allowance
            localStorage.setItem(token+'_'+addr+'_'+spender, allowance)
        },
        //显示弹窗
        showDialog(controlType){
            this.controlType=controlType;
            this.form.amount='';
            this.isDepositPop=true;
        },
        //初始化
        init(){
            clearTimeout(initTimeoutId)
            if(!this.addr || !this.isInitWallet){
                initTimeoutId=setTimeout(()=>this.init(),2000)
                return
            }
            if(this.blockchain.perpetual){
                WalletUtil.perpetual.at(this.blockchain.perpetual)
            }
            if(this.blockchain.usdc){
                WalletUtil.usdt.at(this.blockchain.usdc)
            }
            this.getAllowance(true)
            this.getData(this.blockchain.perpetual);
            this.getWallet();
        },
        //授权
        approve(){
            const usdc=this.blockchain.usdc
            const perpetual=this.blockchain.perpetual
            const addr=this.addr

            WalletUtil.usdt.at(usdc).then((instance) => {
                return instance.approve(perpetual,'9999999999999999999999999999999999999999', {from:addr})
                .then(() =>{
                    return this.getAllowance(true)
                });
            }).catch(err=>{
                console.warn(err.message)
            });
        },
        //获取是否授权
        getAllowance(ignoreCache){
            clearTimeout(getAllowanceTimeoutId)
            if(this.allowance && !ignoreCache){
                return
            }
            const usdc=this.blockchain.usdc
            const perpetual=this.blockchain.perpetual
            const addr=this.addr
            const cachedAllowance=localStorage.getItem(usdc+'_'+addr+'_'+perpetual)
            if(cachedAllowance && !ignoreCache){
                this.allowance=cacheAllowance
                return
            }
            if(!usdc || !perpetual || !addr){
                getAllowanceTimeoutId=setTimeout(()=>this.getAllowance(ignoreCache),2000)
            }

            ContractUtil.usdt.at(usdc).then((instance) => {
                return instance.allowance(addr,perpetual).then(res=>{
                    this.setAllowance(usdc,addr,perpetual, res.toString())                    
                })
            }).catch(err=>{
                console.warn(err.message)
                getAllowanceTimeoutId=setTimeout(()=>this.getAllowance(ignoreCache),2000)
            })
        },
        checkAllowance(amount){
            return Number(this.allowance||0) >= Number(toWad2(amount||0))
        },
        checkBalance(amount){
            return Number(this.walletBalance||0) >= Number(amount||0)
        },

        //获取账户余额
        async getData(perpetual){
            try{
                clearTimeout(getDataTimeoutId)
                let res=await ContractUtil.getTraderPosition(this.blockchain.contractReader,this.blockchain.perpetual,this.addr)
                if(this.blockchain.perpetual!=perpetual){
                    return;
                }
                let availableMargin=res[5];
                let marginBalance=res[0];
                let p=Number(1+('0').repeat(18));
                this.availableMargin=res?.walletMargin?.freeMargin ?? Number((Number(availableMargin)/p).toFixed(2));
                this.marginBalance=Number((Number(marginBalance)/p).toFixed(2));
                this.$store.commit('setAvailableMargin',this.availableMargin);
                this.$store.commit('setMarginBalance',this.marginBalance);
                this.isLoadingLayer=false;
            }catch(err){
                getDataTimeoutId=setTimeout(()=>this.getData(perpetual||this.blockchain.perpetual),3000)
                console.warn(err.message)
            }
        },
        //获取钱包余额
        async getWallet(){
            clearTimeout(getWalletTimeoutId)
            ContractUtil.usdt.at(this.blockchain.usdc).then(async (instance) => {
                return instance.balanceOf(this.addr).then(res=>{
                    let p=Number(1+('0').repeat(18));
                    this.walletBalance=Number((Number(res.toString())/p).toFixed(2));
                })
            }).catch(err=>{
                getWalletTimeoutId=setTimeout(()=>this.getWallet(),3000)
                console.warn(err.message)
            })
        },
        //确认
        confirm(){
            let amount=this.form.amount;
            this.form.amount='';
            let abbreviation=this.blockchain.abbreviation;
            //1：取款，2：存款
            if(this.controlType==1){
                this.withdraw(amount,abbreviation);
            }else if(this.controlType==2){
                this.deposit(amount,abbreviation);
            }
        },
        //取款
        async withdraw(amount,abbreviation){
            if(amount > this.availableMargin){
                this.$Message.error(this.$t('notAvailableMargin'));
                return false;
            }
            WalletUtil.perpetual.at(this.blockchain.perpetual).then(async (instance) => {
                await instance.withdraw(toWad2(amount), {from: this.addr})
                .then((res) => {
                    this.isDepositPop=false;
                    this.$Notice.success({
                        title: `${abbreviation}-USD ${this.$t('withdrawSuccess')}!`,
                        duration:4.5,
                    });
                }).catch(err=>{
                    this.$Notice.error({
                        title: `${abbreviation}-USD ${this.$t('withdrawalFailed')}`,
                        duration:4.5,
                    });
                    throw err;
                });
            }).catch(err=>{
                console.warn(err.message)
            });;
        },
        //存款
        async deposit(amount,abbreviation){
            if(!this.checkBalance(amount)){
                this.$Message.error(this.$t('Insufficient_available_wallet_balance'));
                return false;
            }

            WalletUtil.perpetual.at(this.blockchain.perpetual).then((instance) => {
                return instance.deposit(toWad2(amount), {from: this.addr,})
                .then((res) => {
                    this.isDepositPop=false;
                    this.getData(this.blockchain.perpetual);
                    this.getWallet();
                    this.$Notice.success({
                        title:`${abbreviation}-USD ${this.$t('depositSuccess')}`,
                        duration:4.5,
                    });
                }).catch(err=>{
                    this.$Notice.error({
                        title: `${abbreviation}-USD ${this.$t('depositFailed')}`,
                        duration:4.5,
                    });
                    throw err
                });
            }).catch(err=>{
                console.warn(err.message)
            }).finally(()=>this.getAllowance(true));
        },
    },
    computed:{
        //是否完成表单
        isForm(){
            return !this.form.amount || this.form.amount==0;
        },
        //钱包地址
        addr(){
            return this.$store.state.app.wallet.address;
        },
        blockchain(){
            return this.$store.state.blockchain;
        },
        //保证金使用率
        marginBalanceRate(){
            return  (this.marginBalance-this.availableMargin)/this.marginBalance*100;
        },
        //是否初始化钱包
        isInitWallet() {
            return this.$store.state.app.wallet.isInit;
        },
    },
    watch:{
        //监听多语言切换
        lang:function(){

        },
        //是否登录钱包
        'isInitWallet':{
            handler:function(val,value){
                if(val){
                    clearInterval(timeId);
                    timeId=setInterval(()=>{
                        this.getData(this.blockchain.perpetual);
                        this.getWallet();
                    },6000);
                    setTimeout(() => {
                        this.getAllowance(true)
                       this.getData(this.blockchain.perpetual); 
                       this.getWallet();
                    },0);//执行顺序问题
                }else{
                    clearInterval(timeId);
                }
            },
            deep: false,
            immediate: true
        },
        //切换交易对
        '$store.state.blockchain.perpetual':{
            handler:function(val,value){
                if(val){
                    if(this.isInitWallet){
                        this.isLoadingLayer=true;
                        this.getAllowance(true)
                        this.getData(this.blockchain.perpetual);
                        this.getWallet();
                        // this.getNowData();
                    }
                }
            },
            deep: false,
            immediate: false
        }
    },
    beforeDestroy(){
        clearInterval(timeId);
        this.$eventBus.$off("changeBalance");
    },
}
</script>

<style scoped lang='less'>
.accountInfo{height:170px;border-bottom:1px solid @borderColor;padding:20px;overflow-y:auto;}
.accountInfo>li{.flexBetween;margin:4px 0;}
.infobox {
    background: #000;
    border-radius: 4px;
    padding: 13px 15px;
    margin-top: 20px;
    color:#666;
    >div{margin-top:10px;}
}
.amountBox{
    background: #181818a8;
    border-radius: 4px;
    padding: 13px 15px;
    color:#666;
}
.infobox>div:first-child {
    margin: 0;
}
.infobox em{
    color:@font-color;
}
.actionWpTitle{
    color:@color;
}
</style>