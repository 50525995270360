<template>
<div class="pwh100" style="position:relative;    overflow: auto;">
    <div class="dataPannel" v-if="isShow">
        <TradeBar v-model="isSelect"></TradeBar><!--货币类型，24小时变动一栏-->
        <div class="tradeMain">
            <TradeMainLeft style="overflow-y:auto;"></TradeMainLeft>
            <TradeMainRight style="overflow-y:auto;"></TradeMainRight>
            <money-type-pop v-model="isSelect" style="position:absolute;"></money-type-pop>
        </div>
        <div class="footer">
            <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect y="9.667" width="1.67" height="2.5" :fill="isOnline?'#24AE8F':'#ed4014'"></rect>
                <rect x="4.44446" y="5.667" width="1.66667" height="6.67" :fill="isOnline?'#24AE8F':'#ed4014'"></rect>
                <rect x="8.88892" y="3" width="1.66667" height="10.83" :fill="isOnline?'#24AE8F':'#ed4014'"></rect>
                <rect x="13.3333" width="1.66667" height="15" :fill="isOnline?'#24AE8F':'#ed4014'"></rect>
            </svg>
            <span class="ml5">{{isOnline?$t('stableConnection'):$t('instableConnection')}}</span>
        </div>
    </div>
    <loading-layer :isShow="!isShow"></loading-layer>
</div>
</template>
<script>

import TradeMainLeft from "./components/tradeMainLeft.vue"
import TradeMainRight from "./components/tradeMainRight.vue"
import TradeBar from "./components/tradeBar.vue"
import MoneyTypePop from "./components/moneyTypePop.vue"
import SocketUtil from '@/socket/SocketUtil'
import { sendMsg,sendTradePairMsg ,userFee} from "@/api/http"

export default {
    data () {
        return {
            isSelect:false,//是否显示货币类型弹窗
            isShow:false,//是否显示合约交易界面
            isOnline:true,//网络状态
        };
    },
    components: {
        TradeBar,TradeMainLeft,TradeMainRight,MoneyTypePop
    },
    mounted () {
        this.addNetworkEvent();
        //交易对列表
        this.$eventBus.$on("tradePair_msg",(res)=>{
            this.$eventBus.$off("tradePair_msg");
            let list=res.data || [];
            this.initCurrencyConfig(list);
        });

        //配置消息。交易费率
        this.$eventBus.$on("config_msg",(res)=>{
            let { realTakerFeeRate,realMakerFeeRate,takerFeeRate,gasFee }=res.data;
            this.$store.commit('setFeeRate',{realTakerFeeRate,realMakerFeeRate,takerFeeRate,gasFee});
            if(this.$store.state.app.wallet.address){
                userFee(this.$store.state.app.wallet.address).catch(err=>{});
            }
        });

        setTimeout(() => {
            this.initSocket();
        }, 50);
        this.setCurrencyConfig(this.$store.state.blockchain)
    },
    methods: {
        //监听网络状态
        addNetworkEvent(){
            window.addEventListener("online",()=>{
                this.isOnline=true;
                window.removeEventListener('online', ()=>{})
            })
            window.addEventListener("offline",()=>{
                this.isOnline=false;
                window.removeEventListener('offline', ()=>{})
            })
        },
        //连接socket
        initSocket(){
            //sendMsg({cmd:'tradePair'});//交易对消息
            sendTradePairMsg()
        },
        //初始化交易对配置文件。默认选中的交易对 list：交易对列表
        initCurrencyConfig(list){
            let tradePair=list;//JSON.parse(sessionStorage.getItem('tradePair')) || [];
            let currencyActive=sessionStorage.getItem("currencyActive");
            let c=null;
            if(currencyActive && tradePair.some((item)=>item.perpetual==currencyActive)){
                c=tradePair.filter(item=>item.perpetual==currencyActive)[0];
            }else{
                c=tradePair[0];
            }
            if(!c){
                return;
            }
            const currencyConfig={
                perpetual:c.perpetual,
                broker:c.broker,
                fundmork:c.fundmork,
                img:c.img,
                fullName:c.fullName,
                abbreviation:c.abbreviation,
                amountAccuracy:c.amountAccuracy,
                priceAccuracy:c.priceAccuracy,
            }
            this.setCurrencyConfig(currencyConfig)
        },
        setCurrencyConfig(currencyConfig){
            sessionStorage.setItem("currencyActive",currencyConfig.perpetual);
            this.$store.commit("setCurrencyConfig",currencyConfig);
            this.isShow=true;
            sendMsg({cmd:'config',data:this.$store.getters['getPerpetual']});//获取资金费率
        },
    },
    beforeDestroy() {
        this.$eventBus.$off("tradePair_msg");
        this.$eventBus.$off("config_msg"); //不能卸载
        sendMsg({cmd:'clearPerpetual'});//清除后端缓存的perpetual
        window.removeEventListener('offline', ()=>{})
        window.removeEventListener('online', ()=>{})
    },
};
</script>
<style lang="less" scoped >
.dataPannel{
    display: flex;
    flex-direction: column;
    width:100%;
    height:100%;
    overflow-y:auto;
}
.tradeMain{
    flex:1;
    display:flex; 
    overflow-y:auto;
    box-sizing: border-box;
    position: relative;
}
.footer{
    font-size:12px;
    padding:2px 5px;
}
</style>