/**
 * 合约交易
 */
/**
 * 字段说明
 * placement:气泡框出现位置
 */
 //当前持仓
export function getPositionTitleList(){
    return [
        {title:`${_vm.$t('contract')}`},
        {title:`${_vm.$t('direction')}`},
        {title:`${_vm.$t('amount')}`,
            tag:true,
        },
        {title:_vm.$t('margin')},
        {title:_vm.$t('marginRate')},
        {title:_vm.$t('positionPrice')},
        {title:_vm.$t('signPrice')},
        {title:_vm.$t('strongPrice')},
        // {title:_vm.$t('serviceCharge')},
        {title:_vm.$t('profitLoss')},
        {title:_vm.$t('operation')},
    ];
}
//订单
export function getOrdersTitleList(){
    return [
        {title:`${_vm.$t("systemStatus")}`},
        {title:`${_vm.$t("side")}`},
        {title:`${_vm.$t("amountFilled")}`,tag:true,},
        {title:`${_vm.$t("price")}`},
        // {title:`${_vm.$t("stopPrice")}`,
        //     message:{title:`${_vm.$t("stopPriceTitle")}`,content:`${_vm.$t("stopPriceIntroduce")}`,placement:'bottom-end'}
        // },
        {
            title:`${_vm.$t("goodTil")}`,
            message:{title:`${_vm.$t("goodTil")}`,content:`${_vm.$t("goodTilIntroduce")}`,placement:'bottom-end'}
        },
        {title:``},
    ];
}
//全部成交
export function getFillsTitleList(){
    return [
        {title:`${_vm.$t("time")}`},
        {title:`${_vm.$t("type")}`},
        {title:`${_vm.$t("side")}`},
        {title:`${_vm.$t("amount")}`,
            tag:true,
        },
        {title:`${_vm.$t("price")}`},
        {title:`${_vm.$t("totalFee")}`,},
        {
            title:`${_vm.$t("liquidity")}`,
            // message:{title:`${_vm.$t("liquidityTitle")}`,content:`${_vm.$t("liquidityIntroduce")}`,placement:'bottom-end'}
        },
        {title:`${_vm.$t('status')}`}
    ];
}
