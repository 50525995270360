//导出k线图配置
export function TradingViewConfig1() {
    return {
        theme: 'Dark',
        fullscreen: false, //屏幕尺寸
        autosize: true, //自动适应容器大小
        symbol: null, //函数赋值
        // symbol:this_vue.currency1 + ":"+ this_vue.currency2,
        container_id: "chart_container",
        datafeed: null, //函数赋值
        // datafeed: this_vue.feed,
        library_path: "/custom_scripts/chart_main/", //包文件
        custom_css_url: 'chart.css',
        locale: `${_vm.$t("locale")}`, //语言，en：英文，zh：中文
        timezone: 'UTC', //todo: ustawianie timezone'a po strefie usera
        charts_storage_api_version: "1.1",
        client_id: 'tradingview.com',
        user_id: 'public_user_id',
        debug: true,
        //加载中样式
        // loading_screen:{ backgroundColor: "#00ff00",foregroundColor: "#000000", }, //todo:do it
        interval: '60',
        // preset: "mobile",//是否移动端，移动端就没有什么控件
        // timeframe:'',//todo: na koncu
        toolbar_bg: "#212121", //"#20334d",
        // saved_data: this.savedData,
        allow_symbol_change: true,
        time_frames: [
            { text: "1y", resolution: "1W" },
            { text: "6m", resolution: "3D" },
            { text: "3m", resolution: "1D" },
            { text: "1m", resolution: "1D" },
            { text: "1w", resolution: "30" },
            { text: "3d", resolution: "30" },
            { text: "1d", resolution: "30" },
            { text: "6h", resolution: "15" },
            { text: "1h", resolution: "1" }
        ],
        //图纸访问
        drawings_access: {
            type: 'black',
            tools: [{ name: "Regression Trend" }] //todo: moje
                // tools: [{name: "Trend Line", grayed: true}, {name: "Trend Angle", grayed: true}] //todo: bb
        },
        //禁用的功能
        disabled_features: [
            "header_symbol_search",
            "header_interval_dialog_button",
            "show_interval_dialog_on_key_press",
            "symbol_search_hot_key",
            "study_dialog_search_control",
            "display_market_status",
            "header_compare",
            "edit_buttons_in_legend",
            "symbol_info",
            "border_around_the_chart",
            "main_series_scale_menu",
            "star_some_intervals_by_default",
            "datasource_copypaste",
            "right_bar_stays_on_scroll",
            "context_menus",
            "go_to_date",
            "compare_symbol",
            "border_around_the_chart",
            "timezone_menu",
            //左右移动，缩放，重置
            // "header_resolutions",//todo: przetestowac
            // "control_bar",//todo: przetestowac
            // "edit_buttons_in_legend",//todo: przetestowac
            "remove_library_container_border",
            "adaptive_logo"
        ],
        //开启的功能
        enabled_features: [
            "dont_show_boolean_study_arguments",
            "use_localstorage_for_settings",
            "remove_library_container_border",
            "save_chart_properties_to_local_storage",
            "side_toolbar_in_fullscreen_mode", //全屏模式启动绘图功能
            "hide_last_na_study_output",
            // "hide_left_toolbar_by_default",//当用第一次进入隐藏左部工具栏
            "constraint_dialogs_movement", //todo: nie do końca jestem pewien
            // "move_logo_to_main_pane",//logo位置
            "chart_property_page_background"
        ],
        //覆盖
        studies_overrides: {
            "volume.volume.color.0": "#fe4761",
            "volume.volume.color.1": "#3fcfb4",
            "volume.volume.transparency": 75,
        },
        //覆盖
        overrides: {
            "paneProperties.background": "#ff0000",
            // "indicator_name.input_name":"volume",
            "symbolWatermarkProperties.color": "rgba(0,0,0, 0)",
            // "paneProperties.background": "#20334d",//"#20334d",
            //网格
            "paneProperties.vertGridProperties.color": "#ff00000",
            "paneProperties.horzGridProperties.color": "#344568",
            "paneProperties.crossHairProperties.color": "#58637a",
            "paneProperties.crossHairProperties.style": 2,
            "mainSeriesProperties.style": 9,
            "mainSeriesProperties.showCountdown": false,
            "scalesProperties.showSeriesLastValue": true,
            "mainSeriesProperties.visible": false,
            "mainSeriesProperties.showPriceLine": false,
            "mainSeriesProperties.priceLineWidth": 1,
            "mainSeriesProperties.lockScale": false,
            "mainSeriesProperties.minTick": "default",
            "mainSeriesProperties.extendedHours": false,
            "volumePaneSize": "tiny",
            editorFontsList: ["Lato", "Arial", "Verdana", "Courier New", "Times New Roman"],
            "paneProperties.topMargin": 5,
            "paneProperties.bottomMargin": 5,
            "paneProperties.leftAxisProperties.autoScale": true,
            "paneProperties.leftAxisProperties.autoScaleDisabled": false,
            "paneProperties.leftAxisProperties.percentage": false,
            "paneProperties.leftAxisProperties.percentageDisabled": false,
            "paneProperties.leftAxisProperties.log": false,
            "paneProperties.leftAxisProperties.logDisabled": false,
            "paneProperties.leftAxisProperties.alignLabels": true,
            // "paneProperties.legendProperties.showStudyArguments": true,
            "paneProperties.legendProperties.showStudyTitles": true,
            "paneProperties.legendProperties.showStudyValues": true,
            "paneProperties.legendProperties.showSeriesTitle": true,
            "paneProperties.legendProperties.showSeriesOHLC": true,
            "scalesProperties.showLeftScale": false,
            "scalesProperties.showRightScale": true,
            "scalesProperties.backgroundColor": "#212121", //"#20334d",
            // 坐标轴和刻度标签颜色
            "scalesProperties.lineColor": "#46587b",
            "scalesProperties.textColor": "#8f98ad",
            "scalesProperties.scaleSeriesOnly": false,
            "mainSeriesProperties.priceAxisProperties.autoScale": true,
            "mainSeriesProperties.priceAxisProperties.autoScaleDisabled": false,
            "mainSeriesProperties.priceAxisProperties.percentage": false,
            "mainSeriesProperties.priceAxisProperties.percentageDisabled": false,
            "mainSeriesProperties.priceAxisProperties.log": false,
            "mainSeriesProperties.priceAxisProperties.logDisabled": false,
            "mainSeriesProperties.candleStyle.upColor": "#3fcfb4",
            "mainSeriesProperties.candleStyle.downColor": "#fe4761",
            "mainSeriesProperties.candleStyle.drawWick": true, // 烛心
            "mainSeriesProperties.candleStyle.drawBorder": true, //边框
            "mainSeriesProperties.candleStyle.borderColor": "#3fcfb4",
            "mainSeriesProperties.candleStyle.borderUpColor": "#3fcfb4", //边框颜色
            "mainSeriesProperties.candleStyle.borderDownColor": "#fe4761", //边框颜色
            "mainSeriesProperties.candleStyle.wickColor": "#737375",
            "mainSeriesProperties.candleStyle.wickUpColor": "#3fcfb4", // 烛心颜色
            "mainSeriesProperties.candleStyle.wickDownColor": "#fe4761",
            "mainSeriesProperties.candleStyle.barColorsOnPrevClose": false,
            "mainSeriesProperties.hollowCandleStyle.upColor": "#3fcfb4",
            "mainSeriesProperties.hollowCandleStyle.downColor": "#fe4761",
            "mainSeriesProperties.hollowCandleStyle.drawWick": true,
            "mainSeriesProperties.hollowCandleStyle.drawBorder": true,
            "mainSeriesProperties.hollowCandleStyle.borderColor": "#3fcfb4",
            "mainSeriesProperties.hollowCandleStyle.borderUpColor": "#3fcfb4",
            "mainSeriesProperties.hollowCandleStyle.borderDownColor": "#fe4761",
            "mainSeriesProperties.hollowCandleStyle.wickColor": "#737375",
            "mainSeriesProperties.hollowCandleStyle.wickUpColor": "#3fcfb4",
            "mainSeriesProperties.hollowCandleStyle.wickDownColor": "#fe4761",
            "mainSeriesProperties.haStyle.upColor": "#3fcfb4",
            "mainSeriesProperties.haStyle.downColor": "#fe4761",
            "mainSeriesProperties.haStyle.drawWick": true,
            "mainSeriesProperties.haStyle.drawBorder": true,
            "mainSeriesProperties.haStyle.borderColor": "#3fcfb4",
            "mainSeriesProperties.haStyle.borderUpColor": "#3fcfb4",
            "mainSeriesProperties.haStyle.borderDownColor": "#fe4761",
            "mainSeriesProperties.haStyle.wickColor": "#737375",
            "mainSeriesProperties.haStyle.wickUpColor": "#3fcfb4",
            "mainSeriesProperties.haStyle.wickDownColor": "#fe4761",
            "mainSeriesProperties.haStyle.barColorsOnPrevClose": false,
            "mainSeriesProperties.barStyle.upColor": "#3fcfb4",
            "mainSeriesProperties.barStyle.downColor": "#fe4761",
            "mainSeriesProperties.barStyle.barColorsOnPrevClose": false,
            "mainSeriesProperties.barStyle.dontDrawOpen": false,
            "mainSeriesProperties.lineStyle.color": "#0cbef3",
            "mainSeriesProperties.lineStyle.linestyle": 0,
            "mainSeriesProperties.lineStyle.linewidth": 1,
            "mainSeriesProperties.lineStyle.priceSource": "close",
            "mainSeriesProperties.areaStyle.color1": "#0cbef3",
            "mainSeriesProperties.areaStyle.color2": "#0098c4",
            "mainSeriesProperties.areaStyle.linecolor": "#0cbef3",
            "mainSeriesProperties.areaStyle.linestyle": 0,
            "mainSeriesProperties.areaStyle.linewidth": 1,
            "mainSeriesProperties.areaStyle.priceSource": "close",
            "mainSeriesProperties.areaStyle.transparency": 80
        }
    }
}

//导出k线图配置
export function TradingViewConfig2() {
    return {
        container_id: "chart_container",
        library_path: "charting_library/",
        // custom_css_url: "/tv-charting-library/custom-styles.css",
        locale: "en",
        autosize: true,
        time_frames: [],
        // toolbar_bg: "#1c1c28",
        disabled_features: ["header_symbol_search", "header_compare", "symbol_search_hot_key", "compare_symbol", "symbol_info", "go_to_date", "timeframes_toolbar", "use_localstorage_for_settings"],
        enabled_features: ["remove_library_container_border", "hide_last_na_study_output", "dont_show_boolean_study_arguments", "hide_left_toolbar_by_default"],
        overrides: {
            "paneProperties.background": "#212121",
            "paneProperties.horzGridProperties.color": "#252537",
            "paneProperties.vertGridProperties.color": "#252537",
            "paneProperties.crossHairProperties.style": 1,
            "paneProperties.legendProperties.showBarChange": !1,
            "mainSeriesProperties.style": 1,
            "mainSeriesProperties.candleStyle.upColor": "#3fb68b",
            "mainSeriesProperties.candleStyle.borderUpColor": "#3fb68b",
            "mainSeriesProperties.candleStyle.wickUpColor": "#3fb68b",
            "mainSeriesProperties.candleStyle.downColor": "#ff5353",
            "mainSeriesProperties.candleStyle.borderDownColor": "#ff5353",
            "mainSeriesProperties.candleStyle.wickDownColor": "#ff5353",
            "mainSeriesProperties.statusViewStyle.symbolTextSource": "ticker",
            "scalesProperties.textColor": "#c3c2d4",
            "scalesProperties.backgroundColor": "#1c1c28",
            "scalesProperties.lineColor": "#252537"
        },
        studies_overrides: {
            "volume.volume.color.0": "#ff5353",
            "volume.volume.color.1": "#3fb68b",
            "volume.volume ma.visible": !1,
            "relative strength index.plot.color": "#5973fe",
            "relative strength index.plot.linewidth": 1.5,
            "relative strength index.hlines background.color": "#134A9F"
        },
        loading_screen: {
            backgroundColor: "#1c1c28",
            foregroundColor: "#1c1c28"
        },
        theme: "light", //"dark"
    }
}

//最新版
//导出k线图配置
export function TradingViewConfig() {
    return {
        debug:false,
        fullscreen: false, //屏幕尺寸
        symbol: null, //'AAPL',
        interval: localStorage.getItem('tradingview_interval') || '1D', //分时类型(周期)，默认日线
        autosize: true, //自动适应容器大小
        container: "chart_container",
        //	BEWARE: no trailing slash is expected in feed URL
        datafeed: null, //new Datafeeds.UDFCompatibleDatafeed("https://demo-feed-data.tradingview.com"),//数据
        library_path: "charting_library/", //资源库根路径
        custom_css_url: "custom-styles.css", //自定义样式文件
        locale: `${_vm.$t("locale")}`, //语言，en：英文，zh：中文
        timezone: "UTC",
        // preset: "mobile",//是否移动端，移动端就没有什么控件
        //禁用的功能
        disabled_features: [
            // "use_localstorage_for_settings",     //是否缓存配置
            "header_symbol_search",
            "header_compare",
            "symbol_search_hot_key",
            "compare_symbol",
            "symbol_info",
            "go_to_date",
            "timeframes_toolbar",
        ],
        //开启的功能
        enabled_features: [
            "header_resolutions",
            "control_bar",
            "edit_buttons_in_legend",
            "hide_left_toolbar_by_default",
            "hide_last_na_study_output",
            "remove_library_container_border",
            "dont_show_boolean_study_arguments"
        ],
        overrides: {
            "paneProperties.background": "#212121",
            "paneProperties.horzGridProperties.color": "#212121", //水平网格线颜色，默认：#313131
            "paneProperties.vertGridProperties.color": "#212121", //垂直网格线颜色，默认：#313131
            "paneProperties.crossHairProperties.style": 1,
            "paneProperties.legendProperties.showBarChange": !1,
            "paneProperties.topMargin": 10,//上边距，k线距离标题
            "paneProperties.bottomMargin": 20,//下边距，k线距离指标
            "mainSeriesProperties.style": 1,
            "mainSeriesProperties.candleStyle.upColor": "#3fb68b",
            "mainSeriesProperties.candleStyle.borderUpColor": "#3fb68b",
            "mainSeriesProperties.candleStyle.wickUpColor": "#3fb68b",
            "mainSeriesProperties.candleStyle.downColor": "#ff5353",
            "mainSeriesProperties.candleStyle.borderDownColor": "#ff5353",
            "mainSeriesProperties.candleStyle.wickDownColor": "#ff5353",
            "mainSeriesProperties.statusViewStyle.symbolTextSource": "ticker",
            "scalesProperties.textColor": "#AAA",
            "scalesProperties.backgroundColor": "#1c1c28",
            "scalesProperties.lineColor": "#252537",
            // "mainSeriesProperties.priceLineWidth": 5,
        },
        studies_overrides: {
            "volume.volume.color.0": "#ff5353",
            "volume.volume.color.1": "#3fb68b",
            "volume.volume ma.visible": !1,
            "relative strength index.plot.color": "#5973fe",
            "relative strength index.plot.linewidth": 1.5,
            "relative strength index.hlines background.color": "#134A9F"
        },
        loading_screen: {
            backgroundColor: "#1c1c28",
            foregroundColor: "#1c1c28"
        },
        // theme: "dark",//"light"     //主题色
    }
}