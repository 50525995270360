<template>
<div>
    <!-- <div v-show="loading"> -->
        <!--k线图-->
        <div  id="chart_container" class="f-fill" style="height:100%;width:100%;position:absolute;"></div>
    <!-- </div> -->
    <loading-layer :isShow="loading"></loading-layer>
</div>
</template>

<script>
import {TradingViewConfig} from './const/const.js'
import dayjs from 'dayjs';
import config from "@/config";
import { sendMsg, getKline } from "@/api/http"
import { getThisTime } from '@/utils/base.js'
let updateDataTimeoutId=0
export default {
    name: '',
    props: {
        isShow:{
            type:Boolean
        }
    },
    components: {

    },
    data () {
        return {
            loading:true,
            tradingViewConfig: TradingViewConfig(),//k线图配置
            currency1: '',//币种简称。ETH
            currency2: 'USD',//
            saved_chart: null,
            chart: null,//k线图对象
            feed: null,//数据对象
            last_price:1234.2365,
            //time：日期(毫秒单位时间戳)，close：收盘价，open：开盘价，high：最高价，low：最低价，volume：交易量
            bars:[],
            realBar:[],
            nextFromTime:0,
        }
    },
    methods: {
        async updateData(){
            if(this.blockchain.perpetual){
                clearTimeout(updateDataTimeoutId)
                await getKline(this.blockchain.perpetual, localStorage.getItem('tradingview_interval') || '1D',this.nextFromTime).catch(err=>console.warn(err.message))
                updateDataTimeoutId=setTimeout(()=>this.updateData(),5000) 
            }else{
                updateDataTimeoutId=setTimeout(()=>this.updateData(),500) 
            }
        },

        //创建表格数据，自动调用
        createFeed(){
            let _this_ = this;
            let Datafeed = {};
            Datafeed.DataPulseUpdater = function(datafeed, updateFrequency) {
                this._datafeed = datafeed;
                this._subscribers = {};
                this._requestsPending = 0;
                var that = this;
                var update = function() {
                    if (that._requestsPending > 0) {
                        return;
                    }
                    for (var listenerGUID in that._subscribers) {
                        var subscriptionRecord = that._subscribers[listenerGUID];
                        var resolution = subscriptionRecord.resolution;
                        var datesRangeRight = parseInt((new Date().valueOf()) / 1000);
                        var datesRangeLeft = datesRangeRight - that.periodLengthSeconds(resolution, 10);
                        that._requestsPending++;
                        (function(_subscriptionRecord) {
                            that._datafeed.getBars(_subscriptionRecord.symbolInfo, resolution, datesRangeLeft, datesRangeRight, function(bars) {
                                    that._requestsPending--;
                                    if (!that._subscribers.hasOwnProperty(listenerGUID)) {
                                        return;
                                    }
                                    if (bars.length === 0) {
                                        return;
                                    }
                                    var lastBar = bars[bars.length - 1];
                                    if (!isNaN(_subscriptionRecord.lastBarTime) && lastBar.time < _subscriptionRecord.lastBarTime) {
                                        return;
                                    }
                                    var subscribers = _subscriptionRecord.listeners;
                                    var isNewBar = !isNaN(_subscriptionRecord.lastBarTime) && lastBar.time > _subscriptionRecord.lastBarTime;
                                    if (isNewBar) {
                                        if (bars.length < 2) {
                                            throw new Error('Not enough bars in history for proper pulse update. Need at least 2.');
                                        }
                                        var previousBar = bars[bars.length - 2];
                                        for (var i = 0; i < subscribers.length; ++i) {
                                            subscribers[i](previousBar);
                                        }
                                    }
                                    _subscriptionRecord.lastBarTime = lastBar.time;
                                    for (let i = 0; i < subscribers.length; ++i) {
                                        subscribers[i](lastBar);
                                    }
                                },
                                //	on error
                                function() {
                                    that._requestsPending--;
                                });
                        })(subscriptionRecord);
                    }
                };
                if (typeof updateFrequency != 'undefined' && updateFrequency > 0) {
                    setInterval(update, updateFrequency);
                }
            };

            Datafeed.DataPulseUpdater.prototype.periodLengthSeconds = function(resolution, requiredPeriodsCount) {
                var daysCount = 0;
                if (resolution === 'D') {
                    daysCount = requiredPeriodsCount;
                } else if (resolution === 'M') {
                    daysCount = 31 * requiredPeriodsCount;
                } else if (resolution === 'W') {
                    daysCount = 7 * requiredPeriodsCount;
                } else {
                    daysCount = requiredPeriodsCount * resolution / (24 * 60);
                }
                return daysCount * 24 * 60 * 60;
            };

            Datafeed.DataPulseUpdater.prototype.subscribeDataListener = function(symbolInfo, resolution, newDataCallback, listenerGUID) {
                this._datafeed._logMessage('Subscribing ' + listenerGUID);
                if (!this._subscribers.hasOwnProperty(listenerGUID)) {
                    this._subscribers[listenerGUID] = {
                        symbolInfo: symbolInfo,
                        resolution: resolution,
                        lastBarTime: NaN,
                        listeners: []
                    };
                }
                this._subscribers[listenerGUID].listeners.push(newDataCallback);
            };

            Datafeed.DataPulseUpdater.prototype.unsubscribeDataListener = function(listenerGUID) {
                this._datafeed._logMessage('Unsubscribing ' + listenerGUID);
                delete this._subscribers[listenerGUID];
            };

            //container主要数据结构。
            Datafeed.Container = function(updateFrequency){
                this._configuration = {
                    supports_search: false,
                    supports_group_request: false,
                    supported_resolutions: ['1','15','60', '1D'],//时间：['1', '3', '5', '15', '30', '60', '120', '240', '360', '720', '1D', '3D', '1W', '1M']
                    supports_marks: true,
                    supports_timescale_marks: true,
                    exchanges: ['MEKE'],//交易所名称
                };
                this._barsPulseUpdater = new Datafeed.DataPulseUpdater(this, updateFrequency || 10 * 1000);
                this._enableLogging = true;
                this._callbacks = {};
                this._initializationFinished = true;
                this._fireEvent('initialized');
                this._fireEvent('configuration_ready');
            };

            Datafeed.Container.prototype._fireEvent = function(event, argument) {
                if (this._callbacks.hasOwnProperty(event)) {
                    var callbacksChain = this._callbacks[event];
                    for (var i = 0; i < callbacksChain.length; ++i) {
                        callbacksChain[i](argument);
                    }
                    this._callbacks[event] = [];
                }
            };

            Datafeed.Container.prototype._logMessage = function(message) {
                if (this._enableLogging) {
                    var now = new Date();
                    // console.log("CHART LOGS: "+now.toLocaleTimeString() + '.' + now.getMilliseconds() + '> ' + message);
                }
            };

            Datafeed.Container.prototype.on = function(event, callback) {
                if (!this._callbacks.hasOwnProperty(event)) {
                    this._callbacks[event] = [];
                }
                this._callbacks[event].push(callback);
                return this;
            };
            //此方法可以设置图表库支持的图表配置
            Datafeed.Container.prototype.onReady = function(callback) {
                let that = this;
                if (this._configuration) {
                    setTimeout(function() {
                        callback(that._configuration);
                    }, 0);
                }
                else {
                    this.on('configuration_ready', function() {
                        callback(that._configuration);
                    });
                }
            };
            //通过商品名称解析商品信息
            //symbolName(商品名称 或ticker), onSymbolResolvedCallback, onResolveErrorCallback
            Datafeed.Container.prototype.resolveSymbol = function(symbolName, onSymbolResolvedCallback, onResolveErrorCallback) {
                
                this._logMessage("GOWNO :: resolve symbol "+ symbolName);
                Promise.resolve().then(() => {
                    function adjustScale(){
                        if(_this_.last_price>1000)
                            return 100;
                        else
                            return 100000000;
                    }
                    this._logMessage("GOWNO :: onResultReady inject "+'AAPL');
                    onSymbolResolvedCallback({
                        "name": _this_.currency1 + "-"+ _this_.currency2,
                        // "name":"AAPL",
                        // "symbol": '55',//商品名称
                        "timezone": "UTC",//这个商品的交易所时区
                        "pricescale": adjustScale(),//价格精度
                        "minmov": 1,//最小波动
                        "minmov2": 0,
                        "ticker": _this_.currency1 + "-"+ _this_.currency2,
                        // "ticker": 'MEKE',//它是您的商品体系中此商品的唯一标识符
                        "description": "",//商品说明。这个商品说明将被打印在图表的标题栏中。
                        "session": "24x7",//商品交易时间。7*24小时(例如：比特币或其它数字货币)
                        "type": "bitcoin",//仪表的可选类型。bitcoin比特币 
                        "exchange-traded": "MEKE",
                        "exchange-listed": "MEKE",
                        "has_intraday": true,//布尔值显示商品是否具有日内（分钟）历史数据。
                        "intraday_multipliers": ['1'],//这是一个包含日内周期(分钟单位)的数组，datafeed将会自行构建它。
                        "has_weekly_and_monthly": false,//布尔值显示商品是否具有以W和M为单位的历史数据。
                        "has_no_volume": false,//布尔表示商品是否拥有成交量数据。
                        "regular_session": "24x7",
                        // "timeScale.rightOffset": 50,
                        //     'timeScale':{
                        //         'barSpacing':'19.119813271949624'
                        //     }
                    });
                })
            };
            //当图表库需要由日期范围定义的历史K线片段时，将调用此函数
            //旧版getBars回调函数有六个参数，新版的回调函数只有五个。
            //旧版：getBars(symbolInfo, resolution, rangeStartDate, rangeEndDate, onDataCallback, onErrorCallback)
            //新版：getBars(symbolInfo, resolution, periodParams, onHistoryCallback, onErrorCallback)，旧版的rangeStartDate, rangeEndDate这两个值被放在了periodParams，并且新增了两个值。

            // Datafeed.Container.prototype.getBars = function(symbolInfo, resolution, rangeStartDate, rangeEndDate, onDataCallback, onErrorCallback) {
            Datafeed.Container.prototype.getBars =async function(symbolInfo, resolution, periodParams, onHistoryCallback, onErrorCallback) {
                // console.log('symbolInfo',symbolInfo,_this_.bars,periodParams)
                // if (rangeStartDate > 0 && (rangeStartDate + '').length > 10) {
                //     throw new Error(['Got a JS time instead of Unix one.', rangeStartDate, rangeEndDate]);
                // }
                // onDataCallback([], { noData: true });
                // console.log(rangeEndDate,'rangeEndDate')
                
                // rangeEndDate([], { noData: true });
                onHistoryCallback([], { noData: true });
                // let res=await getTradingview({
                //     beginTime:_this_.bars[0].time,//(periodParams.from)+'000',
                //     // endTime:(periodParams.to)+'000',
                //     perpetual:_this_.blockchain.perpetual,
                //     type:'60',//周期
                //     count:100,//长度
                // });
                // console.log(res,'res55')
                // if(res.code==200){
                //     if(res.data.length>0 && res.data.length<100){
                //         _this_.bars=res.data.concat(_this_.bars);
                //         onHistoryCallback(res.data, { noData: false });
                //     }else{
                //         _this_.bars=res.data.concat(_this_.bars);
                //         onHistoryCallback(res.data, { noData: true });
                //     }
                // }else{
                //     onHistoryCallback([], { noData: true });
                // }

                // onHistoryCallback([], { noData: true });
                // onHistoryCallback(_this_.bars, { noData: false });
                // onDataCallback(bars, { noData: true , nextTime: data.nb || data.nextTime });


                //参数信息
                // let requestParams = {
                //     symbol: symbolInfo.ticker || '',//ticker:symbolInfo对象唯一标识
                //     resolution: resolution,//周期
                //     from: rangeStartDate,
                //     to: rangeEndDate,
                // };
                // //请求历史数据：ajax或websocket
                // let dataList = ajax(url,requestParams);
                // //调用回调方法，传入历史数据到TradingView
                // onResult(formatDataList, {noData: false});
            };
            //订阅K线数据
            Datafeed.Container.prototype.subscribeBars = function(symbolInfo, resolution, onRealtimeCallback, listenerGUID, onResetCacheNeededCallback) {
                // console.log('symbolInfo',symbolInfo);
                _this_.bars.concat(_this_.realBar).sort((a,b)=>a.time-b.time).forEach(function (bar) {
                    onRealtimeCallback(bar)
                });
                this.on('pair_change', function() {
                    onResetCacheNeededCallback();
                });
            };
            //取消订阅K线数据
            Datafeed.Container.prototype.unsubscribeBars = function(listenerGUID) {
                this._barsPulseUpdater.unsubscribeDataListener(listenerGUID);
            };
            return new Datafeed.Container;
        },
        //初始化图表
        init(){
            this.feed = this.createFeed();
            this.tradingViewConfig.symbol = this.currency1 + ":"+ this.currency2;//'AAPL'
            this.tradingViewConfig.locale=`${this.$t("locale")}`;
            this.tradingViewConfig.datafeed = this.feed;
            // this.tradingViewConfig.datafeed = new Datafeeds.UDFCompatibleDatafeed("https://demo-feed-data.tradingview.com");//this.feed;
            this.chart = window.tvWidget = new TradingView.widget(this.tradingViewConfig);
            
            this.chart.onChartReady(()=>{
                
                //默认展示MA Cross。移动平均线
                // this.chart.chart().createStudy("MA Cross", false, false, [10, 20]);
                
                //切换分时图
                this.chart.subscribe("time_interval",res=>{
                    this.nextFromTime=0;
                    localStorage.setItem('tradingview_interval',res.label);
                    this.changePair();
                    this.updateData()
                    this.loading=true
                });
                // this.changePair();
                //选中k线，点击k线 
                //time：当前列的时间，price：当前列鼠标高度的价格
                //需要判断时间是否存在数据中，再获取相关的数据（开盘价，收盘价等等）
                // this.chart.activeChart().crossHairMoved().subscribe(null,({ time, price }) => {
                //     console.log('选中k线：',time, price)
                // });
                //点击释放后还原数据。
                // this.chart.subscribe("mouse_up",()=>{
                // })
                //订阅可见逻辑范围更改
                //判断是否需要获取历史数据
                // this.chart.activeChart().onVisibleRangeChanged().subscribe( null,({ from, to }) =>{
                //     console.log('onVisibleRangeChanged:',from, to,getThisTime(from).now)
                // });
            });
            
        },
     
        //连接socket
        initSocket(){
            this.$eventBus.$on("tradingview_msg",(res)=>{
                this.loading=false
                this.nextFromTime=res.data.lastTime??0;
                let type = res.data.type;
                this.currency1 = this.$store.state.blockchain.abbreviation;
                let ds=res.data.data.map((item,index)=>(
                     {
                        time:item.t*1000,
                        close:item.c,
                        open:item.o,
                        high:item.h,
                        low:item.l,
                        volume:item.v
                    }
                ));
                let d=res.data.real;
                this.realBar= d ? [{
                            time:d.t*1000,
                            close:d.c,
                            open:d.o,
                            high:d.h,
                            low:d.l,
                            volume:d.v
                        }]:[];
                
                if(type=='get'){    //首次进入获取数据
                    this.bars= ds || [];
                    if(!this.chart){
                        this.init();
                    }else{
                        this.changePair();
                    }
                }else if(type=='add'){      //新增数据
                    ds.forEach(v=>this.bars.push(v));
                    this.changePair();
                }
            });
            this.$eventBus.$on('socket:reconnection',()=>{
                console.log('重连消息');
                this.updateData()
            });
            this.updateData()
        },
        //更新图表
        changePair() {
            if(this.chart && this.feed){
                try{
                    this.feed._fireEvent('pair_change');
                    this.chart?.activeChart?.().resetData();
                    this.chart?.activeChart?.().setSymbol(this.currency1+"-"+this.currency2, () => {
                    });
                }catch(err){
                    console.warn(err.message);
                }
            }
        },
    },
    mounted() {
        this.initSocket()
    },
    computed: {
        //多语言切换
        lang(){
            return this.$i18n.locale;
        },
        blockchain(){
            return this.$store.state.blockchain;
        },
    },
    watch:{
        //监听多语言切换
        lang(){
            // this.tradingViewConfig.locale=`${this.$t("locale")}`;
            // this.tradingViewConfig.datafeed = this.feed;
            // this.chart = window.tvWidget = new TradingView.widget(this.tradingViewConfig);
            this.init();
        },
        pair(newVal, oldVal){
            this.currency1= newVal[0];
            this.currency2= newVal[1];
            this.changePair();
        },
    },
    beforeDestroy(){
        this.$eventBus.$off("tradingview_msg");
        this.$eventBus.$off('socket:reconnection');
        clearTimeout(updateDataTimeoutId);
        this.chart=null;
    }
 }
</script>

<style lang='less'>
#chart_container{
    h1, h2 {
    font-weight: normal;
    }
    ul {
    list-style-type: none;
    padding: 0;
    }
    li {
    display: inline-block;
    margin: 0 10px;
    }
    a {
    color: #42b983;
    }
}
</style>