<!--存取款弹窗-->
<template>
<div>
    <Modal :value="isDepositPop" width="400px" :mask-closable="false" @on-cancel="$emit('change',false)">
        <template slot="header">
            <Icon type="information-circled"></Icon>
            <!-- <span v-if='controlType==1'>{{$t('withdraw')}}</span>
            <span v-else-if='controlType==2'>{{$t('deposit')}}</span> -->
            <span>{{$t('margin')}}</span>
        </template>
        <div>
            <ul class="accountInfo" style="position:relative;">
                <li>
                    <span>{{$t('marginBalance')}}</span>
                    <span>{{marginBalance | numFormat(2)}}</span>
                </li>
                <li>
                    <span>{{$t('freeMargin')}}</span>
                    <span>{{availableMargin | numFormat(2)}}</span>
                </li>
            </ul>
        </div>
        <div class="controlType">
            <span :class="{'active':controlType==2}" @click="controlType=2">{{$t('deposit')}}</span>
            <span :class="{'active':controlType==1}" @click="controlType=1">{{$t('withdraw')}}</span>
            
            <!-- <span v-if='controlType==1'>{{$t('withdraw')}}</span>
            <span v-else-if='controlType==2'>{{$t('deposit')}}</span> -->
        </div>
        <!--数量 ETH-->
        <div class="actionWpTitle">{{this.$t("assets")}}</div>
        <div class="mt10">
            <Select v-model="form.currency" class="mb10" size="large" style="width:100%;">
                <Option v-for="(item,index) in currency" :key="index" :value="index">{{item.type}}</Option>
            </Select>
        </div>
        <div class="mt15 actionWpTitle">{{this.$t("amount")}}</div>
        <div class="mt10">
            <ex-input v-model="form.amount" @input="e=>form.amount=prohibitNegative(e,3,20)" max-length="20" placeholder="0.00" @mousewheel.native.prevent />
        </div>
        <!-- <div class="amountBox">
            <div class="flexBetween">
                <div class="actionWpTitle">{{$t('freeMargin')}}<span class="tag ml5">USDT</span></div>
                <em>{{availableMargin | numFormat(2)}}</em>
            </div>
        </div> -->
        <!-- <div class="infobox">
            <div class="flexBetween">
                <span>Equity</span>
                <em>$0.00</em>
            </div>
            <div class="flexBetween">
                <span>购买力</span>
                <em>$0.00</em>
            </div>
        </div> -->
        <!-- <div v-if="allowance==0" class="infobox">
            <div class="fontColor center" style="margin-bottom:10px;">{{$t('approveUSDC')}}</div>
            <Button type="info" size="large" long @click="approve()">{{$t('enable')}} USDT</Button>
        </div> -->
        <template slot="footer">
            <Button type="info"  size="large" long @click="confirm()" :disabled="isForm">
                <span v-show='controlType==1'>{{$t('confirmWithdraw')}}</span>
                <span v-show='controlType==2'>{{$t('confirmDeposit')}}</span>
            </Button>
            <!-- <span v-else></span> -->
        </template>
    </Modal>
</div>
</template>

<script>
import exInput from "@/components/common/exInput/index.vue"

import WalletUtil from "@/utils/walletUtil"
import {getCurrencyList} from "@/api/http"
import ContractUtil from "@/utils/contractUtil"
const {  toWad2 } = require('@/utils/signFn/constants.js');//常量模块
let initTimeoutId=0
let loading=false;
export default {
    name: '',
    model:{
        prop:'isDepositPop',
        event:'change'
    },
    props: {
        //是否显示弹窗
        isDepositPop:{
            type:Boolean,
            default:false
        },
        marginBalance:{
            // type:String,
            default:''
        },
        availableMargin:{
            // type:String,
            default:''
        }
    },
    components: {
        exInput
    },
    data () {
        return {
            /**弹窗内容 */
            isLoadingLayer:true,
            // marginBalance:'',//保证金余额
            // availableMargin:'',//可用保证金
            // isDepositPop:false,//存款弹窗
            currency:[],//所有币种
            allowance:0,//是否授权usdc
            controlType:0,//1：取款，2：存款
            form:{
                currency:0,//币种
                amount:'',//数量
            },
        }
    },
    mounted() {
        getCurrencyList().then(currencyList=>{
            this.currency=currencyList;
        });
        this.init();
        this.showDialog(2);
    },
    methods: {
         //初始化
        init(){
            clearTimeout(initTimeoutId)
            if(!this.addr || !this.isInitWallet){
                initTimeoutId=setTimeout(()=>this.init(),2000)
                return
            }
            if(this.blockchain.perpetual){
                WalletUtil.perpetual.at(this.blockchain.perpetual)
            }
            if(this.blockchain.usdc){
                WalletUtil.usdt.at(this.blockchain.usdc)
            }
        },
        //获取是否授权
        getAllowance(){
            if(loading){
                this.$Message.warning(this.$t('loading'));
            }
            loading=true;
            ContractUtil.usdt.at(this.blockchain.usdc).then((instance) => {
                return instance.allowance(this.addr,this.blockchain.perpetual).then(res=>{
                    loading=false;
                    this.allowance=res.toString();
                    // this.isDepositPop=true;
                })
            });
        },
        //显示弹窗
        showDialog(controlType){
            this.controlType=controlType;
            this.form.amount='';
            // this.getAllowance();
        },
        //确认
        confirm(){
            let amount=this.form.amount;
            this.form.amount='';
            let abbreviation=this.blockchain.abbreviation;
            //1：取款，2：存款
            if(this.controlType==1){
                this.withdraw(amount,abbreviation);
            }else if(this.controlType==2){
                this.deposit(amount,abbreviation);
            }
        },
        //取款
        async withdraw(amount,abbreviation){
            //取款可用保证金必须大于0和取值
            if(this.availableMargin<=0 || amount > this.availableMargin){
                this.$Message.error(this.$t('notAvailableMargin'));
                return false;
            }
            WalletUtil.perpetual.at(this.blockchain.perpetual).then(async (instance) => {
                await instance.withdraw(toWad2(amount), {from: this.addr})
                .then((res) => {
                    this.$emit('change',false)
                    // this.isDepositPop=false;
                    // this.$Message.success(this.$t('withdrawSuccess'));
                    this.$Notice.success({
                        title: `${abbreviation}-USD ${this.$t('withdrawSuccess')}!`,
                        duration:4.5,
                    });
                }).catch(err=>{
                    this.$Notice.error({
                        title: `${abbreviation}-USD ${this.$t('withdrawalFailed')}`,
                        duration:4.5,
                    });
                });
            });
        },
        //存款
        async deposit(amount,abbreviation){
            //存款只能存可用钱包余额大小
            let getAvailableMargin=this.$store.getters['getAvailableMargin'];//可用钱包余额
            if(getAvailableMargin<=0 || amount > getAvailableMargin){
                this.$Message.error(this.$t('Insufficient_available_wallet_balance'));
                return false;
            }
            WalletUtil.perpetual.at(this.blockchain.perpetual).then(async (instance) => {
                await instance.deposit(toWad2(amount), {from: this.addr,})
                .then((res) => {
                    // this.isDepositPop=false;
                    this.$emit('change',false)
                    // this.$Message.success(this.$t('depositSuccess'));
                    // this.getData(this.blockchain.perpetual);
                    this.$Notice.success({
                        title:`${abbreviation}-USD ${this.$t('depositSuccess')}`,
                        duration:4.5,
                    });
                    // sessionStorage.setItem('notice_list',JSON.stringify([{
                    //     type:'deposit',
                    //     data:{
                    //         abbreviation,
                    //         status:1,//1:成功，2：失败
                    //     }
                    // }]));
                }).catch(err=>{
                    this.$Notice.error({
                        title: `${abbreviation}-USD ${this.$t('depositFailed')}`,
                        duration:4.5,
                    });
                    throw err;
                    // sessionStorage.setItem('notice_list',JSON.stringify([{
                    //     type:'deposit',
                    //     data:{
                    //         abbreviation,
                    //         status:2,//1:成功，2：失败
                    //     }
                    // }]));
                });
            }).catch(err=>{
                console.warn(err.message)
            })
        },
    },
    computed:{
        //保证金使用率
        marginBalanceRate(){
            return Math.max(0,this.availableMargin >=0 ? (this.marginBalance-this.availableMargin)/this.marginBalance*100 : 100);
        },
        //是否完成表单
        isForm(){
            return !this.form.amount || this.form.amount==0;
        },
        blockchain(){
            return this.$store.state.blockchain;
        },
        //是否初始化钱包
        isInitWallet() {
            return this.$store.state.app.wallet.isInit;
        },
        //地址
        addr(){
            return this.$store.state.app.wallet.address;
        },
    },
}
</script>

<style scoped lang='less'>
//存取款
.accountInfo{padding:20px;overflow-y:auto;background: #000;color:@font-color;}
.accountInfo>li{.flexBetween;margin:4px 0;}
.controlType{
    margin:10px 0;
    .op8;
    color:white;
    display:flex;
    text-align: center;
    span{
        cursor:pointer;
        width:50%;
        padding-bottom:5px;
        border-bottom:3px solid #111;
        opacity: 0.7;
    }
    span.active{
        cursor:auto;
        border-bottom:3px solid @error-color;
        opacity:10;
    }
}
</style>