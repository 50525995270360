<!--深度图新版-->
<template>
<div>
    <div id="depthchart-container" class="f-fill" style="height:100%;width:100%;position:absolute;"></div>
    <loading-layer :isShow="loading"></loading-layer>
</div>
</template>

<script>
import config from "@/config";
import { sendMsg } from "@/api/http"

let updateChartTimeoutId=0

export default {
    name: '',
    props: {
        isShow: {
            type: Boolean
        }
    },
    components: {

    },
    data () {
        return {
            zdy_chart:null,//深度图对象
            //price:价格，totalAmount:总数量，totalCost总成本：，priceGap价格差
            buy:[],//卖出
            sell:[],
            loading:true
        }
    },
    mounted() {
        setTimeout(()=>{
            this.initSocket();
        },50);
        this.updateData()
    },
    methods: {
        updateData(){
            sendMsg({cmd:'depthchart'});
            this.updateChart()
        },

        //连接socket
        initSocket(){
            //深度图
            this.$eventBus.$on("depthchart_msg",(res)=>{
                this.loading=false
        
                var buy1=res.data.buy.at(-1)?.p||0;
                var sell1=res.data.sell[0]?.p||0;

                res.data.buy.forEach(item=>{
                    item.totalAmount=item.a;
                    item.price=item.p;
                    item.priceGap= sell1!=0 ? (sell1 - item.p)/sell1 : 0;
                });
                res.data.sell.forEach(item=>{
                    item.totalAmount=item.a;
                    item.price=item.p;
                    item.priceGap=buy1!=0 ? (item.p-buy1)/buy1: 0;
                });
                let buy=res.data.buy || [];
                let sell=res.data.sell || [];
                let newBuy=[],newSell=[];
                buy.forEach((item,index)=>{
                    let total=0;
                    for(var i=index;i<buy.length;i++){
                        total+=buy[i].totalAmount;
                    }//totalAmount：总数量
                    newBuy.push({
                        price:item.price,
                        total:total,
                        totalCost:item.price*total,
                        priceImpact:item.priceGap*100
                    });
                });
                sell.forEach((item,index)=>{
                    let total=0;
                    for(var i=0;i<=index;i++){
                        total+=sell[i].totalAmount;
                    }
                    newSell.push({
                        price:item.price,
                        total:total,
                        totalCost:item.price*total,
                        priceImpact:item.priceGap*100
                    });
                });
                this.buy=newBuy;
                this.sell=newSell;
                // this.updateChart();
                if(newBuy.length>0 && newSell.length>0){
                    this.updateChart()
                }
            });
        },
        //刷新处理数据
        updateChart(){
            clearTimeout(updateChartTimeoutId)
            if(!this.$props.isShow || !this.zdy_chart || this.loading){
                if(this.$props.isShow && !this.loading){
                    this.initChart()
                }
                updateChartTimeoutId=setTimeout(()=>{
                    this.updateChart()
                },300)
                return
            }

            this.zdy_chart.setOption();
            this.zdy_chart.render();
        },
        //绘制图表
        initChart(){
            var that=this;
            var bid1 =this.buy;//买入
            var ask1 = this.sell;//卖出
            /**
             * 绘制调用函数
             * selector：dom选择器；
             * d3：d3实例；
             * opts：配置。
             */
            function draw(selector, d3 = window.d3,opts=null){
                //默认配置
                if(opts==null){
                    opts = {
                        hasGrid: true,
                        x: {
                            point: 8
                        },
                        y: {
                            point: 4
                        }
                    }
                }
                //上下左右边距
                const margin = {
                    top: 20,
                    right: 20,
                    bottom: 30,
                    left: 40
                };
                //实例对象
                const depth = {
                    v: {
                        depthIns: null,
                        init(depthIns) {
                            this.depthIns = depthIns
                        }
                    },
                    c: {
                        depthIns: null,
                        init(depthIns) {
                            this.depthIns = depthIns
                        }
                    },
                    //表现
                    markLine: {
                        depthIns: null,
                        init(depthIns) {
                            this.depthIns = this.depthIns;
                            this.v.init(depthIns);
                            this.m.init(depthIns);
                            this.c.init(depthIns);
                        },
                        m: {
                            depthIns: null,
                            init(depthIns) {
                                this.depthIns = depthIns;
                            }
                        },
                        v: {
                            el: null,
                            depthIns: null,
                            init(depthIns) {
                                this.depthIns = depthIns;
                                this.initDom();
                            },
                            initDom() {
                                this.el = depth.container.v.el.append('div')
                            },
                        },
                        c: {
                            depthIns: null,
                            top: margin.top,
                            left: margin.left,
                            init(depthIns) {
                                this.depthIns = depthIns;
                            },
                            hide() {
                                this.depthIns.markLine.v.el.transition()
                                    .duration(0)
                                    .style('opacity', 0)
                            },
                            show() {
                                this.depthIns.markLine.v.el.transition()
                                    .duration(0)
                                    .style('opacity', 1)
                            },

                            move(x, y) {
                                this.left = x;
                                this.top = y;
                                this.render();
                            },
                            render() {
                                //移动线的颜色
                                if (this.depthIns.svg.gC.mouse.m.inLeft()) {
                                    this.depthIns.markLine.v.el.attr('class', 'tl-line')
                                    .style('position', 'absolute')
                                    .style('top', `${this.top}px`)
                                    .style('left', `${this.left}px`)
                                    .style('width', '2px')
                                    .style('height', `${depth.m.scale.y(0)}px`)
                                    .style('background', 'linear-gradient(#24AE8F 50%, transparent 50%)')
                                    .style('background-size','100% 5px')
                                    .style('opacity', 0)
                                } else {
                                    this.depthIns.markLine.v.el.attr('class', 'tl-line')
                                    .style('position', 'absolute')
                                    .style('top', `${this.top}px`)
                                    .style('left', `${this.left}px`)
                                    .style('width', '2px')
                                    .style('height', `${depth.m.scale.y(0)}px`)
                                    .style('background', 'linear-gradient(transparent 50%, #ed4014 50%)')
                                    .style('background-size','100% 5px')
                                    .style('opacity', 0)
                                }
                                
                            }
                        },

                    },
                    //工具弹窗
                    tooltip: {
                        depthIns: null,
                        m: {
                            depthIns: null,
                            init(depthIns) {
                                this.depthIns = depthIns;
                            },
                            getX() {
                                const pL = 15;
                                const pR = 15;
                                const pos = depth.svg.gC.m.getPos();

                                const {
                                    tlW,
                                    tlH
                                } = this.getSize();

                                if (this.depthIns.svg.gC.mouse.m.inLeft()) {
                                    return pL + pos.x;
                                }
                                if (!this.depthIns.svg.gC.mouse.m.inLeft()) {
                                    return pos.x - tlW - pL - margin.left;
                                }
                            },
                            getY() {
                                const item = this.depthIns.m.xAxis.pos2data();
                                if (!item) {
                                    return 0;
                                }
                                const y = depth.m.scale.y(item.total);
                                return y;
                            },
                            get(turn) {
                                const pos = depth.svg.gC.m.getPos();

                                const {
                                    tlW,
                                    tlH
                                } = this.getSize();
                                if (turn == 'left') {
                                    return pos.pT + pos.y;
                                }
                                if (turn == 'right') {
                                    return pos.y - tlH - pos.pT;
                                }
                            },
                            getSize() {
                                const tlW = this.depthIns.tooltip.v.el.node().clientWidth;
                                const tlH = this.depthIns.tooltip.v.el.node().clientHeight;
                                return {
                                    tlW,
                                    tlH
                                }
                            },
                        },
                        v: {
                            el: null,
                            depthIns: null,
                            init(depthIns) {
                                this.depthIns = depthIns;
                                this.initDom()
                            },
                            initDom() {
                                this.el = depth.container.v.el.append('div');
                            },
                        },
                        c: {
                            top: margin.top,
                            left: margin.left,
                            depthIns: null,
                            isHide: true,
                            type: 'bid', // bid | ask
                            html: '',
                            init(depthIns) {
                                this.depthIns = depthIns;
                            },
                            show() {
                                const posData = this.depthIns.m.xAxis.pos2data();

                                const pos = this.depthIns.svg.gC.m.getPos();

                                if (this.depthIns.svg.gC.mouse.m.inLeft()) {
                                    this.setType('bid')
                                } else {
                                    this.setType('ask')
                                }

                                this.isHide = false;
                                this.html = `
                                    <div style='font-size: 14px;line-height: 16px;white-space: nowrap;'>
                                        <div style="display:flex">
                                            <div>${that.$t('price')}：</div>
                                            <div style="font-weight: bold;padding-left:10px">${posData.price.toFixed(that.blockchain.priceAccuracy)}</div>
                                        </div>
                                        <div style="display:flex">
                                            <div>${that.$t('totalSize')}：</div>
                                            <div style="font-weight: bold;padding-left:10px">${posData.total.toFixed(that.blockchain.amountAccuracy)}</div>
                                        </div>
                                        <div style="display:flex">
                                            <div>${that.$t('totalCost')}：</div>
                                            <div style="font-weight: bold;padding-left:10px">${(posData.totalCost).toFixed(that.blockchain.priceAccuracy)}</div>
                                        </div>
                                        <div style="display:flex">
                                            <div>${that.$t('priceImpact')}：</div>
                                            <div style="font-weight: bold;padding-left:10px">${(posData.priceImpact).toFixed(2)}%</div>
                                        </div>
                                    </div>
                                `;
                                this.render();
                            },
                            hide() {
                                this.isHide = true;
                                this.render();
                            },
                            move(x, y) {
                                this.left = x;
                                this.top = y;
                                this.render();
                            },
                            setType(type) {
                                this.type = type;
                                this.render();
                            },
                            render() {
                                let box=$('#depthchart-container');
                                let width=box.width(),height=box.height();
                                let pop=$('.tl-tooltip');
                                let popW=pop.width(),popH=pop.height();
                                let y=this.depthIns.tooltip.m.getY();
                                let x=this.depthIns.tooltip.m.getX();
                                //渲染悬浮数据，悬浮窗
                                this.depthIns.tooltip.v.el.interrupt()
                                    .attr('class', 'tl-tooltip')
                                    .html(this.html)
                                    .transition()
                                    .duration(300)
                                    .style('position', 'absolute')
                                    .style('top', `${y<0?0:(y>height-popH?(height-(popH+popH/2)):y)}px`)
                                    .style('left', `${x<0?0:(x>width-popW?(width-popW):x)}px`)
                                    .style('padding', '5px')
                                    .style('box-shadow', '2px 2px 5px #333333')
                                    .style('opacity', this.isHide ? '0' : '1')
                                    .style('background', 'rgba(33, 33, 33)')
                                    .style('border', this.type == 'bid' ? '2px solid rgba(36, 174, 143)' :
                                        '2px solid rgba(237, 64, 20)')
                                    //条件渲染弹窗颜色
                                    // .style('background', 'rgba(255,251,251,0.7)')
                            }
                        },
                        init(depthIns) {
                            this.depthIns = depthIns;
                            this.v.init(depthIns);
                            this.m.init(depthIns);
                            this.c.init(depthIns);
                        },
                    },
                    svg: {
                        depthIns: null,
                        gC: {
                            depthIns: null,
                            mouse: {
                                depthIns: null,
                                m: {
                                    depthIns: null,
                                    init(depthIns) {
                                        this.depthIns = depthIns;
                                    },
                                    inLeftMin() {
                                        const [x, y] = d3.mouse(this.depthIns.container.v.el.node());
                                        return x <= margin.left;
                                    },
                                    inLeftMax() {

                                    },
                                    isRightMin() {

                                    },
                                    isLimit() {
                                        return this.inLeftMin() || this.inRightMax();
                                    },
                                    inRightMax() {
                                        const [x, y] = d3.mouse(this.depthIns.container.v.el.node());
                                        return x >= (this.depthIns.m.xAxis.toPos(this.depthIns.m.xAxis.max) + margin
                                            .left);
                                    },
                                    inLeft() {
                                        const pos = this.depthIns.svg.gC.m.getPosWithoutMargin();

                                        const half = this.depthIns.m.scale.x(this.depthIns.m.xAxis.left.max);

                                        if (pos.x > half) {
                                            return false;
                                        }
                                        return true;
                                    },
                                    inRight() {

                                    }
                                },
                                v: {
                                    depthIns: null,
                                    init(depthIns) {
                                        this.depthIns = depthIns;
                                    }
                                },
                                c: {
                                    depthIns: null,
                                    init(depthIns) {
                                        this.depthIns = depthIns;
                                    }
                                },
                                init(depthIns) {
                                    this.depthIns = depthIns;
                                    this.v.init(depthIns);
                                    this.m.init(depthIns);
                                    this.c.init(depthIns);
                                },
                            },
                            m: {
                                depthIns: null,
                                init(depthIns) {
                                    this.depthIns = depthIns;
                                },
                                getPosWithoutMargin() {
                                    const {
                                        x,
                                        y
                                    } = this.getPos();
                                    //去掉外边距的真实坐标
                                    const rX = x - margin.left;
                                    const rY = y - margin.top;
                                    return {
                                        x: rX,
                                        y: rY
                                    }
                                },
                                getPos() {
                                    const [x, y] = d3.mouse(this.depthIns.container.v.el.node());
                                    return {
                                        x,
                                        y,
                                    }
                                },
                            },
                            v: {
                                el: null,
                                depthIns: null,
                                init(depthIns) {
                                    this.depthIns = depthIns;
                                    this.initDom();
                                },
                                initDom() {
                                    this.el = this.depthIns.svg.v.el.append('g');
                                },
                            },
                            c: {
                                depthIns: null,
                                init(depthIns) {
                                    this.depthIns = depthIns;
                                    this.render();
                                },
                                render() {
                                    this.depthIns.svg.gC.v.el.attr('transform',
                                        `translate(${margin.left},${margin.top})`)
                                },
                            },
                            init(depthIns) {
                                this.depthIns = depthIns;
                                this.v.init(depthIns);
                                this.m.init(depthIns);
                                this.c.init(depthIns);
                                this.mouse.init(depthIns);
                            },
                        },
                        init(depthIns) {
                            this.depthIns = depthIns;
                            this.v.init(depthIns);
                            this.m.init(depthIns);
                            this.c.init(depthIns);
                            this.gC.init(depthIns);
                        },
                        m: {
                            depthIns: null,
                            width: 0,
                            height: 0,
                            init(depthIns) {
                                this.depthIns = depthIns;
                                this.initSize();
                            },
                            initSize() {
                                this.width = this.depthIns.svg.v.el.node().clientWidth - margin.left - margin.right;
                                this.height = this.depthIns.svg.v.el.node().clientHeight - margin.top - margin
                                    .bottom;
                            },
                        },
                        v: {
                            el: null,
                            depthIns: null,
                            init(depthIns) {
                                this.depthIns = depthIns;
                                this.initDom()
                            },
                            initDom() {
                                this.el = this.depthIns.container.v.el.append('svg');
                            },
                        },
                        c: {
                            depthIns: null,
                            init(depthIns) {
                                this.depthIns = depthIns;
                                this.render();
                            },
                            render() {
                            },
                            addListen() {
                            },
                        }
                    },
                    //主面板
                    container: {
                        depthIns: null,
                        init(depthIns) {
                            this.depthIns = depthIns;
                            this.v.init(depthIns);
                            this.m.init(depthIns);
                            this.c.init(depthIns)
                        },
                        m: {
                            depthIns: null,
                            init(depthIns) {
                                this.depthIns = depthIns;
                            },
                            getSize() {
                                const cW = this.depthIns.container.v.el.node().clientWidth;
                                const cH = this.depthIns.container.v.el.node().clientHeight;
                                return {
                                    cW,
                                    cH
                                }
                            },
                        },
                        v: {
                            el: null,
                            depthIns: null,
                            init(depthIns) {
                                this.depthIns = depthIns;
                                this.initDom();
                            },
                            initDom() {
                                this.el = d3.select(selector);
                            }
                        },
                        c: {
                            depthIns: null,
                            init(depthIns) {
                                this.depthIns = depthIns;
                                this.render();
                                this.addListen();
                            },
                            render() {
                                // this.depthIns.container.v.el.style('position', 'relative')
                                this.depthIns.container.v.el.style('position', 'absolute')
                            },
                            addListen() {
                                this.depthIns.container.v.el.on('mousemove', (nothing, index, node) => {
                                    const pos = this.depthIns.svg.gC.m.getPos();
                                    if (this.depthIns.svg.gC.mouse.m.isLimit()) {
                                        this.depthIns.tooltip.c.hide();
                                        this.depthIns.markLine.c.hide();
                                        return;
                                    }
                                    this.depthIns.tooltip.c.show();
                                    this.depthIns.tooltip.c.move(this.depthIns.tooltip.m.getX('right'), pos
                                        .pT + pos.y);
                                    this.depthIns.markLine.c.show();
                                    this.depthIns.markLine.c.move(pos.x, margin.top);
                                })
                            }
                        },
                    },
                    m: {
                        bid: bid1.sort((a, b) => (a.price > b.price ? 1 : -1)).map((d) => {
                            return {
                                ...d,
                                price: Number(d.price),
                                total: Number(d.total)
                            }
                        }),
                        ask: ask1.sort((a, b) => (a.price > b.price ? 1 : -1)).map((d) => {
                            return {
                                ...d,
                                price: Number(d.price),
                                total: Number(d.total)
                            }
                        }),
                        hash: {},
                        data: [],
                        sortedData: [],
                        depthIns: null,
                        scale: {
                            x: null,
                            y: null,
                            depthIns: null,
                            domain: {
                                depthIns: null,
                                init(depthIns) {
                                    this.depthIns = depthIns;
                                    this.initDomain();
                                },
                                initDomain() {
                                    this.depthIns.m.scale.x.domain([d3.min(this.depthIns.m.bid, (d) => {
                                        return d.price
                                    }), d3.max(this.depthIns.m.ask, (d) => {
                                        return d.price
                                    })])
                                    this.depthIns.m.scale.y.domain([0, d3.max(this.depthIns.m.data, (d) => {
                                        return d.total
                                    })])
                                }
                            },
                            init(depthIns) {
                                this.depthIns = depthIns;
                                this.initScale();
                                this.domain.init(depthIns);
                            },
                            initScale() {
                                this.depthIns.m.scale.x = d3.scaleLinear().range([0, this.depthIns.svg.m.width])
                                this.depthIns.m.scale.y = d3.scaleLinear().range([this.depthIns.svg.m.height, 0]);
                            }
                        },
                        line: {
                            line: null,
                            depthIns: null,
                            init(depthIns) {
                                this.depthIns = depthIns;
                                this.initLine();
                            },
                            initLine() {
                                this.line = d3.line()
                                    .x((d) => {
                                        return this.depthIns.m.scale.x(d.price);
                                    })
                                    .y((d) => {
                                        return this.depthIns.m.scale.y(d.total);
                                    })
                            }
                        },
                        area: {
                            area: null,
                            depthIns: null,
                            init(depthIns) {
                                this.depthIns = depthIns;
                                this.initArea();
                            },
                            initArea() {
                                this.area = d3.area()
                                    .x((d) => {
                                        return this.depthIns.m.scale.x(d.price);
                                    })
                                    .y1((d) => {
                                        return this.depthIns.m.scale.y(d.total);
                                    })
                                    .y0(this.depthIns.m.scale.y(0));
                            },
                        },
                        xAxis: {
                            dataIns: null,
                            depthIns: null,
                            range: [],
                            min: null,
                            max: null,
                            cache: {

                            },
                            left: {
                                depthIns: null,
                                max: null,
                                min: null,
                                init(depthIns) {
                                    this.depthIns = depthIns;
                                    this.initMax();
                                    this.initMin();
                                },
                                initMax() {
                                    this.max = d3.max(this.depthIns.m.bid, (d) => {
                                        return d.price
                                    })
                                },
                                initMin() {
                                    this.min = d3.min(this.depthIns.m.ask, (d) => {
                                        return d.price
                                    })
                                }
                            },
                            right: {
                                depthIns: null,
                                max: null,
                                min: null,
                                init(depthIns) {
                                    this.depthIns = depthIns;
                                    this.initMax();
                                    this.initMin();
                                },
                                initMax() {
                                    this.max = d3.max(this.depthIns.m.ask, (d) => {
                                        return d.price
                                    })
                                },
                                initMin() {
                                    this.min = d3.min(this.depthIns.m.ask, (d) => {
                                        return d.price
                                    })
                                }
                            },
                            init(dataIns, depthIns) {
                                this.dataIns = dataIns;
                                this.depthIns = depthIns;
                                this.initMin();
                                this.initMax();
                                this.initRange();

                                this.left.init(depthIns);
                                this.right.init(depthIns);
                            },
                            toPos(v) {
                                return this.depthIns.m.scale.x(v);
                            },
                            pos2data() {

                                const {
                                    x,
                                    y
                                } = this.depthIns.svg.gC.m.getPosWithoutMargin();

                                const xVal = this.depthIns.m.scale.x.invert(x);

                                const range = this.range.filter((d) => {
                                    if (xVal >= d[0] && xVal <= d[1]) {
                                        return d;
                                    }
                                });

                                const near = this.getNear(this.flatArr(range), xVal);

                                return near[0];
                            },
                            flatArr(arr) {
                                return arr.reduce((initVal, d) => {
                                    initVal.push(d[0]);
                                    initVal.push(d[1]);
                                    return initVal;
                                }, [])
                            },
                            initRange() {
                                const ret = [];
                                const data = this.dataIns.data;
                                const sorted = this.dataIns.sortedData;
                                sorted.map((item, i) => {
                                    if (i == 0) {
                                        return;
                                    }
                                    const pre = sorted[i - 1];
                                    const next = item;
                                    ret.push([pre.price, next.price]);
                                })
                                this.range = ret;
                            },
                            // a or b is more near m ? 
                            getNear(arr, m) {
                                const minPrice = this.min;
                                const maxPrice = this.max;
                                if (m < minPrice) {
                                    return minPrice;
                                }
                                if (m > maxPrice) {
                                    return maxPrice;
                                }
                                const compare = [];
                                const index = false;
                                arr.forEach((item) => {
                                    compare.push({
                                        compare: Math.abs(item - m),
                                        val: item
                                    });
                                })
                                const min = Math.min.apply(Math, compare.map((d) => {
                                    return d.compare;
                                }));
                                const near = compare.filter((d) => {
                                    return d.compare == min;
                                })[0].val;
                                let ret = this.dataIns.data.filter((d) => {
                                    return d.price == near;
                                });
                                return ret;
                            },
                            initMax() {
                                this.max = d3.max(this.dataIns.data, (d) => {
                                    return d.price
                                });;
                            },
                            initMin() {
                                this.min = d3.min(this.dataIns.data, (d) => {
                                    return d.price
                                });;
                            },
                            getTicks(n, p = 2) {
                                n = n - 1;
                                const data = this.dataIns.data;
                                const min = this.min;
                                const max = this.max;
                                let sum = min;
                                let ret = [];
                                const avg = (max - min) / n;
                                for (let i = 0; i < n; i++) {
                                    ret.push(Number(sum).toFixed(2));
                                    sum += avg;
                                }
                                ret = ret.map((d) => {
                                    return Number(d).toFixed(p)
                                })
                                ret.push(Number(max).toFixed(p) + "");
                                return ret;
                            },
                        },
                        yAxis: {
                            dataIns: null,
                            init(dataIns) {
                                this.dataIns = dataIns;
                            }
                        },
                        init(depthIns) {
                            this.depthIns = depthIns;
                            this.initData();
                            this.initSortedData();
                            this.initHashData();
                            this.scale.init(depthIns);
                            this.xAxis.init(this, this.depthIns);
                            this.yAxis.init(this);
                            this.area.init(depthIns);
                            this.line.init(depthIns);
                        },
                        initData() {
                            this.data = this.bid.concat(this.ask);
                        },
                        initHashData() {
                            const ret = [];
                            this.data.forEach((d) => {
                                ret[d.price + ""] = d;
                            });
                            this.hash = ret;
                        },
                        initSortedData() {
                            this.sortedData = this.data.concat().sort((pre, next) => {
                                return pre.price - next.price;
                            });
                        }
                    },
                    //卖出面积图
                    askArea: {
                        el: null,
                        depthIns: null,
                        init(depthIns) {
                            this.depthIns = depthIns;
                            this.v.init(depthIns);
                            this.m.init(depthIns);
                            this.c.init(depthIns);
                        },
                        m: {
                            depthIns: null,
                            init(depthIns) {
                                this.depthIns = depthIns;
                            }
                        },
                        v: {
                            el: null,
                            depthIns: null,
                            init(depthIns) {
                                this.depthIns = depthIns;
                                this.initDom();
                            },
                            initDom() {
                                this.el = this.depthIns.svg.gC.v.el.append('path')
                            },
                        },
                        c: {
                            depthIns: null,
                            init(depthIns) {
                                this.depthIns = depthIns;
                                this.render();
                            },
                            render() {
                                this.depthIns.askArea.v.el.datum(this.depthIns.m.ask)
                                    .attr("fill", "rgba(237, 64, 20,0.1)")
                                    .attr("stroke-linejoin", "round")
                                    .attr("stroke-linecap", "round")
                                    .attr("stroke-width", 2)
                                    .attr("d", this.depthIns.m.area.area);
                            }//渲染右边红色底纹背景。默认：rgba(255,0,0,0.1)
                        },
                    },
                    //买入面积图
                    bidArea: {
                        el: null,
                        depthIns: null,
                        m: {
                            depthIns: null,
                            init(depthIns) {
                                this.depthIns = depthIns;
                            }
                        },
                        v: {
                            el: null,
                            depthIns: null,
                            init(depthIns) {
                                this.depthIns = depthIns;
                                this.initDom();
                            },
                            initDom() {
                                this.el = this.depthIns.svg.gC.v.el.append('path');
                            },
                        },
                        c: {
                            depthIns: null,
                            init(depthIns) {
                                this.depthIns = depthIns;
                                this.render();
                            },
                            render() {
                                this.depthIns.bidArea.v.el
                                    .datum(this.depthIns.m.bid)
                                    .attr("fill", "rgba(36, 174, 143,0.1)")
                                    .attr("stroke-linejoin", "round")
                                    .attr("stroke-linecap", "round")
                                    .attr("stroke-width", 2)
                                    .attr("d", this.depthIns.m.area.area)
                            }//渲染左边绿色底纹背景
                        },
                        init(depthIns) {
                            this.depthIns = depthIns;
                            this.v.init(depthIns);
                            this.m.init(depthIns);
                            this.c.init(depthIns);
                        }
                    },
                    //买入线
                    bidLine: {
                        depthIns: null,
                        m: {
                            depthIns: null,
                            init(depthIns) {
                                this.depthIns = depthIns;
                            }
                        },
                        v: {
                            el: null,
                            depthIns: null,
                            init(depthIns) {
                                this.depthIns = depthIns;
                                this.initDom();
                            },
                            initDom() {
                                this.el = this.depthIns.svg.gC.v.el.append('path');
                            }
                        },
                        c: {
                            depthIns: null,
                            init(depthIns) {
                                this.depthIns = depthIns;
                                this.render();
                            },
                            render() {
                                this.depthIns.bidLine.v.el
                                    .datum(this.depthIns.m.bid)
                                    .attr("fill", "none")
                                    .attr("stroke-linejoin", "round")
                                    .attr("stroke-linecap", "round")
                                    .attr("stroke-width", 2)
                                    .attr("stroke", "rgba(36, 174, 143)")
                                    .attr("d", this.depthIns.m.line.line)
                            }//渲染左边绿色边框
                        },
                        init(depthIns) {
                            this.depthIns = depthIns;
                            this.v.init(depthIns);
                            this.m.init(depthIns);
                            this.c.init(depthIns);
                        }
                    },
                    //卖出线
                    askLine: {
                        depthIns: null,
                        m: {
                            depthIns: null,
                            init(depthIns) {
                                this.depthIns = depthIns;
                            }
                        },
                        v: {
                            el: null,
                            depthIns: null,
                            init(depthIns) {
                                this.depthIns = depthIns;
                                this.initDom();
                            },
                            initDom() {
                                this.el = this.depthIns.svg.gC.v.el.append('path');
                            },
                        },
                        c: {
                            depthIns: null,
                            init(depthIns) {
                                this.depthIns = depthIns;
                                this.render();
                            },
                            render() {
                                this.depthIns.askLine.v.el
                                    .datum(this.depthIns.m.ask)
                                    .attr("fill", "none")
                                    .attr("stroke-linejoin", "round")
                                    .attr("stroke-linecap", "round")
                                    .attr("stroke-width", 2)
                                    .attr("stroke", "rgba(237, 64, 20)")
                                    .attr("d", this.depthIns.m.line.line)
                            }//渲染右边红色边框
                        },
                        init(depthIns) {
                            this.depthIns = depthIns;
                            this.v.init(depthIns);
                            this.m.init(depthIns);
                            this.c.init(depthIns);
                        },
                    },
                    //x轴
                    xAxis: {
                        depthIns: null,
                        m: {
                            depthIns: null,
                            init(depthIns) {
                                this.depthIns = depthIns;
                            }
                        },
                        v: {
                            el: null,
                            depthIns: null,
                            init(depthIns) {
                                this.depthIns = depthIns;
                                this.initDom();
                            },
                            initDom() {
                                this.el = this.depthIns.svg.gC.v.el.append('g');
                            },
                        },
                        c: {
                            depthIns: null,
                            init(depthIns) {
                                this.depthIns = depthIns;
                                this.render();
                            },
                            render() {
                                this.depthIns.xAxis.v.el.attr('class', 'axis axis--x')
                                    .attr('transform', `translate(0,${this.depthIns.svg.m.height})`)
                                    .call(
                                        d3.axisBottom(this.depthIns.m.scale.x).ticks(10, '.2f').tickValues(this
                                            .depthIns.m.xAxis.getTicks(10, 2))
                                    );
                                if (opts.hasGrid) {
                                    this.depthIns.xAxis.v.el.selectAll('g line')
                                        .attr('y2', (y1, y2, nodes) => {
                                            return -this.depthIns.m.scale.y(0)
                                        })
                                }
                            }
                        },
                        init(depthIns) {
                            this.depthIns = depthIns;

                            this.v.init(depthIns);
                            this.m.init(depthIns);
                            this.c.init(depthIns);
                        },
                    },
                    //y轴
                    yAxis: {
                        el: null,
                        depthIns: null,
                        m: {
                            depthIns: null,
                            init(depthIns) {
                                this.depthIns = depthIns;
                            }
                        },
                        v: {
                            depthIns: null,
                            init(depthIns) {
                                this.depthIns = depthIns;
                                this.initDom();
                            },
                            initDom() {
                                this.el = this.depthIns.svg.gC.v.el.append('g');
                            },
                        },
                        c: {
                            depthIns: null,
                            init(depthIns) {
                                this.depthIns = depthIns;
                                this.render();
                            },
                            render() {
                                this.depthIns.yAxis.v.el.attr('class', 'axis axis--y')
                                    .call(d3.axisLeft(this.depthIns.m.scale.y));

                                if (opts.hasGrid) {
                                    this.depthIns.yAxis.v.el.selectAll('g line')
                                        .attr('x2', (y1, y2, nodes) => {
                                            return this.depthIns.m.xAxis.toPos(this.depthIns.m.xAxis.max);
                                        })
                                }
                            }
                        },
                        init(depthIns) {
                            this.depthIns = depthIns;
                            this.v.init(depthIns);
                            this.m.init(depthIns);
                            this.c.init(depthIns);
                        },
                    },
                    //渲染
                    render() {
                        this.container.c.init(this);
                        this.svg.c.init(this);
                        this.svg.gC.c.init(this);
                        this.svg.gC.mouse.c.init(this);
                        this.tooltip.c.init(this);
                        this.markLine.c.init(this);
                        this.bidArea.c.init(this);
                        this.askArea.c.init(this);
                        this.bidLine.c.init(this);
                        this.askLine.c.init(this);
                        this.xAxis.c.init(this)
                        this.yAxis.c.init(this)
                    },
                    //设置配置
                    setOption(opts) {
                        this.container.m.init(this);
                        this.svg.m.init(this);
                        this.svg.gC.m.init(this);
                        this.svg.gC.mouse.m.init(this);
                        this.tooltip.m.init(this);
                        this.m.init(this);
                        this.bidArea.m.init(this);
                        this.askArea.m.init(this);
                        this.bidLine.m.init(this);
                        this.askLine.m.init(this);
                        this.xAxis.m.init(this)
                        this.yAxis.m.init(this)
                    },
                    //初始化
                    init() {
                        this.container.v.init(this);
                        this.svg.v.init(this);
                        this.svg.gC.v.init(this);
                        this.svg.gC.mouse.v.init(this);
                        this.tooltip.v.init(this);
                        this.markLine.v.init(this);
                        this.bidArea.v.init(this);
                        this.askArea.v.init(this);
                        this.bidLine.v.init(this);
                        this.askLine.v.init(this);
                        this.xAxis.v.init(this)
                        this.yAxis.v.init(this)
                    },
                    // untils: {
                    //     timer: null,
                    //     each(o, fn) {
                    //         if (Object.prototype.toString.call(o) == '[object Array') {
                    //             for (let i = 0; i < o.length; i++) {
                    //                 fn(o[i], o);
                    //             }
                    //         }
                    //         if (Object.prototype.toString.call(o) == '[object Object]') {
                    //             for (let key in o) {
                    //                 fn(o[key], key);
                    //             }
                    //         }
                    //     },
                    //     debounce(fn, time) {
                    //         if (this.timer) {
                    //             clearTimeout(this.timer);
                    //         }
                    //         this.timer = setTimeout(() => {
                    //             fn();
                    //             clearTimeout(this.timer);
                    //         }, time)
                    //     }
                    // }
                }
                return depth;
            };
            const depth = this.zdy_chart = draw('#depthchart-container');
            depth.init();
            depth.setOption();
            depth.render();
            window.onresize = () => {
                depth.setOption();
                depth.render();
            };
        }
    },
    computed:{
        blockchain(){
            return this.$store.state.blockchain;
        },
    },
    beforeDestroy () {
        this.$eventBus.$off("depthchart_msg");
    },
    watch: {
        isShow:{
            handler:function(val,value){
                this.updateChart()
            },
            deep: false,
            immediate: true
        }
    }
}
</script>

<style scoped lang='less'>
/*买入价格：绿色*/
/deep/ .bar.bid {
    fill: rgba(40, 193, 44, 0.3);
    /* fill:#24AE8F; */
}
/*卖出价格：红色*/
/deep/ .bar.ask {
    fill: rgba(255, 255, 0, 0.4);
    /* fill:#ed4014; */
}
/deep/ .bar:hover {
    fill: #337ab7;
}
/deep/ .label {
    font-size: 12px
}
/*网格线*/
/deep/ .tick line {
    stroke:transparent;
    // stroke: rgba(0, 0, 0, 0);
    /* stroke: rgba(0, 0, 0, 0.1); */
}
/**刻度尺x轴**/
/deep/ .axis--x path {
    display: none;
}
/**刻度尺y轴**/
/deep/ .axis--y path {
    display: none;
}
/*x轴文字颜色*/
/deep/ .axis--x g text{
    // stroke:@font-color;
    fill:@font-color;
    .op5;
}
/*y轴文字颜色*/
/deep/ .axis--y g text{
    // stroke:@font-color;
    fill:@font-color;
    .op5;
}

/* html, */
/* body, */
/deep/ svg{
    width: 100%;
    height: 100%;
}
</style>