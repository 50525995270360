export function getConfig2(){
    return {
        margin: [0, 0, 0, 0],
        // localization: { // 设置x周时间格式
        //     dateFormat: "yyyy-MM-dd",
        // },
        localization: {
            //y轴数据显示的格式化
            priceFormatter: (a) => {
                //最终百分比显示
                return a;
                // const n = Object(x.b)(a).div("1000000").toFixed(M.d);
                // return (d()(["0.0000", "-0.0000"], n) ? "0" : n) + "%"
            },
        },
        priceScale: {
            borderVisible: !1,
            position: "left",
        },
        timeScale: {
            rightOffset: 50,//10,
        },
        grid: {
            vertLines: {
                visible: !1,
            },
            horzLines: {
                visible: !1,
            },
        },
        layout: {
            backgroundColor: "#212121", //折线图背景色
            fontFamily: "Relative Pro",
            textColor: "#6f6e84",
            fontSize: 12,
        },
        
    };
}

export function getConfig(){
    return {
      timezone: "UTC",
      localization: {
        locale:'en-US',
        // 设置x周时间格式
        dateFormat: 'yyyy-MM-dd',
        // timezone: "Asia/Shanghai",
        // dateFormat:"dd MMM 'yy",
        // timeFormatter: function(val) {
        //     console.log(val,'time')
        //     return "Custom time format";
        // },
        // priceFormatter: (a,b,c) => {
        //     console.log('price',{a,b,c})
        // },
      },
      width: 0,
      height: 0,
      layout: {
        backgroundColor: '#212121',
        fontFamily: 'Relative Pro',
        textColor: '#6f6e84',
        fontSize: 12
      },
      priceScale: {
        autoScale: true,
        borderVisible: !1,
        position: 'left'
      },
      crosshair: {
        //垂直鼠标线颜色
        vertLine: {
          // color: "#ff0000",
          width: 1,
          style: 3,
          visible: !0,
          labelVisible: !0
          // labelBackgroundColor: "#ff0000"
        },
        //水平鼠标线颜色
        horzLine: {
          // color: "#ff0000",
          width: 1,
          style: 3,
          visible: !0,
          labelVisible: !0
          // labelBackgroundColor: "#4c525e"
        },
        mode: 1
      },
      //网格线
      grid: {
        vertLines: {
          color: '#212121',
          style: 0,
          visible: !0
        },
        horzLines: {
          color: '#212121',
          style: 0,
          visible: !0
        }
      },
      // rightPriceScale: {
      //     visible: true,
      //     scaleMargins: {
      //       top: 0.3,
      //       bottom: 0.25,
      //     },
      //     borderVisible: false,
      // },
      // leftPriceScale: {
      //     visible: true,
      //     // mode: 2,
      //     scaleMargins: {
      //       top: 0.3,
      //       bottom: 0.25,
      //     },
      //     borderVisible: false,
      // },
      timeScale: {
        // rightOffset: 10,//距离右边的位置
        rightOffset: 2,
        barSpacing: 20, //150,//6,间距
        fixLeftEdge: !1,
        lockVisibleTimeRangeOnResize: !1,
        rightBarStaysOnScroll: !1,
        borderVisible: !0,
        // borderColor: "#2B2B43",
        visible: !0,
        timeVisible: !1,
        secondsVisible: !0,
        shiftVisibleRangeOnNewBar: !0
      },
      // timeScale: {
      //             timeVisible: true,
      //         secondsVisible: false,
      //         },
      // watermark: ui,
      // localization: {
      //     // locale: Ue ? navigator.language : "",//语言包
      //     // locale: `${_vm.$t("locale")}`, //语言，en：英文，zh：中文
      //     dateFormat: "dd MMM 'yy",
      //     //y轴数据显示的格式化
      //     priceFormatter: (a) => {
      //         //最终百分比显示
      //         console.log(88)
      //         return 88;
      //         // const n = Object(x.b)(a).div("1000000").toFixed(M.d);
      //         // return (d()(["0.0000", "-0.0000"], n) ? "0" : n) + "%"
      //     },
      // },
      handleScroll: {
        mouseWheel: !0,
        pressedMouseMove: !0,
        horzTouchDrag: !0,
        vertTouchDrag: !0
      },
      handleScale: {
        axisPressedMouseMove: {
          time: !0,
          price: !0
        },
        axisDoubleClickReset: !0,
        mouseWheel: !0,
        pinch: !0
      }
    }
}

export function getConfig3(){
    return {
        // localization: { // 设置x周时间格式
        //     // dateFormat: "yyyy-MM-dd",
        //     dateFormat:"dd MMM 'yy",
        // },
        width: 0,
        height: 0,
        layout: {
            backgroundColor: "#212121",
            fontFamily: "Relative Pro",
            textColor: "#6f6e84",
            fontSize: 12,
        },
        priceScale: {
            autoScale:true,
            borderVisible: !1,
            position: "left",
        },
        crosshair: {
            //垂直鼠标线颜色
            vertLine: {
                // color: "#ff0000",
                width: 1,
                style: 3,
                visible: !0,
                labelVisible: !0,
                // labelBackgroundColor: "#ff0000"
            },
            //水平鼠标线颜色
            horzLine: {
                // color: "#ff0000",
                width: 1,
                style: 3,
                visible: !0,
                labelVisible: !0,
                // labelBackgroundColor: "#4c525e"
            },
            mode: 1
        },
        //网格线
        grid: {
            vertLines: {
                color: "#212121",
                style: 0,
                visible: !0
            },
            horzLines: {
                color: "#212121",
                style: 0,
                visible: !0
            }
        },
        // rightPriceScale: {
        //     visible: true,
        //     scaleMargins: {
        //       top: 0.3,
        //       bottom: 0.25,
        //     },
        //     borderVisible: false,
        // },
        // leftPriceScale: {
        //     visible: true,
        //     // mode: 2,
        //     scaleMargins: {
        //       top: 0.3,
        //       bottom: 0.25,
        //     },
        //     borderVisible: false,
        // },
        timeScale: {
            rightOffset: 10,//距离右边的位置
            // rightOffset: 0,
            barSpacing: 20,//150,//6,间距
            fixLeftEdge: !1,
            lockVisibleTimeRangeOnResize: !1,
            rightBarStaysOnScroll: !1,
            borderVisible: !0,
            // borderColor: "#2B2B43",
            visible: !0,
            timeVisible: !1,
            secondsVisible: !0,
            shiftVisibleRangeOnNewBar: !0,
        },
        // timeScale: {
        //             timeVisible: true,
        //         secondsVisible: false,
        //         },
        // watermark: ui,
        // localization: {
        //     // locale: Ue ? navigator.language : "",//语言包
        //     // locale: `${_vm.$t("locale")}`, //语言，en：英文，zh：中文
        //     dateFormat: "dd MMM 'yy",
        //     //y轴数据显示的格式化
        //     priceFormatter: (a) => {
        //         //最终百分比显示
        //         console.log(88)
        //         return 88;
        //         // const n = Object(x.b)(a).div("1000000").toFixed(M.d);
        //         // return (d()(["0.0000", "-0.0000"], n) ? "0" : n) + "%"
        //     },
        // },
        handleScroll: {
            mouseWheel: !0,
            pressedMouseMove: !0,
            horzTouchDrag: !0,
            vertTouchDrag: !0
        },
        handleScale: {
            axisPressedMouseMove: {
                time: !0,
                price: !0
            },
            axisDoubleClickReset: !0,
            mouseWheel: !0,
            pinch: !0
        }
    };
}