<template>
    <div>
        <!--折线图-->
        <div id="linechart-container" style="height:100%;width:100%;position:absolute;"></div>
        <!--悬浮层-->
        <div v-if="!isMove" class="layerLine transform" style="top:50%;left:50%;">
            <div class="titleLine">{{$t('now8FundingRate')}}</div>
            <div class="contentLine green">{{nowValue}}</div>
        </div>
        <div v-else class="layerLineNow" :style="{'left':x+'px','top':y+'px'}">
            <div class="titleLine">{{nowTime | formatTime()}}</div>
            <div class="contentLine green">{{activeValue}}</div>
        </div>
        <loading-layer :isShow="loading"></loading-layer>
    </div>
</template>

<script>
import { createChart } from "lightweight-charts";
import config from "@/config";
import { sendMsg,getFundingLine } from "@/api/http"
import { getConfig } from "./const/const.js"
import { minArrObj,getThisTime,jian,toFixed } from "@/utils/base"
import dayjs from 'dayjs'
let updateDataTimeoutId=0
export default {
    name: "",
    props: {
        isShow:{
            type:Boolean
        }
    },
    components: {},
    data() {
        return {
            loading:true,
            chartObj: null, //折线图
            lineSeries:null,
            listCache:[],//备份数据
            list:[],//数据
            realData:[],//实时数据
            minValue:0,//最小值
            x:50,
            y:50,
            isMove:false,
            nowTime:'',//时间戳时间
            activeValue:'',////鼠标移动值
            nextFromTime:0
        };
    },
    mounted() {
        this.init();
        setTimeout(()=>{
            this.initSocket();
        },0);
    },
    methods: {
        async updateData(){
            clearTimeout(updateDataTimeoutId)
            if(this.blockchain.perpetual){
                await getFundingLine(this.blockchain.perpetual,this.nextFromTime).catch(err=>console.warn(err.message))
                updateDataTimeoutId=setTimeout(()=>this.updateData(),5000) 
            }else{
                updateDataTimeoutId=setTimeout(()=>this.updateData(),500)
            }
        },
        //连接socket
        initSocket(){
            this.$eventBus.$on("fundingRate_msg",(res)=>{
                this.loading=false
                this.nextFromTime=res.data.lastTime??0;

                let type = res.data.type;
                let ds = res.data.data.map(d=>({
                    time: d.t, //+60*60*8,//图表存在问题，小于8小时，需要处理
                    value: d.f,
                }))

                var d=res.data.real;
                this.realData=[{
                    time: d.t,
                    value: d.f,
                }]

                if(type=='get'){    //首次进入获取数据
                    this.listCache=ds;
                    this.updatePoints(JSON.parse(JSON.stringify(this.listCache.concat(this.realData))));
                }else if(type=='add'){      //新增数据
                    this.listCache.push(...ds);
                    this.updatePoints(JSON.parse(JSON.stringify(this.listCache.concat(this.realData))));
                }
            });
            this.updateData();
        },
        //设置数据
        updatePoints(_list){
            //转换负数和零都为正数。折线图不允许存在负数
            let minValue=this.minValue=Math.abs(minArrObj(_list,'value'))*100+1;//+1：排除0
            _list.forEach(item=>{
                item.value=Number((Number(item.value)*100+minValue).toFixed(6));
            });
            _list = _list.filter((c,i)=>c.time !==_list[i+1]?.time);
            
            try{
                this.lineSeries.setData(_list);
            }catch(err){
                console.warn(err.message);
            }
            this.list = _list;
        },
        //初始化
        init() {
            var option=getConfig();
            option.localization.locale=this.lang;

            const chart=this.chartObj = createChart(document.getElementById("linechart-container"),option); //{ width: "100%", height: "100%" }，margin: [0, 0, 0, 0] ：动态改变大小
            const lineSeries = chart.addLineSeries({
                color: "#c8c7d8",
                lineWidth: 1.5,     //线条宽度
                priceLineVisible: !1,
                lastValueVisible: !1,
                // priceFormat: {
                //     type: 'value',//百分比
                //     precision: 3,//精度
                //     minMove: .01,
                // },
            });
            lineSeries.applyOptions({
                priceScale: {
                    position: "left",
                    mode: 2,
                    autoScale: true,
                    invertScale: true,
                    alignLabels: false,
                    borderVisible: false,
                    borderColor: "#555ffd",
                    scaleMargins: {
                    top: 0.3,
                    bottom: 0.25
                    }
                },
                priceFormat: {
                    type: "custom",
                    minMove: 0.02,//精度
                    formatter: (price)=> {
                        // return toFixed(jian(price,this.minValue),4)+'%';
                        return Number(price-this.minValue).toFixed(4)+'%';
                    }
                },
            });
            lineSeries.createPriceLine({
                price: 0,
                axisLabelVisible: !1,
                lineWidth: 1,
                lineStyle: "",
                color: "#c8c7d8",
                priceScale: {
                    autoScale:100,
                    borderVisible: !1,
                    position: "left",
                },
            });
            
            //监听鼠标在图标上的变化
            chart.subscribeCrosshairMove((param,obj)=> {
                if ( param === undefined || param.time === undefined || param.point.x < 0 || param.point.y < 0) {
                    // 判断当前鼠标不在图表上时，
                    this.isMove=false;
                } else {
                    // 判断当前鼠标在图表上时，进行的处理函数
                    // console.log('图表上移动：',param,param.time)
                    let point=param.point;
                    let time=param.time;
                    this.x=point.x;
                    this.y=point.y-80-20;
                    this.nowTime=time;
                    this.list.forEach(item=>{
                        let t=item.time;
                        if(t==param.time){
                            this.activeValue=(item.value-this.minValue).toFixed(6)+'%';
                            // this.activeValue=toFixed(Number(jian(item.value,this.minValue)),6)+'%';
                        }
                        // if(t.year==time.year && t.month==time.month && t.day==time.day){
                        //     this.activeValue=(item.value-this.minValue).toFixed(4)+'%';
                        // }
                    });
                    this.isMove=true;
                }
            });
            this.lineSeries=lineSeries;
            //修改数据
            //将负数和正数加100。解决报错问题。
            // let list=[
                // { time: 1556877600, value: 230.12 },
                // { time: 1556881200, value: 230.24 },
                // { time: 1556884800, value: 230.63 },
                // { time: "2019-04-11 12:11:11", value: -0.001 },
                // { time: "2019-04-12 12:11:11", value: 0.005 },
                // { time: "2019-04-13 08:11:11", value: 0.006 },
                // { time: "2019-04-13 16:11:11", value: 0.006 },
                // { time: "2019-04-14 12:11:11", value: 0.007 },
                // { time: "2019-04-15 12:11:11", value: 0.008 },
                // { time: "2019-04-16 12:11:11", value: 0.009 },
                // { time: "2019-04-17", value: 96.63 },
                // { time: "2019-04-18", value: 76.64 },
                // { time: "2019-04-19", value: 81.89 },
                // { time: "2019-04-20", value: 74.43 },
            // ];
            // list.forEach(item=>{
            //     var time = Date.parse(new Date(item.time)).toString();//获取到毫秒的时间戳，精确到毫秒
            //     time = timeStr.substr(0,10);//精确到秒
            //     item.time=Number(time);
            // });
            // console.log(list)
            // var time = Date.parse( new Date() ).toString();//获取到毫秒的时间戳，精确到毫秒
                // time = timeStr.substr(0,10);//精确到秒
            // this.updatePoints(list);
        },
    },
    computed: {
        //多语言切换
        lang(){
            return this.$i18n.locale;
        },
        nowValue(){
            if(this.list.length==0){
                return '-';
            }
            return (this.list[this.list.length-1].value-this.minValue).toFixed(6)+'%';
        },
        blockchain(){
            return this.$store.state.blockchain;
        },
    },
    watch:{
        isShow(newValue,oldValue){
            if(newValue){
            }
        },
        //监听多语言切换
        lang(){
            //切换中英文不生效，官方bug。            
            this.chartObj.applyOptions({
                localization: {
                    locale: this.lang
                }
            });
        },
    },
    filters:{
        formatTime(val){
            if(!val){
                return val;
            }
            return getThisTime(val-(60*60*8)).now;
        }
    },
    beforeDestroy () {
        this.$eventBus.$off("fundingRate_msg");
        clearTimeout(updateDataTimeoutId);
    },
};
</script>

<style scoped lang='less'>
.layerLine {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -12px;
    left: calc(50% - 120px);
    z-index: 2;
    width: 240px;
    height: 120px;
    border-radius: 50%;
    background: radial-gradient(50% 50% at 50% 50%,#333 35%,rgba(26,26,39,0) 100%);
}
.layerLineNow{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: -12px;
    left: calc(50% - 120px);
    z-index: 2;
    width: 160px;
    height: 80px;
    border-radius: 15px;
    background: @graybg;
    padding:20px;
}
.transform{
    transform: translate(-50%, -50%);
}
.titleLine {
    font-size: 14px;
    line-height: 18px;
    color: #6f6e84;
    white-space: nowrap;
}
.contentLine {
    font-size: 20px;
    line-height: 24px;
    margin-top: 4px;
}

</style>